<div *ngIf="comments">
  <div *ngFor="let item of comments">
    <uni-comment
      [showFooter]="showFooter"
      [comment]="item"
      [user]="user"
      [slug]="slug"
      [owner_flag]="owner_flag"
      [source]="source"
      (removed)="removed($event)"
      (mentioned)="userMentioned($event)"
      [nested]="current_nest_depth < max_nest_depth"
      [current_nest_depth]="current_nest_depth"
      [max_nest_depth]="max_nest_depth">
    </uni-comment>
  </div>
</div>
