import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';

import { ShareModule } from 'ngx-sharebuttons';
import { NavigationButtonComponent } from './blueprints/navigation-button/navigation-button.component';
import { StateButtonComponent } from './blueprints/state-button/state-button.component';
import { ToggleButtonComponent } from './blueprints/toggle-button/toggle-button/toggle-button.component';
import { ToggleButtonsGroupComponent } from './blueprints/toggle-button/toggle-buttons-group/toggle-buttons-group.component';
import { FloatButtonComponent } from './float-button/float-button.component';
import { LikeButtonComponent } from './instances/like-button/like-button.component';
import { ShareButtonGroupComponent } from './instances/share-button-group/share-button-group.component';

import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';
import { ImagesModule } from '../images/images.module';

@NgModule({
  declarations: [
    NavigationButtonComponent,
    StateButtonComponent,
    ToggleButtonsGroupComponent,
    ToggleButtonComponent,
    ShareButtonGroupComponent,
    LikeButtonComponent,
    FloatButtonComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    DirectiveModule,
    ShareModule,
    ImagesModule
  ],
  exports: [
    NavigationButtonComponent,
    StateButtonComponent,
    ToggleButtonsGroupComponent,
    ToggleButtonComponent,
    ShareButtonGroupComponent,
    LikeButtonComponent,
    FloatButtonComponent
  ]
})
export class ButtonsModule { }
