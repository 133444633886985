import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@sparro/app/core/models/organization.model';
import { environment } from '@sparro/env/environment';
import { BehaviorSubject } from 'rxjs';

export class UserOrganization {
  type: string;
  is_member: boolean;
  organization: Organization;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private baseUrl = `api/v2/`;
  // Set BS for organization in detail view
  organization_initialized = new BehaviorSubject(false);
  organization: BehaviorSubject<Organization>;

  // Sets organizations list in BS
  organizations_initialized = new BehaviorSubject(false);
  organizations: BehaviorSubject<Organization>;

 // Set user organizations
  user_organizations = new BehaviorSubject(false);
  userOrganizations: BehaviorSubject<UserOrganization[]>;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  initializeSubject(organization: Organization) {
    this.organization = new BehaviorSubject(organization);
    this.organization_initialized.next(true);
  }

  initializeOrganizations(organizations: Organization) {
    this.organizations = new BehaviorSubject(organizations);
    this.organizations_initialized.next(true);
  }

  initializeUserOrganizations(organizations: UserOrganization[]) {
    this.userOrganizations = new BehaviorSubject(organizations);
    this.user_organizations.next(true);
  }

  createOrganization(postData: object) {
    return this.http.post(this.baseUrl + 'organizations/', postData);
  }

  updateOrganization(slug: string, postData) {
    return this.http.put(this.baseUrl + 'organizations/' + slug + '/', postData);
  }

  getOrganizationMeta(slug: string) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/meta/');
  }

  checkPermission(slug: string) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/is_member/');
  }

  uploadOrganizationLogo(postData) {
    return this.http.post(
      this.baseUrl + 'organizations_media_upload/',
      postData,
      {
        reportProgress: true,
        observe: 'events',
      });
  }

  getOrganization(slug: string) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/');
  }

  getOrganizations(
    page: number = 1,
    searchText: string = '',
    countryIsoCodes: string = '',
    status: string = '',
    order: string = '',
    hasOrganizedCompetition: boolean | null = null,
    membersCountRange: string = ''
  ) {
    return this.http.get(
      this.baseUrl +
        'organizations' +
        '/?page=' +
        page +
        '&search=' +
        searchText +
        '&countries=' +
        countryIsoCodes +
        '&status=' +
        status +
        '&ordering=' +
        order +
        '&organized_competitions=' +
        hasOrganizedCompetition +
        '&members_count=' +
        membersCountRange
    );
  }

  getConnectedOrganizations(type: string) {
    return this.http.get(
      this.baseUrl + 'organizations/connected/?type=' + type
    );
  }

  getUserLightOrganizations() {
    return this.http.get(this.baseUrl + 'organizations/user_light/');
  }

  getUserHeavyOrganizations() {
    return this.http.get(this.baseUrl + 'organizations/user/');
  }

  getFilteredProjects(page: number, organization_id: number, ) {
    return this.http.get(this.baseUrl + 'neo_projects/?page=' + page + '&organizations=' + organization_id);
  }

  getFilteredCompetitions(page: number, organization_id: number) {
    return this.http.get(
      this.baseUrl + 'competitions/?page=' + page + '&organizations=' + organization_id
    );
  }

  getPublishedCompetitions(slug: string, page: number) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/published_competitions/?page=' + page);
  }

  getDraftCompetitions(slug: string, page: number) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/draft_competitions/?page=' + page);
  }

  getCompetitionsInReview(slug: string, page: number) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/competitions_in_review/?page=' + page);
  }

  getOrganizationBlogs(id: number, page: number) {
    // Todo
  }


  getOrganizationPublications(id: number, page: number) {
    // Todo

  }

  getOrganizationProducts(id: number, page: number) {
    // Todo

  }

  getOrganizationMembers(slug: string) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/members/');
  }

  createOrganizationMember(slug: string, postData) {
    return this.http.post(this.baseUrl + 'organizations/' + slug + '/members/', postData);
  }

  updateOrganizationMember(slug: string, member_id: number, postData) {
    return this.http.put(this.baseUrl + 'organizations/' + slug + '/members/' + member_id + '/', postData);
  }

  getOrganizationMemberById(slug: string, member_id: number) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/members/' + member_id + '/');
  }

  filterOrganizationMembers(slug: string, page: number, member_type: string ) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/members/?page=' + page + '&member_type=' + member_type);
  }

  deleteOrganizationMember(slug: string, member_id: number) {
    return this.http.delete(this.baseUrl + 'organizations/' + slug + '/members/' + member_id + '/');
  }
}
