import { Directive, ElementRef, Input, ViewContainerRef, ComponentFactoryResolver, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractButtonDirective } from './abstract_button_directive';
import { LoaderComponent } from '../../components/loader/loader/loader.component';

@Directive({
  selector: '[uni-button-icon]'
})
export class ButtonIconDirective extends AbstractButtonDirective implements OnChanges {
  @Input('uni-button-icon') default_icon: string;
  @Input() active_icon: string;
  @Input() loading: boolean;
  @Input() loadingText: string = '';
  class_name: string = 'icon-edit';
  loadingState: boolean = false;
  loader;

  constructor(
    _renderer: Renderer2,
    element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2,
  ) {
    super(_renderer, element);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.loading.currentValue == true) {
      this.showLoader();
    }
    else if((changes.loading.previousValue == true) && (changes.loading.currentValue == false)) {
      this.hideLoader();
    }
  }

  showLoader() {
    const factory = this.resolver.resolveComponentFactory(LoaderComponent);
    this.loader = this.viewContainerRef.createComponent(factory);
    this.setLoaderInputs(this.loader);
    this.loader.location.nativeElement.style.marginRight = '8px';
    this.renderer.appendChild(this.element.nativeElement, this.loader.location.nativeElement);
  }

  hideLoader() {
    this.loader.location.nativeElement.style.display = 'none';
  }

  setLoaderInputs(loader) {
    loader.instance.radius = 15;
  }

  stateHandler() {
    if (this.activeState && this.active_icon) {
      this.removeClass();
      this.class_name = this.active_icon;
    } else {
      this.removeClass();
      this.class_name = this.default_icon;
    }
    this.addClass();
  }

  loadingHandler() {
  }

}
