import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';

@Injectable({
  providedIn: 'root'
})

export class CommentService {
  private baseUrl = `api/v2/`;
  static instance: CommentService;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
    CommentService.instance = this;
  }

  getNeoComments(source: string, slug: string, page: number) {
    return this.http.get(this.baseUrl + source + '/' + slug + '/comments/?page=' + page);
  }

  getChildrenComments(source: string, slug: string, parent: number, page: number) {
    return this.http.get(this.baseUrl + source + '/' + slug + '/comments/' + parent + '/children/?page=' + page);
  }

  getUserNeoComments(source: string, slug: string, page: number) {
    return this.http.get(this.baseUrl + source + '/' + slug + '/comments/user_comments/?page=' + page);
  }

  createNeoComment(source: string, slug: string, comment) {
    return this.http.post(this.baseUrl + source + '/' + slug + '/comments/', comment);
  }

  updateNeoComment(source: string, slug: string, comment_pk: number, comment) {
    return this.http.put(this.baseUrl + source + '/' + slug + '/comments/' + comment_pk + '/', comment);
  }

  removeNeoComment(source: string, slug: string, comment_pk: number) {
    return this.http.delete(this.baseUrl + source + '/' + slug + '/comments/' + comment_pk + '/');
  }

  highlightNeoComment(source: string, slug: string, comment_pk: number) {
    return this.http.put(this.baseUrl + source + '/' + slug + '/comments/' + comment_pk + '/highlight/', {});
  }

  upvoteNeoComment(source: string, slug: string, comment_pk: number, user_pk: number) {
    return this.http.put(this.baseUrl + source + '/' + slug + '/comments/' + comment_pk + '/upvote/', {});
  }

  downvoteNeoComment(source: string, slug: string, comment_pk: number, user_pk: number) {
    return this.http.put(this.baseUrl + source + '/' + slug + '/comments/' + comment_pk + '/downvote/', {});
  }

  removeNeoCommentVote(source: string, slug: string, comment_pk: number, user_pk: number) {
    return this.http.put(this.baseUrl + source + '/' + slug + '/comments/' + comment_pk + '/remove_vote/', {});
  }

  checkNeoCommentVote(source: string, slug: string, comment_pk: number) {
    return this.http.get(this.baseUrl + source + '/' + slug + '/comments/' + comment_pk + '/check_vote/', {});
  }

}
