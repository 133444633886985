import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { User } from '@sparro/app/core/models/user.model';
import { NeoComment } from '@sparro/app/core/api/comment/comment.model';
import { CommentService } from '@sparro/app/core/api/comment/comment.service';
import { environment } from '@sparro/env/environment';
import { ProjectService } from '@sparro/app/core/api/project/project.service';

import _ from 'lodash';

@Component({
  selector: 'uni-comment-group',
  templateUrl: './comment-group.component.html',
  styleUrls: ['./comment-group.component.scss']
})
export class CommentGroupComponent implements OnInit {
  comments: NeoComment[] = [];
  @Input() showFooter: boolean = true;
  @Input() slug: string;
  @Input() uuid: string;
  @Input() competition_slug: string;
  @Input() source: string;
  @Input() create_only = false;
  @Input() user: any;
  @Input() nested = false;
  @Input() max_nest_depth = 0;
  @Input() current_nest_depth = 0;
  @Input() parent: number;
  @Input() child_group = false;

  next = null;
  page = 1;
  // user: any = null;
  comments_count = 0;
  user_checked: boolean = false;
  is_authenticated: boolean = false;
  private loginUrl = null;
  showLoader = true;
  owner_flag: boolean = false;
  juror_flag: boolean = false;
  mentioned_users = new Set();

  @Output() counter = new EventEmitter<number>();
  @Output() create_only_toggle = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private commentService: CommentService,
    private projectService: ProjectService
  ) {
    this.loginUrl = `${environment.loginUrl}`;
  }

  ngOnInit() {
    this.showLoader = true;
    if (this.user) {
      this.is_authenticated = true;
    } else {
      this.getUser();
    }
    if (this.source === 'neo_projects') {
      this.checkProjectOwner();
      this.checkCompetitionJuror();
    } else {
      this.getComments();
    }
  }

  getUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          if (user) {
            this.user = user;
            this.user_checked = true;
            this.is_authenticated = true;
          } else {
            this.user_checked = true;
            this.is_authenticated = false;
          }
        });
      }
    });
  }

  checkProjectOwner(): void {
    this.owner_flag = this.projectService.checkOwner(this.uuid, this.slug);
    this.user_checked = true;
    // Commented for NOW will remove later once permission system works
    // if (this.user.ownerships?.project.find(project => project.slug === this.slug)) {
    //   this.owner_flag = this.user.ownerships?.project.find(project => project.slug === this.slug).owner;
    // } else {
    //   this.userService.checkProjectOwner(this.slug).subscribe(
    //     (response) => {
    //       // console.log(response);
    //       this.user_checked = true;
    //       this.owner_flag = response['owner'];
    //       if (!this.user.ownerships) {
    //         this.user.ownerships = {
    //           project: []
    //         };
    //       }
    //       this.user.ownerships.project.push({
    //         slug: this.slug,
    //         owner: this.owner_flag
    //       });
    //     }, (err) => {
    //       console.log(err);
    //     }
    //   );
    // }
  }

  checkCompetitionJuror() {
    if (this.competition_slug) {
      this.userService.checkCompetitionJuror(this.competition_slug).subscribe(
        response => {
          this.user_checked = true;
          // console.log(this.user_checked);
          this.juror_flag = response['is_jury'];
          if (this.juror_flag) {
            this.getUserComments();
          } else {
            this.getComments();
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.getComments();
    }
  }

  getComments() {
    NeoComment.getComments(
      this.source,
      this.slug,
      this.page,
      this.child_group,
      this.parent
    ).subscribe(
      (response: any) => {
        this.showLoader = false;
        this.setComments(response);
      },
      err => {
        console.log(err);
        this.showLoader = false;
      }
    );
  }

  getUserComments() {
    // NOTE: User Comments are needed only for eval right now
    // So no need for nesting, as of now.
    NeoComment.getUserComments(this.source, this.slug, this.page).subscribe(
      (response: any) => {
        this.showLoader = false;
        this.setComments(response);
      },
      err => {
        console.log(err);
        this.showLoader = false;
      }
    );
  }

  loadMoreComments() {
    if (this.next != null) {
      this.getComments();
    }
  }

  toggleComments(flag: boolean) {
    this.create_only = flag;
  }

  setComments(response: any) {
    this.next = response.next;
    if (this.next) {
      this.page = parseInt(this.next.split('=')[1]);
    }
    this.comments = [...this.comments, ...response.results];
    this.comments_count = response.count;
    // console.log(this.comments)
  }

  created(data: any) {
    this.comments.unshift(data);
    this.create_only = false;
    this.counter.emit(this.comments.length);
    // console.log(data);
    // this.getComments();
  }

  goToLogin() {
    window.location.href = `${this.loginUrl}authentication?service=sign-in&continue=${window.location.href}`;
  }

  userMentioned(data: any) {
    if (this.mentioned_users.size === 0) {
      this.mentioned_users.add(data);
    } else {
      let user_found = false;
      this.mentioned_users.forEach((user: any) => {
        if (data.id === user.id) {
          user_found = true;
          // this.mentioned_users.add(data);
        }
      });
      if (!user_found) {
        this.mentioned_users.add(data);
      }
    }
    console.log(this.mentioned_users);
    this.scrollToCreate();
  }

  removeMention(data: any) {
    if (this.mentioned_users.has(data)) {
      this.mentioned_users.delete(data);
    }
  }

  clearComment(data: any) {
    this.mentioned_users.clear();
  }

  scrollToCreate() {
    const el = document.getElementById('createsection');
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
