<button
  [uni-button-class]="button_class"
  [uni-button-type]="button_type"
  [uni-icon-button]="icon_only"
  [matTooltip]="title"
  [matTooltipDisabled]="!icon_only"
  auxiliary-class
  [auxiliaryClassName]="customClass"
  [disabled]="disabled">
  <i
    *ngIf="icon_class"
    [uni-button-icon]="icon_class"
    [loading]="showLoader"
    [active_icon]="icon_class_active"
    style="vertical-align: middle"
    class="margin-right-2 icon-active-wrapper">
  </i>

  <span
    uni-button-title
    [default]="default"
    [active]="active"
    [activeHover]="activeHover"
    [inactiveHover]="inactiveHover"
    [loading]="showLoader"
    [loadingText]="loadingText"
    class="uni-button-title"
    (title_changed)="titleChanged($event)"
    [ngStyle]="{ display: icon_only ? 'none' : '' }">
    {{ default ? default : title }}
  </span>
</button>

<!-- <uni-state-button
  [active]=" 'string' "
  [activeHover]=" 'string'"
  [activeState]= " 'string' "
  [inactiveHover]=" 'string' "
  [default]= " 'string'">
  </uni-state-button> -->
