import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'uni-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss']
})
export class NavigationButtonComponent implements OnInit {
  @Input() default: string;
  @Input() loading: string;
  @Input() button_class: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() icon_class: string;
  @Input() icon_class_active: string;
  @Input() icon_only: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showLoader: boolean = false;
  @Input() loadingText: string = '';
  @Input() customClass: string = '';

  constructor() { }

  ngOnInit() {
  }

}
