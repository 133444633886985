import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'uni-unifid-contribution',
  templateUrl: './unifid-contribution.component.html',
  styleUrls: ['./unifid-contribution.component.scss']
})
export class UnifidContributionComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UnifidContributionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }
}
