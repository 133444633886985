<ng-container *ngIf="self; else readOnly">
  <div [ngClass]="{'container': isJumboAvatar}">
    <ng-container *ngIf="organization">
      <uni-image
        [src]="organization.logo"
        [alt]="organization.slug"
        class="uni-image"
        [default]="default"
        [width]="isJumboAvatar && isDesktop ? 256 : isJumboAvatar ? 128 : 40"
        [height]="isJumboAvatar && isDesktop ? 256 : isJumboAvatar ? 128 : 40"
        classes="fit-cover cursor-pointer rounded-border sparro-bg-grey-200 align-middle"
        [auxClassList]="avatarClass" #avatar>
      </uni-image>
    </ng-container>
    <div class="default-avatar-icon-box">
      <div class="icon-container-box">
        <i (click)="fileInput.click()" class="icon-upload">
          <input hidden type="file" #fileInput (change)="uploadFile($event, 'img')" accept="img/*">
        </i>
      </div>
    </div>
    <ng-container *ngIf="isUploading; else notUploading">
      <div class="loader">
        <uni-loader></uni-loader>
      </div>
    </ng-container>
    <ng-template #notUploading>
    </ng-template>
  </div>
</ng-container>
<ng-template #readOnly>
  <a [href]="getOrganizationUrl()" class="no-decor">
    <ng-container *ngIf="organization">
      <uni-image
        [src]="organization.logo"
        [alt]="organization.slug"
        [default]="default"
        [width]="isJumboAvatar && isDesktop ? 256 : isJumboAvatar ? 128 : isSmallAvatar ? 32 : 40"
        [height]="isJumboAvatar && isDesktop ? 256 : isJumboAvatar ? 128 : isSmallAvatar ? 32 : 40"
        classes="fit-cover cursor-pointer rounded-border sparro-bg-grey-200 align-middle"
        [auxClassList]="avatarClass">
      </uni-image>
    </ng-container>
  </a>
</ng-template>
