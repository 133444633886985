import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MaterialModule } from '@sparro/app/material.module';
import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';
import { ButtonsModule } from '../../buttons/buttons.module';
import { ImagesModule } from '../../images/images.module';

import { AddNewLocationComponent } from './add-new-location/add-new-location.component';
import { AuxiliaryFilterComponent } from './auxiliary-filter/auxiliary-filter.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { EmbedViewComponent } from './discussion-form/components/embed-view/embed-view.component';
import { MediaUploadComponent } from './discussion-form/components/media-upload/media-upload.component';
import { DiscussionFormComponent } from './discussion-form/discussion-form.component';
import { LeaderboardDateRangeFilterComponent } from './leaderboard-date-range-filter/leaderboard-date-range-filter/leaderboard-date-range-filter.component';
import { LocationComponent } from './location/location.component';

@NgModule({
  declarations: [
    LocationComponent,
    CreateOrganizationComponent,
    AddNewLocationComponent,
    DateRangeFilterComponent,
    DiscussionFormComponent,
    MediaUploadComponent,
    EmbedViewComponent,
    LeaderboardDateRangeFilterComponent,
    AuxiliaryFilterComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonsModule,
    MatDialogModule,
    DirectiveModule,
    ImagesModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    LocationComponent,
    CreateOrganizationComponent,
    DateRangeFilterComponent,
    DiscussionFormComponent,
    MediaUploadComponent,
    EmbedViewComponent,
    AuxiliaryFilterComponent,
    LeaderboardDateRangeFilterComponent
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class FieldsModule {}
