import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';
import { LoaderModule } from '../loader/loader.module';
import { ImageComponent } from './image/image.component';
import { OrganizationAvatarComponent } from './organization-avatar/organization-avatar.component';
import { OrganizationCoverComponent } from './organization-cover/organization-cover.component';
import { ProjectEmbedComponent } from './project-embed/project-embed.component';
import { ProjectImageComponent } from './project-image/project-image.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserCoverComponent } from './user-cover/user-cover.component';

@NgModule({
  declarations: [
    ProjectImageComponent,
    ProjectEmbedComponent,
    UserCoverComponent,
    UserAvatarComponent,
    OrganizationAvatarComponent,
    OrganizationCoverComponent,
    ImageComponent
  ],
  entryComponents: [
    ProjectImageComponent,
    ProjectEmbedComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    LoaderModule,
    DirectiveModule
  ],
  exports: [
    ProjectImageComponent,
    ProjectEmbedComponent,
    UserCoverComponent,
    UserAvatarComponent,
    OrganizationAvatarComponent,
    OrganizationCoverComponent,
    ImageComponent
  ]
})
export class ImagesModule { }
