export const environment = {
  production: false,
  staging: false,
  imageOptimizer: true,
  baseUrl: '/api/v2/',
  competitionUrl: 'http://localhost:4000',
  postUrl: 'http://localhost:4001',
  loginUrl: 'http://localhost:4003',
  projectUrl: 'http://localhost:4004',
  serverUrl: 'http://localhost:8000',
  profileUrl: 'http://localhost:4006/',
  projectUploaderUrl: 'http://localhost:4008',
  publicationUrl: 'http://localhost:4011',
  static_assets_url: '../../../../../static/project-builder/dist/assets/',
  google_map_key: 'AIzaSyB7t6cPISRQ8mTWShLot3Pi5y4wJmIo2og'
};
