import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkService } from '@sparro/app/core/api/bookmark/bookmark.service';
import { FollowerService } from '@sparro/app/core/api/follower/follower.service';
import { LikeService } from '@sparro/app/core/api/like/like.service';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { SnackbarService } from '@sparro/app/core/helpers/toastr/snackbar.service';
import { Report } from '@sparro/app/core/models/report.model';
import { User } from '@sparro/app/core/models/user.model';
import { ConfirmationDialogComponent } from '@sparro/app/shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ReportDialogComponent } from '@sparro/app/shared/components/dialogs/report-dialog/report-dialog.component';
import { ShareDialogComponent } from '@sparro/app/shared/components/dialogs/share-dialog/share-dialog.component';
import { environment } from '@sparro/env/environment';

@Component({
  selector: 'uni-share-button-group',
  templateUrl: './share-button-group.component.html',
  styleUrls: ['./share-button-group.component.scss']
})
export class ShareButtonGroupComponent implements OnInit, AfterViewInit {
  @Input() source: string;
  // Add sources with a fallback. Use ALLOWED_HOSTS
  @Input() commentSectionTop: number;
  @Input() item: any;
  @Input() slug: string;
  @Input() sourceCopy: string;
  @Input() report: any;
  @Input() following: any;
  @Input() user: User;
  @Input() metaSection: boolean = false;
  @Input() mobileView: boolean = false;
  @Input() self: boolean = false;
  @Input() content: any;
  @Input() projectOwners: any;
  @Input() showFloatingShare: boolean = false;
  @Output() version = new EventEmitter;
  shareUrl: string;
  owners: User[] = [];
  @Output() comment = new EventEmitter;

  copyLink: string = '';

  icons = {
    share: 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/share/black_bold_48x48.svg',
    share_fill: 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/share/black_fill_48x48.svg',
    bookmark: 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/addtocollection/default-48x8.svg',
    bookmark_fill_default: 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/addtocollection/fill-48x48.svg',
    bookmark_fill_orange: 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/addtocollection/collection_fill_orange_48x48.svg',
  };

  constructor(
    private metaService: MetaService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private followerService: FollowerService,
    private likeService: LikeService,
    private bookmarkService: BookmarkService,
    public breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.shareUrl = `${environment.serverUrl}${this.sourceCopy}/${this.slug}`;
  }

  ngAfterViewInit() {
    if (this.source == 'neo_projects') {
      // function to display owners
    }
    this.copyLink = this.metaService.copyItem(this.sourceCopy, this.slug);
    this.changeDetectorRef.detectChanges();
  }

  goToComment() {
    if (this.source == 'competitions') {
      this.comment.emit(this.commentSectionTop);
    }
    else if (this.source === 'neo_projects') {
      window.scrollTo(0, this.commentSectionTop);
    }
    else {
      window.scrollTo(0, this.commentSectionTop - (window.innerHeight / 3));
    }
  }

  copiedLink(link) {
    this.snackbarService.success('Link copied to clipboard');
  }

  createReport() {
    if (!this.user) {
      window.location.href = `${environment.loginUrl}authentication?service=sign-in&continue=${window.location.href}`;
      return;
    } else {
      const dialogWidth = window.innerWidth <= 540 ? `90vw` : `527px`;
      const dialogRef = this.dialog.open(ReportDialogComponent, {
        width: dialogWidth,
        maxWidth: dialogWidth,
        data: {
          user: this.user,
          slug: this.slug,
          source: this.source
        }
      });
      dialogRef.afterClosed().subscribe(
        (response: any) => {
          if (response) {
            this.report = response;
            this.snackbarService.success('Report has been initiated');
          }
        });
    }
  }

  redactReport() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '420px',
      data: {
        message: `This will withdraw the report and the action cannot be undone.
        Are you sure you want to continue?`,
        okayButtonText: 'Yes'
      }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          Report.redactReport(this.source, this.slug, this.report.id).subscribe(
            (response: any) => {
              if (response) {
                this.report = response;
                this.snackbarService.success('Report has been redacted');
              }
            });
        }
      });
  }

  openShareDialog() {
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      data: {
        sourceCopy: this.sourceCopy,
        slug: this.slug,
        content: this.content
      }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {

      });
  }

  toggleFollow() {
    if (this.following) {
      this.followerService.unfollow(this.source, this.slug, this.following?.id).subscribe(
        (response) => {
          if (response) {
            this.following = null;
            this.snackbarService.success(`You have unfollowed <b>${this.content.title}</b>`);
            this.changeDetectorRef.detectChanges();
          }
        });
    } else {
      const data = {
        follower: this.user.id
      };
      this.followerService.follow(this.source, this.slug, data).subscribe(
        (response: any) => {
          // console.log(response);
          this.following = response;
          this.snackbarService.success(`You have started following <b>${this.content.title}</b>`);
          this.changeDetectorRef.detectChanges();
        },
        (err) => {
          console.log(err);
        });
    }
  }

  toggleLike() {
    if (!this.user) {
      this.snackbarService.error('To perform this action, login or sign-up');
      this.redirectToLogin();
    } else {
      if (this.item.user_like) {
        this.likeService.unlike(this.source, this.slug, this.item.user_like?.id).subscribe(
          (response: any) => {
            if (response) {
              this.item.user_like = null;
              this.item.likes -= 1;
            }
          });
      } else {
        const data = {
          user: this.user.id
        };
        this.likeService.like(this.source, this.slug, data).subscribe(
          (response: any) => {
            this.item.user_like = response;
            this.item.likes += 1;
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  toggleBookmark() {
    if (!this.user) {
      this.snackbarService.error('To perform this action, login or sign-up');
      this.redirectToLogin();
    } else {
      if (this.item.bookmark) {
        this.bookmarkService.removeFromCollection(this.source, this.slug, this.item.bookmark?.id).subscribe(
          (response: any) => {
            if (response) {
              this.item.bookmark = null;
              this.snackbarService.success('Removed from collection');
            }
          });
      } else {
        const data = {
          user: this.user.id
        };
        this.bookmarkService.addToCollection(this.source, this.slug, data).subscribe(
          (response: any) => {
            this.item.bookmark = response;
            this.snackbarService.success('Added to collection');
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  redirectToLogin() {
    window.location.href = `${environment.loginUrl}authentication?service=sign-in&continue=${window.location.href}`;
  }

  showVersion() {
    this.version.emit();
  }


  goToEditPage() {
    console.log(this.source, this.item.uuid)
    if (this.source == 'neo_projects') {
      window.location.href = `${environment.projectUploaderUrl}project/${this.item.uuid}/edit`;
    }
    if (this.source == 'blogs') {
      window.location.href = `${environment.postUrl}edit/${this.item.uuid}/`;
    }
    if (this.source == 'publications') {
      window.location.href = `${environment.publicationUrl}edit/${this.item.uuid}/`;
    }
  }
}
