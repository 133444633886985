import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card/user-card.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
import { DirectiveModule } from '../../directives/directive.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ImagesModule } from '../images/images.module';

@NgModule({
  declarations: [UserCardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ImagesModule,
    FlexLayoutModule,
    MaterialModule,
    DirectiveModule,
    PipesModule
  ],
  exports: [
    UserCardComponent
  ]
})
export class PopoverCardsModule { }
