import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import { SafeContentPipe } from './pipes/safe-content/safe-content.pipe';
import { TimesincePipe } from './pipes/timesince/timesince.pipe';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { DurationPipe } from './pipes/duration/duration.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    SafeUrlPipe,
    SafeContentPipe,
    TimesincePipe,
    CapitalizePipe,
    DurationPipe
  ],
  imports: [CommonModule],
  exports: [TruncatePipe, SafeUrlPipe, SafeContentPipe, TimesincePipe, CapitalizePipe, DurationPipe]
})
export class PipesModule {}
