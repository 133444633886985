import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SnackBar } from '../../models/snackbar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarManagerService {
  snackBarQueue: SnackBar[] = [];
  snackBarQueueObservable = new BehaviorSubject<SnackBar[]>([]);

  constructor() {}

  addToPriorityQueue(newElement: SnackBar) {
    let enqueued = false;

    for (let index = 0; index < this.snackBarQueue.length; index++) {
      if (this.snackBarQueue[index].priority <= newElement.priority) {
        this.snackBarQueue.splice(index, 0, newElement);
        enqueued = true;
        this.snackBarQueueObservable.next(this.snackBarQueue);
        break;
      }
    }
    if (!enqueued) {
      this.snackBarQueue.push(newElement);
      this.snackBarQueueObservable.next(this.snackBarQueue);
    }
  }

  removeHighestPriorityElement() {
    if (this.isEmpty()) {
      console.log('underflow');
      return;
    } else {
      this.snackBarQueue.shift();
      this.snackBarQueueObservable.next(this.snackBarQueue);
    }
  }

  getHighestPriorityElement() {
    if (this.isEmpty()) {
      console.log('No elements in queue');
      return;
    } else {
      return this.snackBarQueue[0];
    }
  }

  getLowestPriorityElement() {
    if (this.isEmpty()) {
      console.log('No elements in queue');
      return;
    } else {
      return this.snackBarQueue[this.snackBarQueue.length - 1];
    }
  }

  isEmpty() {
    return this.snackBarQueue.length == 0;
  }
}
