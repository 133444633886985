import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UserDefaultImageService } from '@sparro/app/core/helpers/image/user-default-image.service';

import { OrganizationService } from '@sparro/app/core/api/organization/organization.service';
import { Organization } from '@sparro/app/core/models/organization.model';
import { environment } from '@sparro/env/environment';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'uni-organization-avatar',
  templateUrl: './organization-avatar.component.html',
  styleUrls: ['./organization-avatar.component.scss']
})
export class OrganizationAvatarComponent implements OnInit {
  @Input() organization: Organization;
  @Input() isJumboAvatar: boolean = false;
  @Input() self: boolean = false;
  @Input() isSmallAvatar: boolean = false;
  @Input() avatarClass: string = '';
  isUploading: boolean = false;
  default: string;
  default_char: string = '';
  isDesktop: boolean = false;

  @ViewChild('avatar') avatar: ElementRef;

  constructor(
    private userDefaultImageService: UserDefaultImageService,
    private orgService: OrganizationService,
    public changeDetectorRef: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit() {
    this.initializeBreakpoints();
  }

  initializeBreakpoints(): void {
    const tabletBreakpoint = BREAKPOINTS.above.md;
    this.breakpointObserver.observe([tabletBreakpoint]).subscribe(
      result => {
        this.isDesktop = result.breakpoints[tabletBreakpoint];
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organization?.currentValue) {
      this.organization = changes.organization.currentValue;
      this.setDefaultAvatar();
    }
    if (changes.self) {
      this.self = changes.self.currentValue;
    }
    this.changeDetectorRef.detectChanges();
  }

  setDefaultAvatar() {
    this.default = this.userDefaultImageService.setDefaultOrganizationAvatar(
      this.organization.title,
      this.organization.slug
    );
  }

  getOrganizationUrl() {
    return `${environment.organizationUrl}${this.organization.slug}/`;
  }

  uploadFile(event, type) {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      // this.files.push(element.name)
      const fileData = new FormData();
      fileData.append('pk', `${this.organization.id}`);
      fileData.append('title', '');
      fileData.append('description', '');
      fileData.append('file', element, element.name);
      fileData.append('type', 'logo');
      if (type === 'img') {
        this.uploadAvatarImage(element, fileData);
      } else {

      }
    }
  }

  uploadAvatarImage(element, fileData) {
    this.orgService.uploadOrganizationLogo(fileData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.isUploading = true;
        } else if (event.type === HttpEventType.Response) {
          // this.toastService.success('Profile Image Updated Successfully!')
          this.isUploading = false;
          // this.user.avatar_url = event.body.avatar_url
          this.organization.logo = event.body.logo;
          this.changeDetectorRef.detectChanges();
        }
      },
      (error) => {
        console.log(error);
        // this.toastService.error('Something went wrong, Please try again.')
        this.isUploading = false;
      }
    );
  }

  deleteAvatar() {
    console.log('deletion triggered');
  }
}
