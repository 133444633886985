import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CustomToastComponent } from '@sparro/app/shared/components/dialogs/custom-toast/custom-toast.component';
import { BehaviorSubject } from 'rxjs';

export interface snackBarOptions {
  action?: string,
  vPosition?: MatSnackBarVerticalPosition,
  hPosition?: MatSnackBarHorizontalPosition
}
@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  customMessage = new BehaviorSubject(null);
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone
  ) {

  }

  success(message, displayTime?: number, options?: snackBarOptions) {
    return this.custom(message, displayTime, options? options: null, 'success')
  }

  error(message, displayTime?: number, options?: snackBarOptions) {
    return this.custom(message, displayTime, options? options: null, 'error')
  }

  warn(message, displayTime?: number, options?: snackBarOptions) {
    return this.custom(message, displayTime, options ? options : null, 'warn')
  }

  info(message, displayTime?: number, options?: snackBarOptions) {
    return this.custom(message, displayTime, options ? options : null, 'info')
  }

  custom(msg, displayTime?: number, options?: snackBarOptions, type?: string, width?: string) {
    this.customMessage.next(msg);
    let pClass: string;
    switch (type) {
      case 'success':
        pClass = 'green-snackbar'
        break;
      case 'error':
        pClass = 'red-snackbar'
        break;
      case 'warn':
        pClass = 'orange-snackbar'
        break;
      case 'info':
        pClass = 'blue-snackbar'
        break;
      default:
        pClass = 'green-snackbar'
        break;
    }
    if (options?.action) {
        return this.snackBar.open(msg, (options.action ? options.action : null), {
        duration: displayTime? displayTime : 5000,
        direction: 'ltr',
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [pClass]
      })
    }
    this.zone.run(() => {
      return this.snackBar.openFromComponent(CustomToastComponent, {
        duration: displayTime ? displayTime : 5000,
        direction: 'ltr',
        verticalPosition: options && options.vPosition ? options.vPosition : 'top',
        horizontalPosition: options && options.hPosition ? options.hPosition : 'center',
        panelClass: [pClass = 'no-border']
      })
    })
  }
}
