import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = `${environment.serverUrl}${environment.baseUrl}`
  }

  getFeedPublications(page: number) {
    return this.http.get(this.baseUrl + 'publications/?page=' + page);
  }

  getPublication(slug: string) {
    return this.http.get(this.baseUrl + 'publications/' + slug + '/');
  }

  deletePublication(uuid: string) {
    return this.http.delete(this.baseUrl + 'publication/self/' + uuid + '/');
  }

  uploadPublicationCoverImage(fileData) {
    return this.http.post(this.baseUrl + 'publication_cover_upload/', fileData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  removePublicationCover(uuid: string) {
    return this.http.put(this.baseUrl + 'publication/self/' + uuid + '/remove_cover_image/', {});
  }

  getSeoContent() {
    return this.http.get(this.baseUrl + 'seo_content/publication/');
  }
}
