<div fxLayout="row">
  <div class="sidenav-main-container relative">
    <ng-container *ngIf="showSidenav">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        class="menu-icon-wrapper sidenav cursor-pointer"
        (click)="toggleLeftSidenav()">
        <i [class]="isOpened ? 'icon-cross-bold' : 'icon-hamburger-bold'"></i>
      </div>
    </ng-container>
    <a href="https://uni.xyz" class="no-decor">
      <button mat-button class="menu-icon-wrapper menu-hover">
        <uni-image
          src="https://uni-common-assets.s3.amazonaws.com/logo/uni/uni-logo-81x48.png"
          alt="uni-logo"
          classes="fit-content height-100 width-100">
        </uni-image>
      </button>
    </a>

    <!-- <div *ngFor="let navLink of leftNavLinks; index as i">
      <div *ngIf= "navLink.type === 'link'">
        <a [href]="navLink.path">
          <button mat-button class="nav-link-wrapper" [ngClass]="{'active-route': navLink.active}" [matTooltip]="navLink.name" matTooltipPosition="after" matTooltipClass="navlink-icon-tooltip">
            <i [class]="navLink.icon"></i>
          </button>
        </a>
      </div>
    </div> -->
    <div
      class="create-button"
      (click)="openCreateDialog()"
      matTooltip="Create"
      matTooltipPosition="after">
      <i class="icon-add-bold-fill"></i>
    </div>
    <ng-container *ngIf="user">
      <a href="https://beegraphy.com" class="no-decor" target="_blank">
        <div
          class="create-button no-mob"
          matTooltip="Try Beegraphy: Computational Design on Cloud"
          matTooltipPosition="after">
          <img
            src="https://uni-user.s3.amazonaws.com/so/sorampurifilessorampuri2024-09-02T01-45-37-069934.svg"
            alt="advertiser-icon"
            [height]="24" />
        </div>
      </a>
    </ng-container>
    <a
      [href]="
        activeApp
          ? activeApp.endsWith('/')
            ? activeApp
            : activeApp + '/'
          : discoverSections[0].url
      "
      class="explore-section no-decor"
      matTooltip="Explore"
      matTooltipPosition="after">
      <i class="icon-explore-circle-fill"></i>
    </a>

    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="margin-bottom-6 margin-x-2 absolute uni-for-section">
      <i class="border-section"></i>
      <p class="margin-y-4 margin-x-0 sparro-grey-800 font-xs">UNI for</p>
      <ng-container *ngFor="let navLink of leftNavLinks; index as i">
        <a
          [href]="navLink.path"
          class="no-decor"
          *ngIf="navLink.type == 'accordion'"
          [matTooltip]="navLink.name"
          matTooltipPosition="after">
          <button mat-button class="create-section">
            <i [class]="navLink.icon"></i>
            <!-- <p class="accordion-element-name"> {{navLink.name}} </p> -->
          </button>
        </a>
      </ng-container>
    </div>
  </div>
  <div class="discover-section-wrapper" fxLayout="column" *ngIf="showDiscoverSection">
    <div class="title-section">
      <p class="margin-0 title">Discover</p>
    </div>
    <ng-container *ngFor="let section of discoverSections">
      <a
        [href]="section.url"
        fxLayout="row"
        fxLayoutGap="8px"
        fxLayoutAlign="start center"
        class="no-decor section"
        [class.active]="activeFeed == section.title">
        <i [class]="section.icon + ' font-md'"></i>
        <p class="margin-0">{{ section.title }}</p>
      </a>
    </ng-container>
  </div>
</div>
