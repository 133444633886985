<ng-container *ngIf="self; else readOnly">
  <div [ngClass]="{ container: size === 'jumbo' }">
    <ng-container *ngIf="user">
      <uni-image
        [src]="
          user.avatar_url == 'https://s3.amazonaws.com/sparro-global/profile_pic.svg'
            ? default
            : user.avatar_url
        "
        [alt]="user.username"
        [default]="default"
        [width]="
          size === 'nano'
            ? 24
            : size === 'micro'
            ? 32
            : size === 'jumbo' && !isDesktop
            ? 128
            : size === 'medium'
            ? 144
            : size === 'jumbo' && isDesktop
            ? 256
            : 40
        "
        [height]="
          size === 'nano'
            ? 24
            : size === 'micro'
            ? 32
            : size === 'jumbo' && !isDesktop
            ? 128
            : size === 'medium'
            ? 144
            : size === 'jumbo' && isDesktop
            ? 256
            : 40
        "
        host="user-avatar"
        [auxClassList]="avatarClass"
        classes="circle fit-cover sparro-bg-white border vertical-align-middle">
      </uni-image>
    </ng-container>
    <div class="default-avatar-icon-box"></div>
    <div
      class="icon-container-box absolute top-0 left-0 bottom-0 right-0"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="8px">
      <i (click)="fileInput.click()" class="icon-upload">
        <input
          hidden
          type="file"
          #fileInput
          (change)="uploadFile($event, 'img')"
          accept="image/*" />
      </i>
      <i (click)="deleteAvatar()" class="icon-delete"></i>
    </div>
    <ng-container *ngIf="isUploading; else notUploading">
      <div class="loader">
        <uni-loader></uni-loader>
      </div>
    </ng-container>
    <ng-template #notUploading> </ng-template>
  </div>
</ng-container>
<ng-template #readOnly>
  <div *ngIf="user" #avatar (mouseenter)="togglePopover()" (mouseleave)="togglePopover()">
    <ng-container *ngIf="viewProfile; else onlyViewAvatar">
      <a [href]="getProfileUrl(user)" target="_blank">
        <ng-container *ngTemplateOutlet="onlyViewAvatar"> </ng-container>
      </a>
    </ng-container>
    <ng-template #onlyViewAvatar>
      <uni-image
        [src]="
          user.avatar_url == 'https://s3.amazonaws.com/sparro-global/profile_pic.svg'
            ? default
            : user.avatar_url
        "
        [alt]="user.username"
        [default]="default"
        [width]="
          size === 'nano'
            ? 24
            : size === 'micro'
            ? 32
            : size === 'jumbo' && !isDesktop
            ? 128
            : size === 'medium'
            ? 144
            : size === 'jumbo' && isDesktop
            ? 256
            : 40
        "
        [height]="
          size === 'nano'
            ? 24
            : size === 'micro'
            ? 32
            : size === 'jumbo' && !isDesktop
            ? 128
            : size === 'medium'
            ? 144
            : size === 'jumbo' && isDesktop
            ? 256
            : 40
        "
        host="user-avatar"
        [auxClassList]="avatarClass"
        classes="circle fit-cover sparro-bg-white border margin-auto">
      </uni-image>
    </ng-template>
  </div>
</ng-template>
