import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  previousUrl = new BehaviorSubject(null);

  leftNavLinks = [
    // {
    //   type   : 'link',
    //   name   : 'Home',
    //   path   : ``,
    //   icon   : 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/home-48x48.svg',
    //   active : false,
    // },
    {
      type: 'link',
      name: 'Competitions',
      path: `${environment.serverUrl}competitions/`,
      // path   : `http://dev.uni.xyz:4200/`,
      icon: 'icon-competition',
      active: false,
    },
    {
      type: 'link',
      name: 'Projects',
      path: `${environment.serverUrl}projects/`,
      icon: 'icon-project',
      active: false,
    },
    {
      type: 'link',
      name: 'Journals',
      path: `${environment.serverUrl}journal/`,
      // path   : `http://dev.uni.xyz:4300/`,
      icon: 'icon-post',
      active: false,
    },
    {
      type: 'link',
      name: 'Publications',
      path: `${environment.serverUrl}publications/`,
      icon: 'icon-publication',
      active: false,
    },
    // {
    //   type   : 'link',
    //   name   : 'Publications',
    //   path   : ``,
    //   icon   : 'icon-publication',
    //   active : false,
    // },
    // {
    //   type   : 'link',
    //   name   : 'Classrooms',
    //   path   : ``,
    //   icon   : 'icon-classroom',
    //   active : false,
    // },
    {
      type: 'accordion',
      name: 'Designers',
      path: `${environment.aboutUrl}about/designers`,
      icon: 'icon-designers-fill',
      active: false,
      children: [
        // {
        //   type: 'link',
        //   name: 'Create Portfolio',
        //   path: 'https://about.uni.xyz/portfolio',
        //   icon: '',
        //   active: false,
        // },
        // {
        //   type: 'link',
        //   name: 'Get Membership',
        //   path: 'https://about.uni.xyz/membership',
        //   icon: '',
        //   active: false,
        // }
      ]
    },
    {
      type: 'accordion',
      name: 'Experts',
      path: `${environment.aboutUrl}about/experts`,
      icon: 'icon-experts-fill',
      active: false,
      children: [
        // {
        //   type: 'link',
        //   name: 'Become a Curator',
        //   path: 'https://about.uni.xyz/curators',
        //   icon: '',
        //   active: false,
        // },
        // {
        //   type: 'link',
        //   name: 'Become a Mentor',
        //   path: 'https://about.uni.xyz/mentors',
        //   icon: '',
        //   active: false,
        // },
        // {
        //   type: 'link',
        //   name: 'Become a Juror',
        //   path: 'https://about.uni.xyz/jurors',
        //   icon: '',
        //   active: false,
        // },
      ]
    },
    {
      type: 'accordion',
      name: 'Business',
      path: `${environment.aboutUrl}about/business`,
      icon: 'icon-business-fill',
      active: false,
      children: [
        // {
        //   type: 'link',
        //   name: 'Host a Competition',
        //   path: 'https://about.uni.xyz/host',
        //   icon: '',
        //   active: false,
        // },
        // {
        //   type: 'link',
        //   name: 'List a Competition',
        //   path: 'https://about.uni.xyz/list',
        //   icon: '',
        //   active: false,
        // },
        // {
        //   type: 'link',
        //   name: 'Sponsor a Competition',
        //   path: 'https://about.uni.xyz/sponsor',
        //   icon: '',
        //   active: false,
        // },
      ]
    },
    // {
    //   type: 'accordion',
    //   name: 'Institutions',
    //   path: `${environment.aboutUrl}about/designers/institutional-access/`,
    //   icon: 'icon-institution',
    //   active: false,
    //   children: [
    //     {
    //       type: 'link',
    //       name: 'Launch a Course',
    //       path: 'https://about.uni.xyz/course',
    //       icon: '',
    //       active: false,
    //     },
    //     {
    //       type: 'link',
    //       name: 'Institutional Access',
    //       path: 'https://about.uni.xyz/institutional-access',
    //       icon: '',
    //       active: false,
    //     }
    //   ]
    // }
  ]

  constructor() { }

  populateDataInLeftnav(user: any, route: string) {
    this.leftNavLinks.map((navLink) => {
      if (navLink.type === 'link') {
        if (route.includes(navLink.path)) {
          navLink.active = true;
        }
      }
      else if (navLink.type === 'accordion') {
        navLink.children.map((element) => {
          if (element.path === route) {
            element.active = true;
          }
        })
      }
    })
    return this.leftNavLinks;
  }
}
