import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';

@Injectable({
  providedIn: 'root'
})
export class LikeService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  like(source: string, slug: string, data) {
    return this.http.post(this.baseUrl + source + '/' + slug + '/likes/', data);
  }

  unlike(source: string, slug: string, like_id: number) {
    return this.http.delete(this.baseUrl + source + '/' + slug + '/likes/' + like_id + '/');
  }
}
