import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpXsrfInterceptor } from './core/interceptors/auth.header.interceptor';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

import { MaterialModule } from '@sparro/app/material.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { ErrorComponent } from './modules/error/error.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { UNIAppModule } from '@sparro/app/app.module';
import { ButtonsModule } from '@sparro/app/shared/components/buttons/buttons.module';
import { LayoutModule } from '@sparro/app/shared/components/layout/layout.module';
import { LoaderModule } from '@sparro/app/shared/components/loader/loader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldsModule } from '@sparro/app/shared/components/forms/fields/fields.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CommonModule,
    FlexLayoutModule,
    CoreModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
    UNIAppModule.forRoot(),
    LayoutModule,
    ButtonsModule,
    LoaderModule,

    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    FieldsModule,
    SharedModule
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpXsrfInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
