import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@about/env/environment';

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getSimilarCompetitions() {
    return this.http.get(this.baseUrl + 'competitions/similar/');
  }

  getPromotedCompetitions() {
    return this.http.get(this.baseUrl + 'competitions/promoted/');
  }

  getOngoingCompetitions() {
    return this.http.get(
      this.baseUrl + 'competitions/ongoing_registration/?page=1&ordering=title/'
    );
  }

  getJurors(pageNumber: number, pageSize: number = 26) {
    return this.http.get(
      this.baseUrl + `competitions/jurors/?page=${pageNumber}&page_size=${pageSize}`
    );
  }

  getListedCompetitions() {
    return this.http.get(this.baseUrl + `competitions/?page=1&hosted=false`);
  }

  getHostedCompetitions() {
    return this.http.get(this.baseUrl + `competitions/?page=1&hosted=true`);
  }

  getSponsoredCompetitions() {
    return this.http.get(this.baseUrl + `competitions/ongoing_registration/?page=1`);
  }
  getMembershipCompetitions() {
    return this.http.get(this.baseUrl + `competitions/ongoing_registration/?page=1&hosted=true`);
  }
}
