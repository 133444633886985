<mat-nav-list fxLayoutGap="15px;" *ngIf="user">
  <a mat-button style="height: 0; position: absolute"></a>
  <a mat-list-item class="user-avatar-icon-wrapper" [href]="goToProfile()">
    <i class="icon-user" style="font-size: 30px; margin-right: 12px"></i>
    <span class="right-sidenav-icon-text">My profile</span>
  </a>

  <a mat-list-item [href]="goToUserProfileTab('competitions')" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-competition"></i>
    <span class="right-sidenav-icon-text">My competitions</span>
  </a>

  <a mat-list-item [href]="goToUserProfileTab('projects')" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-project"></i>
    <span class="right-sidenav-icon-text">My projects</span>
  </a>

  <a mat-list-item [href]="goToUserProfileTab('journals')" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-post"></i>
    <span class="right-sidenav-icon-text">My journals</span>
  </a>

  <a mat-list-item [href]="goToUserProfileTab('publications')" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-publication"></i>
    <span class="right-sidenav-icon-text">My publications</span>
  </a>

  <a mat-list-item [href]="goToUserBookmarks()" class="right-sidenav-icon-wrapper">
    <uni-image
      [src]="bookmarkSvg"
      alt="icon-bookmark"
      [height]="24"
      [width]="24"
      classes="fit-cover margin-right-4"></uni-image>
    <span class="right-sidenav-icon-text">My bookmarks</span>
  </a>

  <a mat-list-item [href]="goToSettings()" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-settings"></i>
    <span class="right-sidenav-icon-text">Settings</span>
  </a>

  <a mat-list-item [href]="goToPayment()" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-cart"></i>
    <span class="right-sidenav-icon-text">Cart</span>
  </a>
  <a mat-list-item *ngIf="isAdmin" (click)="goToImageUploader()" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-upload"></i>
    <span class="right-sidenav-icon-text">Upload images</span>
  </a>
  <mat-divider class="mat-divider"></mat-divider>

  <div mat-list-item fxLayout="column" class="content">
    <a [href]="goToUploadNewProject()" class="right-sidenav-inner-content">
      <p><span class="icon-add-bold"></span> Upload a project</p>
    </a>
    <a [href]="getUrl('journal')" class="right-sidenav-inner-content">
      <p><span class="icon-add-bold"></span> Write a journal</p>
    </a>
    <a [href]="getUrl('publication')" class="right-sidenav-inner-content">
      <p><span class="icon-add-bold"></span> Upload a publication</p>
    </a>
    <mat-divider class="bottom-divider"></mat-divider>
  </div>

  <a mat-list-item (click)="logout()" class="right-sidenav-icon-wrapper">
    <i class="right-sidenav-icon icon-logout" style="color: #d7373d"></i>
    <span class="right-sidenav-icon-text" style="color: #d7373d">Logout</span>
  </a>
</mat-nav-list>

<div
  class="footer-wrapper width-100"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  style="height: 140px">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" class="width-100">
    <a href="https://about.uni.xyz/" target="_blank" class="footer-link">About UNI</a>
    <a href="https://help.uni.xyz/portal/en/home" target="_blank" class="footer-link"
      >Help Center</a
    >
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" class="width-100">
    <a href="https://about.uni.xyz/terms-of-service/" target="_blank" class="footer-link"
      >Terms of Service</a
    >
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" class="width-100">
    <p class="font-bold sparro-grey-800 margin-0" style="margin: 0">
      <i class="icon-copyright-bold"></i> Uniegis Network Pvt. Ltd.
    </p>
  </div>
</div>
