import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[float-button-label]'
})
export class FloatButtonLabelDirective implements OnInit {
  @Input() isSmall: boolean

  constructor(private el: ElementRef) { }

  ngOnInit(){
    this.el.nativeElement.style.alignSelf = 'center';
    this.el.nativeElement.style.fontFamily = 'Roboto';

    if (this.isSmall == true){
      this.el.nativeElement.style.fontSize = "12px";
    } else{
      this.el.nativeElement.style.fontSize = "16px";
    }
  }
}
