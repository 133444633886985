import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private baseUrl = `api/v2/`;
  width = new BehaviorSubject('full');
  title = new BehaviorSubject('');
  leftNav = new BehaviorSubject(false);
  unreadNotificationCount = new BehaviorSubject(0);
  private menuOpen = new Subject<string>();

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  menuOpen$ = this.menuOpen.asObservable();

  openMenu() {
    this.menuOpen.next();
  }

  setHeaderWidth(size) {
    this.width.next(size);
  }

  getHeaderWidth() {
    return this.width;
  }

  setHeaderTitle(text) {
    this.title.next(text);
  }

  getHeaderTitle() {
    return this.title;
  }

  setLeftNav(value) {
    this.leftNav.next(value);
  }

  getLeftNav() {
    return this.leftNav;
  }

  getUreadNotificationCount() {
    return this.http.get(`${this.baseUrl}user_notifications/unread_count/`);
  }

  getNotificationCount() {
    return this.unreadNotificationCount;
  }

  setNotificationCount(count: number) {
    this.unreadNotificationCount.next(count);
  }

  getUserNotifications(page: number = 1) {
    return this.http.get(`${this.baseUrl}user_notifications/?page=${page}`);
  }

  markAllNotificationsRead() {
    return this.http.get(`${this.baseUrl}user_notifications/mark_all_read/`);
  }

  markNotificationRead(pk: number) {
    return this.http.put(`${this.baseUrl}user_notifications/${pk}/mark_read/`, {});
  }
}
