import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { OrganizationService } from '@sparro/app/core/api/organization/organization.service';
import { User } from '@sparro/app/core/models/user.model';
import { Organization } from '@sparro/app/core/models/organization.model';

@Component({
  selector: 'uni-organization-cover',
  templateUrl: './organization-cover.component.html',
  styleUrls: ['./organization-cover.component.scss']
})
export class OrganizationCoverComponent implements OnInit {
  @Input() organization: Organization;
  @Input() self: boolean;
  user: User;
  coverImage: string = "";
  defaultImage: string = "https://uni-common-assets.s3.amazonaws.com/illustrations/png/1/default-profile-cover.png";

  @ViewChild('cover') cover: ElementRef;
  isUploading: boolean = false;
  uploadCoverProgress: number = 0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orgService: OrganizationService,
  ) { }

  ngOnInit() {
    if (this.organization) {
      // this.getCoverImage()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organization?.currentValue) {
      this.coverImage = changes.organization.currentValue.cover;
    }
    if(changes.self) {
      this.self = changes.self.currentValue;
    }
    this.changeDetectorRef.detectChanges()
  }
  // ngAfterViewInit(): void {
  //   if (this.cover && this.self) {
  //     this.cover.nativeElement.classList.add('overlay')
  //   }
  // }

  // getCoverImage() {
  //   this.userService.getUserCover(this.user_id).subscribe(
  //     (response: any) => {
  //       if (response.cover_image) {
  //         this.coverImage = response.cover_image.url;
  //       }
  //     }
  //   )
  // }

  uploadFile(event, type) {
    var files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      // this.files.push(element.name)
      const fileData = new FormData();
      fileData.append('pk', `${this.organization.id}`);
      fileData.append('title', '');
      fileData.append('description', '');
      fileData.append('file', element, element.name);
      fileData.append('type', 'cover');
      if (type === 'img') {
        this.uploadCoverImage(element, fileData)
      } else {

      }
    }
  }

  uploadCoverImage(element, fileData) {
    this.orgService.uploadOrganizationLogo(fileData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.isUploading = true;
        } else if (event.type === HttpEventType.Response) {
          // this.toastService.success('Profile Image Updated Successfully!')
          this.isUploading = false;
          // this.user.avatar_url = event.body.avatar_url
          console.log(event.body, 'body');

          this.organization['cover'] = event.body['cover'];
          this.coverImage = event.body['cover'];
          this.changeDetectorRef.detectChanges()
        }
      },
      (error) => {
        console.log(error);
        // this.toastService.error('Something went wrong, Please try again.')
        this.isUploading = false;
      }
    )
  }

  deleteCoverImage() {
    // console.log("Requested to delete cover image");
  }
}
