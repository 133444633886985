<uni-state-button
  [activeState]="false"
  [default]="default"
  [button_class]="button_class"
  [icon_only]="icon_only"
  [icon_class]="icon_class"
  button_type="navigation"
  [disabled]="disabled"
  [showLoader]="showLoader"
  [customClass]="customClass"
  [loadingText]="loadingText">
</uni-state-button>
