import { Component, OnInit, Input, } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { LikeService } from '@sparro/app/core/api/like/like.service';
import { ToastrService } from '@sparro/app/core/helpers/toastr/toastr.service';

import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@sparro/env/environment';

@Component({
  selector: 'uni-like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss']
})
export class LikeButtonComponent implements OnInit {
  @Input() isNormal: boolean = true;
  @Input() isJumbo: boolean = false;
  @Input() source: string;
  @Input() item;
  @Input() showCount: boolean = false;
  @Input() border: boolean = false;

  liked: boolean = false;
  user: User;

  constructor(
    private userService: UserService,
    private likeService: LikeService,
    private toast: ToastrService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.userInitialized.subscribe(
      (initialized: boolean) => {
        if (initialized) {
          this.userService.user.subscribe(
            (user: User) => {
              this.user = user;
            }
          );
        }
      }
    );
  }

  toggleLike() {
    if (!this.user) {
      this.toast.error('To perform this action, please', 8000, {action: 'Login or Sign up'}).onAction().subscribe(
        (actionClicked) => {
          this.redirectToLogin();
        });
    } else {
      if(this.item.user_like) {
        this.likeService.unlike(this.source, this.item.slug, this.item.user_like?.id).subscribe(
          (response: any) => {
            if(response){
              this.item.user_like = null;
              this.item.likes -= 1;
            }
          });
      } else {
        const data = {
          user: this.user.id
        }
        this.likeService.like(this.source, this.item.slug, data).subscribe(
          (response: any) => {
            this.item.user_like = response;
            this.item.likes += 1;
          },
          (err) => {
            console.log(err);
          });
      }
    }
  }

  redirectToLogin() {
    window.location.href = `${environment.loginUrl}authentication?service=sign-in&continue=${window.location.href}`;
  }

}
