  <div class="iframe-wrapper" *ngIf="project_media.media_type === 'CODE'">
    <ng-container *ngIf="src; else notEmbed">
      <iframe class="iframe" [src]="src" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
    </ng-container>

    <ng-template #notEmbed>
      <p class="iframe">Not a valid Media</p>
    </ng-template>
  </div>
