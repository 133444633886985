export const environment = {
  production: false,
  staging: false,
  imageOptimizer: true,
  baseUrl: 'api/v2/',
  competitionUrl: 'http://localhost:4000/',
  postUrl: 'http://localhost:4001/',
  loginUrl: 'http://localhost:4003',
  projectUrl: 'http://localhost:4004/',
  profileUrl: 'http://localhost:4008/',
  studioUrl: 'http://localhost:4010/',
  serverUrl: 'http://localhost:8000/',
};
