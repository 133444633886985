import { Directive, Input, Output, EventEmitter, HostListener } from "@angular/core";

@Directive({
  selector: '[copyToClipboard]'
})
export class CopyToClipboardDirective {
  @Input('copyToClipboard')
  public copiedString: string;

  @Output('copied')
  public copied: EventEmitter<string> = new EventEmitter<string>();

  constructor(
  ) { }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.copiedString) {
      return;
    }

    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', this.copiedString.toString());
      e.preventDefault();

      this.copied.emit(this.copiedString);
    };

    document.addEventListener('copy', listener, false)
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }


}
