<div fxLayout="row" class="width-100" *ngIf="user" fxLayoutGap="12px">
  <uni-comment-user
    [user]="user"
    [avatar_mode]="true">
  </uni-comment-user>

  <div fxLayout="column" fxFlex="90">
    <form [formGroup]="commentForm" class="width-100">
      <div class="comment-wrapper">
        <mat-chip-list *ngIf="mentioned_users && mentioned_users.size > 0">
          <mat-chip *ngFor="let user of mentioned_users" [removable]="new" (removed)="removeMention(user)">
            @{{ user.first_name | titlecase }} &nbsp;
            <mat-icon matChipRemove *ngIf="new">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-divider style="width:100%; margin-top:4px; border-top-style: dashed;" *ngIf="mentioned_users && mentioned_users.size > 0">
        </mat-divider>

        <div class="width-100 comment-input-field">
          <textarea matInput class="font-sm sparro-grey-800" formControlName="comment" maxlength="2000"
            placeholder="{{ (source === 'competitions') ? 'Initiate a discussion' : 'Write your response here' }}" cdkTextareaAutosize
            cdkAutosizeMaxRows="3" cdkAutosizeMinRows="3" class="comment-textbox">
          </textarea>
        </div>
      </div>

      <div fxLayout="row" style="justify-content:flex-end" fxLayoutGap="16px">
        <uni-navigation-button
          *ngIf="(source !== 'competitions') && commentForm.valid"
          default="Cancel"
          button_class="secondary"
          (click)="cancel()">
        </uni-navigation-button>
        <uni-navigation-button
          default="{{ (source === 'competitions') ? 'Post' : 'Comment' }}"
          button_class="primary"
          [disabled]="!commentForm.valid"
          (click)="submit()">
        </uni-navigation-button>
      </div>
    </form>
  </div>
</div>
