import { Component, OnInit } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@journal/env/environment';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { RouteService } from '@sparro/app/core/helpers/route/route.service';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  previousUrl: any;
  error: any;
  customErrorVariables = [
    {
      code: '401',
      type: 'Unauthorized',
      image: 'https://uni-common-assets.s3.amazonaws.com/illustrations/svg/1/404.svg',
      message: 'You will need to Log-in or Sign-up to continue',
      redirect: {
        message: 'Click here to login',
        url: `${environment.loginUrl}`
      }
    },
    {
      code: '403',
      type: 'Forbidden',
      image: 'https://uni-common-assets.s3.amazonaws.com/illustrations/svg/1/404.svg',
      message: 'You do not have the required permissions to visit this page.',
      redirect: {
        message: `If you are sure that this should not be the case, please tell us how you reached
          here and what you expectxed to find in the chat below and we will try our best to help.`,
        url: ``
      }
    },
    {
      code: '404',
      type: 'Not Found',
      image: 'https://uni-common-assets.s3.amazonaws.com/illustrations/svg/1/404.svg',
      message: 'Sorry, the page that you are looking for cannot be found',
      redirect: {
        message: `Please recheck the link you entered. If you are sure that this is the correct address,
          please tell us how you reached here and what you expected to find in the chat below. We will try our best to help.`,
        url: ``
      }
    }
  ];
  defaultErrorVariables = {
    code: '404',
    type: 'Not Found',
    image: 'https://uni-common-assets.s3.amazonaws.com/illustrations/svg/1/404.svg',
    message: 'Looks like something went wrong',
    redirect: {
      message:
        'Please explain the issue that you are facing in the chat below and we will try our best to help you.',
      url: ``
    }
  };

  constructor(
    public routeService: RouteService,
    public route: ActivatedRoute,
    public router: Router,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.routeService.previousUrl.subscribe(prevUrl => {
      this.previousUrl = prevUrl;
    });
    this.getQueryParams();
  }

  getQueryParams(): void {
    const isQueryParams = this.route.snapshot.queryParams.type;
    if (isQueryParams) {
      this.setCustomErrorVariables();
    } else {
      this.setDefaultErrorVariables();
    }
  }

  setCustomErrorVariables(): void {
    this.route.queryParams.subscribe(params => {
      this.customErrorVariables.map(errorObj => {
        if (errorObj.code === params.type) {
          this.error = errorObj;
          this.updateMetaInfo();
        }
      });
    });
  }

  setDefaultErrorVariables(): void {
    this.error = this.customErrorVariables[2];
    this.updateMetaInfo();
  }

  updateMetaInfo(): void {
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: `Error | ${this.error.code} ${this.error.type}` },
      {
        name: 'description',
        content: `You have reached a realm of unknowns. Please
        explain to us how you have reached here and we will try our best to help you out.`
      },
      { name: 'url', content: window.location.href },
      { name: 'image', content: this.error.image },
      { name: 'robots', content: 'noindex' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(`Error | ${this.error.code} ${this.error.type}`);
    this.metaService.createCanonicalURL();
  }
}
