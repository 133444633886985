import { Injectable } from '@angular/core';

import { environment as aboutEnv } from '@about/env/environment';
import { environment as accountEnv } from '@account/env/environment';
import { environment as arenaEnv } from '@b2b/env/environment';
import { environment as builderEnv } from '@builder/env/environment';
import { environment as discussionEnv } from '@discussion/env/environment';
import { environment as evalEnv } from '@eval/env/environment';
import { environment as journalEnv } from '@journal/env/environment';
import { environment as organizationEnv } from '@organization/env/environment';
import { environment as paymentEnv } from '@payment/env/environment';
import { environment as profileEnv } from '@profile/env/environment';
import { environment as projectEnv } from '@project/env/environment';
import { environment as publicationEnv } from '@publication/env/environment';
import { environment } from '@sparro/env/environment';
import { environment as studioEnv } from '@studio/env/environment';
import { environment as topicEnv } from '@topic/env/environment';

const ENV_CONFIG = [
  { url: environment.aboutUrl, env: aboutEnv },
  { url: environment.loginUrl, env: accountEnv },
  { url: environment.competitionUrl, env: arenaEnv },
  { url: environment.projectUploaderUrl, env: builderEnv },
  { url: environment.discussionUrl, env: discussionEnv },
  { url: environment.evalUrl, env: evalEnv },
  { url: environment.postUrl, env: journalEnv },
  { url: environment.organizationUrl, env: organizationEnv },
  { url: environment.paymentUrl, env: paymentEnv },
  { url: environment.profileUrl, env: profileEnv },
  { url: environment.projectUrl, env: projectEnv },
  { url: environment.publicationUrl, env: publicationEnv },
  { url: environment.studioUrl, env: studioEnv },
  { url: environment.topicUrl, env: topicEnv }
];

type _MATCH_CONFIG = { case: number; regex: RegExp };

const MATCH_CONFIG: _MATCH_CONFIG[] = [
  // CASE-1 http://s3.amazonaws.com/bucket/key1/key2
  { case: 1, regex: /^https?:\/\/s3.amazonaws.com\/([^\/]+)\/?(.*?)$/ },
  // CASE-2 http://s3-aws-region.amazonaws.com/bucket/key1/key2
  { case: 2, regex: /^https?:\/\/s3-([^.]+).amazonaws.com\/([^\/]+)\/?(.*?)$/ },
  // CASE-3  http://bucket.s3.amazonaws.com/key1/key2
  { case: 3, regex: /^https?:\/\/([^.]+).s3.amazonaws.com\/?(.*?)$/ },
  // CASE-4 http://bucket.s3-aws-region.amazonaws.com/key1/key2
  { case: 4, regex: /^https?:\/\/([^.]+).s3-([^\.]+).amazonaws.com\/?(.*?)$/ },
  // CASE-5 http://bucket.s3.aws-region.amazonaws.com/key1/key2
  { case: 1, regex: /^https?:\/\/([^.]+).s3.([^\.]+).amazonaws.com\/?(.*?)$/ }
];

@Injectable({
  providedIn: 'root'
})
export class ImageOptimizerService {
  allowedBuckets = [
    'uni-user',
    'uni-blog',
    'uni-competitions',
    'uni-projects',
    'competitions-uni',
    'uni-common-assets',
    'uni-publications'
  ];
  // optimizerServerURL = 'https://d2hqpkr4oi3jr6.cloudfront.net/'; // NOTE: This could be fetched from env in future
  optimizerServerURL = 'https://ojo2mx38l6.execute-api.ap-south-1.amazonaws.com/image/'; //https://dl1cuijfra.execute-api.us-east-1.amazonaws.com/image/
  constructor() {}

  renderImage(image: string, xRes: number | string, yRes: number | string): string {
    const windowLoc = window.location.href;
    if (this.isAppAllowed(windowLoc)) {
      const matched = this.matchImgUrl(image);
      if (matched.match) {
        if (this.isBucketAllowed(matched.match, matched.config.case)) {
          try {
            return this.optimizeImage(matched.match, matched.config.case, xRes, yRes);
          } catch {
            // pass
          }
        }
      }
    }
    return image;
  }

  isAppAllowed(windowLoc: string): boolean {
    const envConfig = ENV_CONFIG.find(config => windowLoc.includes(config.url));
    return envConfig?.env?.imageOptimizer;
  }

  matchImgUrl(image: string): { match: RegExpMatchArray; config: _MATCH_CONFIG } {
    const url = decodeURIComponent(image);
    let match: RegExpMatchArray = null;
    let config: _MATCH_CONFIG = null;

    for (const matchConfig of MATCH_CONFIG) {
      match = url.match(matchConfig.regex);
      if (match) {
        config = matchConfig;
        break;
      }
    }
    return { match, config };
  }

  isBucketAllowed(match: RegExpMatchArray, caseNumber: number): boolean {
    return this.allowedBuckets.indexOf(caseNumber === 2 ? match[2] : match[1]) > -1;
  }

  optimizeImage(
    match: RegExpMatchArray,
    caseNumber: number,
    xRes: number | string,
    yRes: number | string
  ): string {
    const postObject = {
      bucket: caseNumber === 2 ? match[2] : match[1],
      key: caseNumber === 1 || caseNumber === 3 ? match[2] : match[3],
      edits: {
        resize: {
          width: xRes ? xRes : '',
          fit: 'cover'
        },
        flatten: false,
        normalise: false
      }
    };
    if (yRes !== '') {
      postObject['edits']['resize']['height'] = yRes;
    }
    let stringifyData = JSON.stringify(postObject);
    stringifyData = stringifyData.replace(/\s/g, '');
    stringifyData = stringifyData.replace(/[\u00A0-\u2666]/g, c => '&#' + c.charCodeAt(0) + ';');
    const encodedUrl = btoa(unescape(stringifyData));
    return this.optimizerServerURL.concat(encodedUrl);
  }
}
