import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@sparro/app/shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ReportDialogComponent } from '@sparro/app/shared/components/dialogs/report-dialog/report-dialog.component';
import { NeoComment } from '@sparro/api/comment/comment.model';
import { Report } from '@sparro/app/core/models/report.model';
import { User } from '@sparro/app/core/models/user.model';
import { CommentService } from '@sparro/app/core/api/comment/comment.service';

@Component({
  selector: 'uni-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comment: NeoComment;
  @Input() showFooter: boolean = true;
  @Input() user: User;
  @Input() slug: string;
  @Input() source: string;
  @Input() owner_flag: boolean;
  @Input() nested: boolean = false;
  @Input() max_nest_depth: number = 0;
  @Input() current_nest_depth: number = 0;
  can_edit: boolean = false;
  edit_mode: boolean = false;
  nested_reply_event = false;
  expanded: boolean = false;

  @Output() removed = new EventEmitter<NeoComment>();
  @Output() mentioned = new EventEmitter<any>();

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public commentService: CommentService
  ) {}

  ngOnInit() {
    this.getAuthUser();
  }

  getAuthUser() {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
          this.checkAuthUser();
        });
      }
    });
  }

  checkAuthUser() {
    if (this.comment.user.id === this.user.id) {
      this.can_edit = true;
    }
  }

  removeComment(comment) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '380px',
      height: '165px',
      data: {
        message: `<p style="text-align:center; margin:0">Are you sure you want to
          delete this comment?</p>`,
        okayButtonText: 'Yes'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        NeoComment.removeComment(this.source, this.slug, comment.id).subscribe(
          response => {
            // console.log(response);
            if (comment.child_count === 0) {
              this.removed.emit(comment);
            } else {
              comment.comment = 'This comment has been removed';
              comment.is_removed = true;
            }
          },
          err => {
            console.log(err);
          }
        );
      } else {
      }
    });
  }

  highlightComment(comment) {
    NeoComment.highlightComment(this.source, this.slug, comment.id).subscribe(
      res => {
        // console.log(res, "Highlighted");
        comment.is_highlighted = !comment.is_highlighted;
      },
      err => {
        console.log(err, 'Nope');
      }
    );
  }

  nestedReplyEvent(event) {
    this.nested_reply_event = !this.nested_reply_event;
  }

  editComment() {
    this.edit_mode = true;
  }

  commentEditted(data: any) {
    this.comment = data;
    this.edit_mode = false;
  }

  userMentioned(data: any) {
    // console.log(data);
    this.mentioned.emit(data);
  }

  report(user: User) {
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      data: {
        user: this.user,
        slug: user.username,
        source: 'users'
      }
    });
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
        // this.project.report = response;
      }
    });
  }

  redact(user: User) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `This will withdraw the report and the action cannot be undone.
        Are you sure you want to continue?`,
        okayButtonText: 'Okay'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Report.redactReport('users', user.username, user.report?.id).subscribe((response: any) => {
          if (response) {
          }
        });
      }
    });
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
