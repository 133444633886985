import { Component, AfterViewInit, Input, ContentChildren, QueryList, AfterContentChecked, Renderer2, ElementRef } from '@angular/core';
import { ToggleButtonComponent } from '../toggle-button/toggle-button.component';

@Component({
  selector: 'uni-toggle-buttons-group',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./toggle-buttons-group.component.scss']
})
export class ToggleButtonsGroupComponent implements AfterViewInit, AfterContentChecked {

  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() buttonGap = 0;
  @Input() isDivider: boolean = false;
  @ContentChildren(ToggleButtonComponent) buttons: QueryList<ToggleButtonComponent>;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.setButtonsLayout();
    this.setDividers();
  }

  ngAfterContentChecked() {
  }

  setButtonsLayout() {
    this.elementRef.nativeElement.classList.add(this.orientation === 'horizontal' ? 'horizontal' : 'vertical');
    this.buttons.forEach((button) => {
      this.renderer.setStyle(button.el.nativeElement, 'margin', this.buttonGap);
    })
  }

  setDividers() {
    if (this.isDivider) {
      this.buttons.forEach((button) => {
        // TODO: Set direction of mat-divider, depending on this.orientation
        var divider = button.renderer.createElement('mat-divider');
        button.renderer.appendChild(button.el.nativeElement.childNodes[1], divider)
      })
    }
  }

}
