import { CommonModule } from '@angular/common';
// import { MaterialModule } from './material.module';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { UniApiConfig } from './core/config/api.config';
import { UniConnectionConfig } from './core/config/connection.config';
import { FieldsModule } from './shared/components/forms/fields/fields.module';
import { UNISharedModule } from './shared/shared.module';

declare module '@angular/core' {
  interface ModuleWithProviders<T= any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
    UNISharedModule,
    FieldsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class UNIAppModule {
  public static forRoot(options?: any): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: [
        UniApiConfig,
        UniConnectionConfig
      ]
    };
  }
}
