<div fxLayout="column" fxLayoutGap="16px" class="snack-bar-wrapper">
  <ng-container *ngFor="let snackbar of snackbars">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between flex-start"
      class="message-wrapper"
      [ngClass]="{
        'green-snackbar': snackbar.snackBarItem.type === 'success',
        'red-snackbar': snackbar.snackBarItem.type === 'error',
        'orange-snackbar': snackbar.snackBarItem.type === 'warning',
        'blue-snackbar': snackbar.snackBarItem.type === 'info'
      }">
      <p [innerHTML]="snackbar.snackBarItem.message | safeContent" class="message"></p>
      <i class="icon-cross" (click)="closeSnackbarByUser(snackbar)"></i>
    </div>
  </ng-container>
</div>
