<div fxLayout="column" fxLayoutAlign="flex-start center">
  <ng-container *ngIf="content">
    <div fxLayout="column" class="content-card-container">
      <uni-image [src]="content?.cover_image ?  content.cover_image : defaultContentImage" alt="contentImage"
        [height]="213"
        [width]="318"
        auxClassList="content-card-image">
      </uni-image>
      <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center flex-start" class="margin-2">
        <span class="title-text" *ngIf="content?.title">
          {{ content.title | truncate: mobileView ? 30 : 40 }}
        </span>
        <span class="sub-title-text sparro-grey-800" *ngIf="content?.subtitle">
          {{ content.subtitle | truncate: mobileView ? 120 : 145}}
        </span>
      </div>
    </div>
  </ng-container>
  <div fxLayout="column" [ngClass]="{'social-share-container': true, 'no-content': !content}">
    <p class="text-center margin-0">Share this with your community</p>

    <div fxLayout="row" fxLayoutGap="30px" class="inner-container" style="margin: 48px auto;">
      <ng-container *ngFor="let icon of shareIcons">
        <a [url]="shareUrl" [shareButton]="icon.name" class="cursor-pointer">
          <uni-image [src]="icon.link" [alt]="icon.name" [width]="48" [height]="48" auxClassList="share-button-image"></uni-image>
        </a>
      </ng-container>
    </div>

    <div class="copy-link-container" fxLayout="row">
      <input class="sparro-grey-700" [value]="shareUrl" readonly>
      <ng-container *ngIf="mobileView; else desktopView">
        <button mat-icon-button [cdkCopyToClipboard]="shareUrl" (cdkCopyToClipboardCopied)="copiedLink()">
          <uni-image 
            [src]="copyIcon" 
            alt="icon-copy" 
            [width]="30" 
            [height]="30" 
            classes="fit-cover align-middle">
          </uni-image>
        </button>
      </ng-container>
      <ng-template #desktopView>
        <button mat-button [cdkCopyToClipboard]="shareUrl" (cdkCopyToClipboardCopied)="copiedLink()">Copy Link</button>
      </ng-template>
    </div>
  </div>
</div>