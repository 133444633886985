<div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="12px">
  <div fxLayout="row">
    <i *ngIf="!user" class="button-icon-disabled icon-arrow-up-bold"></i>
    <i *ngIf="user && vote === 1" (click)="upvoteClicked()" class="button-icon-active icon-arrow-up-bold"></i>
    <i *ngIf="user && vote !== 1" (click)="upvoteClicked()" class="button-icon-inactive icon-arrow-up-bold"></i>

    <p class="font-sm margin-0 sparro-grey-900 padding-1">{{ score }}</p>

    <i *ngIf="!user" class="button-icon-disabled icon-arrow-down-bold"></i>
    <i *ngIf="user && vote === -1" (click)="downvoteClicked()" class="button-icon-active icon-arrow-down-bold"></i>
    <i *ngIf="user && vote !== -1" (click)="downvoteClicked()" class="button-icon-inactive icon-arrow-down-bold"></i>
  </div>
  <div>
    <a *ngIf="can_reply && user" (click)="mentionUser()" class="sparro-grey-900 reply-btn">Reply</a>
    <a *ngIf="!user" class="sparro-grey-900 reply-btn">Reply</a>
  </div>

  <div>
    <a *ngIf="nested" (click)="nestedReplyEvent()" class="sparro-grey-900 reply-btn" [ngPlural]="comment.child_count">
      <ng-template ngPluralCase="=0">Reply</ng-template>
      <ng-template ngPluralCase="=1">Reply [1]</ng-template>
      <ng-template ngPluralCase="other">Replies [{{comment.child_count}}]</ng-template>
    </a>
  </div>
</div>
