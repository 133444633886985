export const environment = {
  production: false,
  staging: false,
  imageOptimizer: true,
  baseUrl: 'api/v2/',
  competitionUrl: 'http://localhost:4000/',
  postUrl: 'http://localhost:4001/',
  loginUrl: 'http://localhost:4003/',
  projectUrl: 'http://localhost:4004/',
  profileUrl: 'http://localhost:4006',
  paymentUrl: 'http://localhost:4005/',
  serverUrl: 'http://localhost:8000/',
  OAUTH_CLIENT_ID: '104426002907-6kptslkba6n999d2faba14l5u2ipn9cm.apps.googleusercontent.com',
  FACEBOOK_ID: '161864937791871'
};
