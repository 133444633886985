<div *ngIf="user">
  <uni-comment-edit
    [owner_flag]="owner_flag"
    [juror_flag]="juror_flag"
    [user]="user"
    [slug]="slug"
    [new]="true"
    [source]="source"
    [mentioned_users]="mentioned_users"
    (remove_mention)="removeMention($event)"
    (clear_comment)="clearComment($event)"
    (comment_response)="emitComment($event)"
    [nested]="nested"
    [parent]="parent"
    [child_group]="child_group">
  </uni-comment-edit>
</div>
<ng-content select="[commentPlaceholder]"></ng-content>
