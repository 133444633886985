export const environment = {
  production: true,
  staging: false,
  imageOptimizer: true,
  baseUrl: '/api/v2/',
  serverUrl: 'https://uni.xyz/',
  aboutUrl: 'https://uni.xyz/',
  loginUrl: 'https://uni.xyz/accounts/',
  competitionUrl: 'https://uni.xyz/competitions/',
  projectUrl: 'https://uni.xyz/projects/',
  postUrl: 'https://uni.xyz/journal/',
  paymentUrl: 'https://uni.xyz/payment/transaction/',
  profileUrl: 'https://uni.xyz/profile/',
  publicationUrl: 'https://uni.xyz/publications/',
  organizationUrl: 'https://uni.xyz/organization/'
};
