<div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-4">
    <p class="font-bold font-md margin-0">Create</p>
    <i class="icon-cross-bold font-md cursor-pointer" (click)="close()"></i>
</div>
<div fxLayout="column" class="content">
    <ng-container *ngFor="let section of sections">
        <a [href]="section.url" class="no-decor dialog-content" (click)="close()">
            <p class="font-sm margin-y-3"><i [class]="section.icon+' font-md'"></i> {{section.title}}</p>
        </a>
    </ng-container>

    <ng-container *ngIf="!activeMembership">
        <mat-divider style="margin: 12px !important;"></mat-divider>
        <a href="https://uni.xyz/about/designers/membership/" class="dialog-content no-decor" (click)="close()">
          <i class="icon-membership font-lg sparro-orange-600"></i>
          <p class="font-sm sparro-orange-600">Become a member</p>
        </a>
      </ng-container>
</div>