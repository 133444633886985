import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, custom_limit?: number) : string {
    let limit = custom_limit ? custom_limit : 35;
    let trail = '...';

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
