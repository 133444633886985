import { ImagesModule } from './../images/images.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldsModule } from '../forms/fields/fields.module';
import { PipesModule } from '../../pipes/pipes.module';
import { DialogModule } from '../dialogs/dialog.module';
import { ReportDialogComponent } from '../dialogs/report-dialog/report-dialog.component';
import { ShareDialogComponent } from '../dialogs/share-dialog/share-dialog.component';

@NgModule({
  declarations: [LeaderboardComponent],
  entryComponents: [ReportDialogComponent, ShareDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FieldsModule,
    PipesModule,
    DialogModule,
    ImagesModule
  ],
  exports: [
    LeaderboardComponent
  ]
})
export class TablesModule { }
