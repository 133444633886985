import { Component, ElementRef, HostListener, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '@sparro/app/core/api/auth/auth.service';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { User } from '@sparro/app/core/models/user.model';
import _ from 'lodash';

export class FooterLink {
  title: string;
  link: string;
}

export class FooterSection {
  title: string;
  footer_link: FooterLink[];
}

export class UniFooter {
  footer_section: FooterSection[];
  uni_social_link: any;
  footer_tagline: string;
  footer_tagline_icon: string;
  foot_note: any;
}

@Component({
  selector: 'uni-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class PublicFooterComponent implements OnInit {
  @Input() seoContent: any;
  @Input() topMargin: boolean = true;
  user: User;
  uniFooter: UniFooter;
  footNote: any;
  @ViewChild('container') containerDiv: ElementRef;
  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
  emailControl: FormControl = new FormControl('', {
    validators: [Validators.required, Validators.pattern(this.emailRegex)]
  });

  small: boolean = false;
  medium: boolean = false;
  large: boolean = false;
  xlarge: boolean = false;
  xLargeFooter: number = 1499;
  largeFooter: number = 799;
  mediumFooter: number = 540;
  footerCurrentWidth: number;
  currentYear: number;
  subscriptionStatus: string = '';

  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.authService.getAuthUser().subscribe(
      (user: User) => {
        if (user) {
          this.user = user;
        }
      }
    );
    this.getUniFooter();
  }

  ngAfterViewInit(): void {
    this.getBreakpoints();
  }

  @HostListener('window:resize', ['$event'])
  getBreakpoints(): void {
    if (this.footerCurrentWidth !== this.containerDiv.nativeElement.offsetWidth) {
      this.footerCurrentWidth = this.containerDiv.nativeElement.offsetWidth;
      if (this.footerCurrentWidth > this.xLargeFooter) {
        this.xlarge = true;
        this.small = this.medium = this.large = false;
      }
      else if (this.footerCurrentWidth > this.largeFooter) {
        this.large = true;
        this.small = this.medium = this.xlarge = false;
      }
      else if (this.footerCurrentWidth > this.mediumFooter) {
        this.medium = true;
        this.small = this.large = this.xlarge = false;
      }
      else {
        this.small = true;
        this.medium = this.large = this.xlarge = false;
      }
    }
  }

  getUniFooter(): void {
    this.userService.getUniFooter().subscribe((response: UniFooter[]) => {
      if (response.length) {
        this.uniFooter = response[0];
        const visibleIndex = _.random(0, this.uniFooter.foot_note.length - 1);
        this.footNote = this.uniFooter.foot_note[visibleIndex];
      }
    });
  }

  subscribeNewsLetter(): void {
    if (this.emailControl.valid) {
      const subscribersInfo = {
        email: this.emailControl.value,
      };
      this.userService.subscribeNewsletter(subscribersInfo).subscribe((response: any) => {
        if (response.message) {
          this.subscriptionStatus = response.message;
        }
      }, (error) => {
        this.subscriptionStatus = error;
      });
    }
  }
  getErrorMessage(): string {
    return this.emailControl.hasError('required') ? 'You must enter an email' :
      this.emailControl.hasError('pattern') ? 'Not a valid email' : '';
  }
}
