import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { Subject } from 'rxjs';
import { User } from '@sparro/app/core/models/user.model';
import { map } from 'rxjs/operators';
import { MetaService } from '../meta/meta.service';

@Injectable({
  providedIn: 'root'
})
export class StorageHandlerService {
  private baseUrl: string = `${environment.baseUrl}`;
  authChange: Subject<User> = new Subject();
  blogOwnerDetail = new Subject();
  publicationOwnerDetail = new Subject();
  projectOwnerDetail = new Subject();
  organizationMemberDetail = new Subject();
  user: User;
  blogOwner: object;
  projectOwner: object;
  publicationOwner: object;
  organizationMember: object;
  authenticated: boolean = false;
  timeout: number = 86400000; // 1 day in ms

  constructor(private http: HttpClient, private metaService: MetaService) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  // Added helper functions for Unicode-safe encoding and decoding
  utf8ToBase64(str: string): string {
    return btoa(encodeURIComponent(str));
  }

  base64ToUtf8(str: string): string {
    return decodeURIComponent(atob(str));
  }

  storeItem(key: string, value: any): void {
    const timeStampedValue = { value, timestamp: new Date().getTime().toString() };
    let stringifiedValue = JSON.stringify(timeStampedValue);
    
    // Modified: Use utf8ToBase64 for Unicode-safe encoding
    const encodedValue = this.utf8ToBase64(stringifiedValue);
    localStorage.setItem(key, encodedValue);
  }

  getItem(key: string): any {
    const data = localStorage.getItem(key);
    if (data !== null) {
      // Modified: Use base64ToUtf8 for Unicode-safe decoding
      const decodedString = this.base64ToUtf8(data);
      const timeStampedJSON = JSON.parse(decodedString);
      const jsonData = timeStampedJSON.value;
      const now = new Date().getTime();
      const timestamp = parseInt(timeStampedJSON.timestamp);
      if ((timestamp && now - timestamp > this.timeout) || !timestamp) {
        this.removeItem(key);
        return null;
      }
      return jsonData;
    }
    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getUser() {
    return this.http.get(this.baseUrl + 'profiles/auth/').pipe(
      map(response => {
        return this.metaService.decodeApiResponse(response);
      })
    );
  }

  getHeavyUser(user: number) {
    return this.http.get(this.baseUrl + 'profiles/' + user + '/heavy/');
  }

  getCurrentUserLocalStorage() {
    const data = localStorage.getItem('user');
    if (data) {
      // Modified: Use base64ToUtf8 for Unicode-safe decoding
      const decodedString = this.base64ToUtf8(data);
      return JSON.parse(decodedString);
    } else {
      return data;
    }
  }

  setCurrentUserLocalStorage(user: any) {
    let stringifyData = JSON.stringify(user);
    // Modified: Use utf8ToBase64 for Unicode-safe encoding
    const encodedUrl = this.utf8ToBase64(stringifyData);
    if (this.getCurrentUserLocalStorage() != null) {
      localStorage.removeItem('user');
    }
    localStorage.setItem('user', encodedUrl);
  }

  getEntityOwnerLocalStorage(entityType: string) {
    const data = localStorage.getItem(entityType);
    if (data == null) {
      return data;
    } else {
      // Modified: Use base64ToUtf8 for Unicode-safe decoding
      const decodedString = this.base64ToUtf8(data);
      const jsonData = JSON.parse(decodedString);
      return jsonData;
    }
  }

  setEntityOwnerLocalStorage(ownerDetail: any, entityType: string) {
    let stringifyData = JSON.stringify(ownerDetail);
    // Modified: Use utf8ToBase64 for Unicode-safe encoding
    const encodedUrl = this.utf8ToBase64(stringifyData);
    localStorage.setItem(entityType, encodedUrl);
  }

  // Additional API methods below, unchanged
  getEditViewBlogInfo(uuid: string) {
    return this.http.get(this.baseUrl + 'blog/self/' + uuid + '/');
  }

  getEditViewPublicationInfo(uuid: string) {
    return this.http.get(this.baseUrl + 'publication/self/' + uuid + '/');
  }

  getEditViewProjectInfo(uuid: string) {
    return this.http.get(this.baseUrl + 'neo_project/self/' + uuid + '/teams/');
  }

  getDetailViewBlogInfo(slug: string) {
    return this.http.get(this.baseUrl + 'blogs/' + slug + '/');
  }

  getDetailViewPublicationInfo(slug: string) {
    return this.http.get(this.baseUrl + 'publications/' + slug + '/');
  }

  getDetailViewProjectInfo(slug: string) {
    return this.http.get(this.baseUrl + 'neo_projects/' + slug + '/meta/');
  }

  getOrganizationMemberInfo(slug: string) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/is_member/');
  }

  setProjectPermission(key: string, data: any): void {
    let value = this.getItem(key);
    const localData = value ? value : {};
    localData[data.uuid] = data.type;
    this.storeItem(key, localData);
  }

  getProjectPermission(key: string, searchField: string): string {
    const uuids = this.getItem(key);
    return uuids && searchField in uuids ? uuids[searchField] : null;
  }
}

