import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private baseUrl = `api/v2/`;
  activeSearchHost = new BehaviorSubject('');
  activeAppFeedUrl = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getBulletins(host: string) {
    return this.http.get(`${this.baseUrl}bulletins/?host=${host}`);
  }

  setActiveSearchHost(host) {
    this.activeSearchHost.next(host);
  }

  getActiveSearchHost() {
    return this.activeSearchHost;
  }

  setActiveAppFeedUrl(name) {
    this.activeAppFeedUrl.next(name);
  }

  getActiveAppFeedUrl() {
    return this.activeAppFeedUrl;
  }
}
