import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CompetitionService } from './api/competition/competition.service';

// import { HeaderComponent } from '@sparro/app/shared/components/layout/header/header.component';
// import { RightSidenavComponent } from '@sparro/app/shared/components/layout/right-sidenav/right-sidenav.component';

@NgModule({
  declarations: [],
  // entryComponents: [
  //   HeaderComponent,
  //   RightSidenavComponent
  // ],
  imports: [CommonModule, RouterModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  // exports: [
  //   RouterModule,
  //   HttpClientModule,
  //   FormsModule,
  //   ReactiveFormsModule,
  // ],
  providers: [CompetitionService]
})
@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class CoreModule {}
