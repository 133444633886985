import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UNI_CONSTANTS } from '@sparro/app/core/config/constants.config';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { ImageMetaData } from '@sparro/core/models/media.model';

@Component({
  selector: 'uni-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() auxClassList: string = '';
  @Input() host: string = '';
  @Input() src: string;
  @Input() alt: string;
  @Input() height: number;
  @Input() width: number;
  @Input() classes: string;
  @Input() default: string = UNI_CONSTANTS.NO_IMAGE.DEFAULT;
  // TODO: Add a flag for optimizer
  html: SafeHtml;
  jsonLdData: { [key: string]: any };
  defaultLicense: string = 'https://to.uni.xyz/license';
  renderedImage: string = null;
  currentImageUrl: string;

  @ViewChild('imgContainer') imageContainer: ElementRef; 

  constructor(private imageOptimizer: ImageOptimizerService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.setJsonLdScema();
    this.currentImageUrl = this.src;
    // this.getRenderedImage();
  }

  ngAfterViewInit() {
    this.getRenderedImage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setJsonLdScema();
    this.getRenderedImage();
  }
  setJsonLdScema(): void {
    this.jsonLdData = this.createImageLicenseSchema();
    this.html = this.getSafeHTML(this.jsonLdData);
  }

  createImageLicenseSchema(): { [key: string]: any } {
    const schema = {
      '@context': 'https://schema.org/',
      '@type': 'ImageObject',
      contentUrl: '',
      license: this.defaultLicense
    };
    schema.contentUrl = this.src;
    return schema;
  }

  getRenderedImage() {
    if (this.src) {
      if (
        !this.src.includes('uni-common-assets') ||
        (this.src.includes('uni-common-assets') &&
          this.src.split('.').pop() !== 'svg' &&
          this.src.split('.').pop() !== 'png' &&
          this.src.split('.').pop() !== 'webp')
      ) {
        // return 
        this.renderImage();
      } else {
        this.renderedImage = this.src;
        // return this.renderedImage;
      }
    }
  }

  renderImage() {
    // console.log(this.imageContainer?.nativeElement?.offsetWidth, "here");
    
    if (!this.renderedImage || this.currentImageUrl != this.src) {
      this.currentImageUrl = this.src;
      if (this.host === 'user-avatar') {
        this.renderedImage = this.imageOptimizer.renderImage(this.src, 300, 300);
      } else {
        this.renderedImage = this.imageOptimizer.renderImage(
          this.src,
          this.width ? this.width : this.imageContainer.nativeElement.offsetWidth < 1281 ? (
            this.imageContainer.nativeElement.offsetWidth < 541 ? this.imageContainer.nativeElement.offsetWidth*2 : 
            this.imageContainer.nativeElement.offsetWidth*1.5) : this.imageContainer.nativeElement.offsetWidth*1.2,
          this.height ? this.height : ''
        );
      }
    }
    // return this.renderedImage;
  }

  getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
    const json = jsonLD
      ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>')
      : '';
    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
