<div class="wrapper">
  <div fxLayout="row" class="margin-bottom-4" fxLayoutAlign="space-between center">
    <p class="margin-0 title">Be a Part of Something Bigger</p>
    <i class="icon-cross-bold cross-icon cursor-pointer absolute" (click)="closeDialog()"></i>
  </div>

  <section fxLayout="column" fxLayoutGap="20px">
    <div class="content">
      Fascinated by architectural creativity, design contests, and storytelling? You're in the right
      place! To keep our space flourishing and responsive to our community, we need your helping
      hand.
      <br /><br />

      Take action today and become a catalyst for creativity with us!
      <br /><br />
      <span class="font-bolder">Announcement:</span> Introducing the UNIFID chatbot, a new way to
      support our contributors. As part of your contribution, access the cutting-edge chatGPT and
      your preferred text-to-image generation tool. Give it a try!
    </div>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
      <a href="https://uni.xyz/about/contribute-to-unifid" class="no-decor">
        <button class="dia-btn cursor-pointer">Show me how?</button>
      </a>

      <a href="https://unifidchat.com" target="_blank" class="no-decor">
        <button class="show-me-btn cursor-pointer">Let's Chat!</button>
      </a>
    </div>
  </section>
</div>
