import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { ToastrService } from '@sparro/app/core/helpers/toastr/toastr.service';
import { BREAKPOINTS, SOCIAL_SHARE_ICONS } from '@sparro/core/config/constants.config';
import { environment } from '@sparro/env/environment';

export class ShareIcon {
  link: string;
  name: string;
}
@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {
  content: any;

  defaultContentImage: string = 'https://uni-common-assets.s3.amazonaws.com/images/noimage/noimage_s.jpg';
  copyIcon: string = 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/copy/black_bold_48x48.svg';
  shareUrl: string;

  shareIcons: ShareIcon[];

  mobileView: boolean = false;

  constructor(
    public toast: ToastrService,
    public breakpointObserver: BreakpointObserver,
    private imageOptimization: ImageOptimizerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.shareIcons = SOCIAL_SHARE_ICONS;
    this.shareUrl = this.data.sourceCopy == 'competitions'? window.location.href : `${environment.serverUrl}${this.data.sourceCopy}/${this.data.slug}`;
    this.content = this.data.content;
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([
      BREAKPOINTS.below.md
    ]).subscribe(result => {
      this.mobileView = result.breakpoints[BREAKPOINTS.below.md];
    });
  }

  copiedLink(): void {
    this.toast.success('Link copied to clipboard');
  }

  renderImage(image: string): string {
    return this.imageOptimization.renderImage(image, window.innerWidth, '');
  }
}
