import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@sparro/app/material.module';
import { EmbedVideo } from 'ngx-embed-video';
import { ShareModule } from 'ngx-sharebuttons';

import { PipesModule } from '../../pipes/pipes.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { CommentsModule } from '../comments/comments.module';
import { LoaderModule } from '../loader/loader.module';
import { PanelModule } from '../panel/panel.module';

import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { EmbedVideoDialogComponent } from './embed-video-dialog/embed-video-dialog.component';
import { ImagePropertiesDialogComponent } from './image-properties-dialog/image-properties-dialog.component';
import { ImageUploaderDialogComponent } from './image-uploader-dialog/image-uploader-dialog.component';
import { PreviewPaneComponent } from './preview-pane/preview-pane.component';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import { SnackbarDisplayComponent } from './snackbar-display/snackbar-display.component';
import { StatusDialogComponent } from './status-dialog/status-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { ImagesModule } from '@sparro/app/shared/components/images/images.module';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { UnifidContributionComponent } from './unifid-contribution/unifid-contribution.component';

@NgModule({
  declarations: [
    ImagePropertiesDialogComponent,
    ConfirmationDialogComponent,
    SuccessDialogComponent,
    CustomToastComponent,
    StatusDialogComponent,
    PreviewPaneComponent,
    ReportDialogComponent,
    ImageUploaderDialogComponent,
    ShareDialogComponent,
    EmbedVideoDialogComponent,
    SnackbarDisplayComponent,
    CreateDialogComponent,
    UnifidContributionComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    SuccessDialogComponent,
    CustomToastComponent,
    StatusDialogComponent,
    ReportDialogComponent,
    EmbedVideoDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    LoaderModule,
    PipesModule,
    ButtonsModule,
    DirectiveModule,
    PanelModule,
    CommentsModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    EmbedVideo.forRoot(),
    ImagesModule
  ],
  exports: [
    ImagePropertiesDialogComponent,
    ConfirmationDialogComponent,
    SuccessDialogComponent,
    StatusDialogComponent,
    ReportDialogComponent,
    ImageUploaderDialogComponent,
    ShareDialogComponent,
    EmbedVideoDialogComponent
  ]
})
export class DialogModule {}
