export const environment = {
  production: false,
  staging: false,
  imageOptimizer: true,
  baseUrl: '/api/v2/',
  competitionUrl: 'http://localhost:4000',
  loginUrl: 'http://localhost:4003',
  profileUrl: 'http://localhost:4006',
  serverUrl: 'http://localhost:8000',
};
