import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaryClassDirective } from '@sparro/app/shared/directives/auxiliary-class.directive';
import { CopyToClipboardDirective } from '@sparro/app/shared/directives/copy-to-clipboard.directive';
import { ButtonTitleDirective } from '@sparro/app/shared/directives/button-title.directive';
import { ButtonClassDirective } from '@sparro/app/shared/directives/buttons/button-class.directive';
import { ButtonIconDirective } from '@sparro/app/shared/directives/buttons/button-icon.directive';
import { FloatButtonDirective } from '@sparro/app/shared/directives/float-button/float-button.directive';
import { ImagePreloadDirective } from '@sparro/app/shared/directives/image-preload.directive';
import { FloatButtonDirectionDirective } from '@sparro/app/shared/directives/float-button/float-button-direction.directive';
import { FloatButtonLabelDirective } from '@sparro/app/shared/directives/float-button/float-button-label.directive';
import { ClassListsDirective } from './aux-class.directive';

@NgModule({
  declarations: [
    AuxiliaryClassDirective,
    ClassListsDirective,
    CopyToClipboardDirective,
    ButtonClassDirective,
    ButtonIconDirective,
    ButtonTitleDirective,
    FloatButtonDirective,
    FloatButtonDirectionDirective,
    FloatButtonLabelDirective,
    ImagePreloadDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AuxiliaryClassDirective,
    ClassListsDirective,
    CopyToClipboardDirective,
    ButtonClassDirective,
    ButtonIconDirective,
    ButtonTitleDirective,
    FloatButtonDirective,
    FloatButtonDirectionDirective,
    FloatButtonLabelDirective,
    ImagePreloadDirective
  ]
})
export class DirectiveModule { }
