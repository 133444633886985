import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UNI_CONSTANTS } from '@sparro/core/config/constants.config';
import { StorageHandlerService } from '@sparro/core/helpers/storage/storage-handler.service';
import { environment } from '@sparro/env/environment';
import { of } from 'rxjs';
import { MetaService } from '../../helpers/meta/meta.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private baseUrl = `api/v2/`;

  constructor(
    private http: HttpClient,
    private metaService: MetaService,
    private storage: StorageHandlerService
  ) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getProject(slug: string) {
    return this.http.get(this.baseUrl + 'neo_projects/' + slug + '/');
  }

  checkUserProjectLike(slug: string) {
    return this.http.get(this.baseUrl + 'neo_projects/' + slug + '/check_liked/');
  }

  likeProject(slug: string) {
    return this.http.put(this.baseUrl + 'neo_projects/' + slug + '/like/', {});
  }

  getImageProperties(media_uuid: string) {
    return this.http.get(this.baseUrl + 'neo_medias/' + media_uuid + '/');
  }

  getEmbedProperties(media_uuid: string) {
    return this.http.get(this.baseUrl + 'neo_medias/' + media_uuid + '/');
  }

  getProjectMeta(slug: string) {
    return this.http.get(this.baseUrl + 'neo_projects/' + slug + '/meta/');
  }

  getProjectVersion(slug: string, version_uuid: string) {
    return this.http.get(this.baseUrl + 'neo_projects/' + slug + '/version/' + version_uuid + '/');
  }

  getProjectVersionMeta(slug: string, version_uuid: string) {
    return this.http.get(
      this.baseUrl + 'neo_projects/' + slug + '/version/' + version_uuid + '/meta/'
    );
  }

  deleteProject(project_uuid: string) {
    return this.http.delete(this.baseUrl + 'neo_project/self/' + project_uuid + '/');
  }

  togglePrivate(project_uuid: string) {
    return this.http.put(
      this.baseUrl + 'neo_project/self/' + project_uuid + '/toggle_private/',
      {}
    );
  }
  checkOwner(projectUuid: string, projectSlug: string): boolean {
    const storageData = {
      uuid: projectUuid,
      type: 'owner'
    };
    const permission = this.storage.getProjectPermission(
      UNI_CONSTANTS.PERMISSIONS.PROJECT['KEY'],
      projectUuid
    );
    if (permission && permission === UNI_CONSTANTS.PERMISSIONS.PROJECT.OWNER) {
      return true;
    } else {
      this.getIsOwner(projectSlug).subscribe(
        response => {
          if (response.owner) {
            this.storage.setProjectPermission(UNI_CONSTANTS.PERMISSIONS.PROJECT.KEY, storageData);
            return of(true);
          } else {
            return of(false);
          }
        },
        error => {
          return of(false);
        }
      );
    }
  }
  getIsOwner(projectSlug: string): any {
    return this.http.get(this.baseUrl + 'neo_projects/' + projectSlug + '/check_owner/');
  }
}
