import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Comment } from '@sparro/api/comment/comment.model';

@Component({
  selector: 'uni-comment-create',
  templateUrl: './comment-create.component.html',
  styleUrls: ['./comment-create.component.scss']
})
export class CommentCreateComponent implements OnInit {
  @Input() user: any;
  @Input() slug: any;
  @Input() owner_flag: boolean;
  @Input() juror_flag: boolean;
  @Input() source: string;
  @Input() nested = false;
  @Input() parent: number;
  @Input() child_group = false;
  @Input() mentioned_users: Set<any>;

  @Output() comment_response = new EventEmitter<Comment>()
  @Output() remove_mention = new EventEmitter<any>()
  @Output() clear_comment = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
    // console.log(this.user, "User", this.slug);
  }

  emitComment(data: any) {
    this.comment_response.emit(data);
  }

  removeMention(data: any) {
    this.remove_mention.emit(data);
  }

  clearComment(data: any) {
    this.clear_comment.emit(data);
  }
}
