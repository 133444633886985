import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { EmbedVideo } from 'ngx-embed-video';
import { ShareModule } from 'ngx-sharebuttons';

import { MaterialModule } from '@sparro/app/material.module';

import { ButtonsModule } from './components/buttons/buttons.module';
import { CardsModule } from './components/cards/cards.module';
import { CommentsModule } from './components/comments/comments.module';
import { DialogModule } from './components/dialogs/dialog.module';
import { ImagesModule } from './components/images/images.module';
import { LayoutModule } from './components/layout/layout.module';
import { LoaderModule } from './components/loader/loader.module';
import { PopoverModule } from './components/popover/popover.module';
// import { ChartsModule } from './components/charts/charts.module';
import { PipesModule } from './pipes/pipes.module';

import { DynamicScriptLoaderService } from '../core/helpers/script-loader/dynamic-script-loader.service';
// import { ErrorComponent } from './components/error-page/not-found/error.component';
import { PanelModule } from './components/panel/panel.module';
import { UNIRouteComponent } from './components/route/uni-route/uni-route.component';
import { UploaderModule } from './components/uploader/uploader.module';
import { DirectiveModule } from './directives/directive.module';
import { TablesModule } from './components/tables/tables.module';
import { PopoverCardsModule } from './components/popover-cards/popover-cards.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    EmbedVideo.forRoot(),
    ShareModule,
    CardsModule,
    ButtonsModule,
    PipesModule,
    LoaderModule,
    ImagesModule,
    LayoutModule,
    CommentsModule,
    DialogModule,
    PopoverModule,
    PanelModule,
    DirectiveModule,
    UploaderModule,
    PopoverCardsModule,
    LottieModule.forRoot({ player: playerFactory })
    // ChartsModule
  ],
  declarations: [
    UNIRouteComponent
    // ErrorComponent,
  ],
  entryComponents: [],
  exports: [
    CommentsModule,
    CardsModule,
    PipesModule,
    ButtonsModule,
    LoaderModule,
    ImagesModule,
    LayoutModule,
    DialogModule,
    PanelModule,
    // ChartsModule,
    UNIRouteComponent,
    // ConfirmationDialogComponent,
    // ErrorComponent,
    PopoverModule,
    PopoverCardsModule,
    DirectiveModule,
    UploaderModule,
    TablesModule,
    LottieModule
  ],
  providers: [DynamicScriptLoaderService]
})
export class UNISharedModule {}
