<ng-container *ngIf="isLoading; else viewTemplate">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 400px">
    <uni-loader></uni-loader>
  </div>
</ng-container>

<ng-template #viewTemplate>
  <div class="width-100 report-section">
    <div fxLayout="row" class="margin-bottom-4" fxLayoutAlign="space-between center">
      <p class="font-sm font-bold margin-0">Report this content</p>
      <mat-icon (click)="close()" class="cursor-pointer icon-cross-bold font-md"></mat-icon>
    </div>

    <mat-radio-group
      *ngIf="reportArchTypes?.length > 0"
      aria-labelledby="report-group-label"
      class="report-radio-group margin-bottom-4"
      (change)="getArchType($event)">
      <ng-container *ngFor="let archType of reportArchTypes">
        <ng-container *ngIf="reportTypeIds.length; else defaultView">
          <ng-container *ngIf="reportTypeIds.includes(archType.id)">
            <ng-container *ngTemplateOutlet="defaultView"> </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #defaultView>
          <mat-radio-button class="report-radio-button" [disableRipple]="true" [value]="archType">
            <span class="sparro-grey-800 font-sm">
              {{ archType.title | titlecase }}
            </span>
            <span>
              <i class="icon-info" matTooltip="{{ archType.info }}" matTooltipPosition="left"></i>
            </span>
          </mat-radio-button>

          <div class="report-type-wrapper" *ngIf="archType.active">
            <ng-container *ngIf="archType?.types?.length > 0">
              <div class="width-100 type-form-field">
                <mat-select
                  [formControl]="typeControl"
                  placeholder="Select one category"
                  [multiple]="false">
                  <mat-option
                    *ngFor="let type of archType.types"
                    [value]="type"
                    (click)="setReportType(type)">
                    {{ type.title }}
                  </mat-option>
                </mat-select>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </mat-radio-group>

    <mat-form-field class="width-100 url-input-form-field" appearance="outline">
      <input
        matInput
        [formControl]="urlControl"
        placeholder="Add URL"
        class="font-sm sparro-grey-800"
        [readonly]="readOnlyUrl" />
    </mat-form-field>

    <mat-hint align="start" class="sparro-grey-700" style="font-size: 12px">
      Add URL of account, project, journal, publication etc.
    </mat-hint>

    <div class="width-100 margin-top-4 custom-textarea">
      <textarea
        matInput
        class="font-sm sparro-grey-800"
        placeholder="Provide additional comments"
        [formControl]="reportInfoControl"
        cdkTextareaAutosize
        cdkAutosizeMaxRows="3"
        cdkAutosizeMinRows="2">
      </textarea>
    </div>

    <div fxLayout="row" fxLayoutGap="12px" *ngIf="!imageUrl">
      <input hidden type="file" #fileInput (change)="uploadFile($event)" accept="image/*" />
      <i class="icon-image icon-button" (click)="fileInput.click()"></i>
      <!-- <i class="icon-embed icon-button"></i> -->
    </div>

    <div class="image-preview-section margin-y-4" *ngIf="imageUrl">
      <uni-image
        [src]="imageUrl"
        alt="Report evidence"
        [width]="200"
        [height]="130"
        classes="fit-cover rounded-border">
      </uni-image>
    </div>

    <div class="margin-bottom-4">
      <p class="font-sm sparro-grey-800">
        Please review our
        <a href="https://uni.xyz/policy" target="_blank" style="text-decoration: none"
          >Platform policies</a
        >
        for additional information about what is and isn't permitted on UNI.
      </p>
    </div>

    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="24px">
      <uni-navigation-button default="Cancel" button_class="secondary" (click)="close()">
      </uni-navigation-button>
      <uni-navigation-button
        default="Report"
        button_class="primary"
        [disabled]="checkControlState()"
        (click)="createReport()">
      </uni-navigation-button>
    </div>
  </div>
</ng-template>
