import {
	animate,
	state,
	style,
	transition,
	trigger
} from '@angular/animations';

export const animateButtons = [
	trigger(
		'buttonAnimation',
		[
			transition(
				'void => right', [
					style({ transform: 'translateX(-100%) scale(0.5)', opacity: 0 }),
					animate('400ms ease-out', style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
				]
			),
			transition(
				'right => void', [
					style({ transform: 'translateX(0) scale(1)', opacity: 1 }),
					animate('400ms ease-in', style({ transform: 'translateX(-100%) scale(0.5)', opacity: 0 }))
				]
			),
			transition(
				'void => left', [
					style({ transform: 'translateX(100%) scale(0.5)', opacity: 0 }),
					animate('400ms ease-out', style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
				]
			),
			transition(
				'left => void', [
					style({ transform: 'translateX(0) scale(1)', opacity: 1 }),
					animate('400ms ease-in', style({ transform: 'translateX(100%) scale(0.5)', opacity: 0 }))
				]
			),
			transition(
				'void => down', [
					style({ transform: 'translateY(-100%) scale(0.5)', opacity: 0 }),
					animate('400ms ease-out', style({ transform: 'translateY(0) scale(1)', opacity: 1 }))
				]
			),
			transition(
				'down => void', [
					style({ transform: 'translateY(0) scale(1)', opacity: 1 }),
					animate('400ms ease-in', style({ transform: 'translateY(-100%) scale(0.5)', opacity: 0 }))
				]
			),
			transition(
				'void => up', [
					style({ transform: 'translateY(100%) scale(0.5)', opacity: 0 }),
					animate('400ms ease-out', style({ transform: 'translateY(0) scale(1)', opacity: 1 }))
				]
			),
			transition(
				'up => void', [
					style({ transform: 'translateY(0) scale(1)', opacity: 1 }),
					animate('400ms ease-in', style({ transform: 'translateY(100%) scale(0.5)', opacity: 0 }))
				]
			)
		]
	),
	trigger(
		'labelAnimation',
		[
			transition(
				':enter', [
					style({ transform: 'scale(0.6)', opacity: 0 }),
					animate('100ms 300ms ease', style({ transform: 'scale(1)', opacity: 1 }))
				]
			),
			transition(
				':leave', [
					style({ transform: 'scale(1)', opacity: 1 }),
					animate('200ms ease-out', style({ transform: 'scale(0.6)', opacity: 0 }))
				]
			)
		]
	),
	trigger('animateMain', [
		state('false', style({
			transform: 'rotate(0deg)',

		})),
		state('true', style({
			transform: 'rotate(135deg)',
			color: 'white', background: 'black',
		})),
		transition('* <=> *', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
	]),
];
