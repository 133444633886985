import { Renderer2, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[classList]'
})

// USAGE => <element ...[ClassList]="class names here" >

export class ClassListsDirective {
  @Input() classList: string;

  constructor(
    protected renderer: Renderer2,
    protected element: ElementRef
  ) { }

  ngOnInit() {
  }

  ngAfterContentChecked(): void {
    this.addClass();
  }

  addClass(): void {
    if (this.element.nativeElement && this.classList) {
      const auxClassList = this.classList.split(' ');
      for (const className of auxClassList) {
        this.renderer.addClass(this.element.nativeElement, className);
      }
    }
  }
}
