export const environment = {
  production: false,
  staging: false,
  imageOptimizer: true,
  baseUrl: 'api/v2/',
  competitionUrl: 'http://localhost:4000/',
  postUrl: 'http://localhost:4001/',
  loginUrl: 'http://localhost:4003/',
  projectUrl: 'http://localhost:4004/',
  imageUrl: 'http://localhost:4004/image/',
  profileUrl: 'http://localhost:4006/',
  serverUrl: 'http://localhost:8000/',
  publicationUrl: 'http://localhost:4011/',
  organizationUrl: 'http://localhost:4009/',
};
