import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { UserCardComponent } from '@sparro/app/shared/components/popover-cards/user-card/user-card.component';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  static instance: PopoverService;

  private overlayRef: OverlayRef;
  private popoverVisible = false;
  public data: any;

  constructor(private overlay: Overlay) {
    PopoverService.instance = this;
  }

  toggleUserPopover(anchor: any, user: any) {
    this.data = user;
    this.popoverVisible = !this.popoverVisible;
    if (this.popoverVisible) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(anchor)
          .withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top'
            },
            {
              originX: 'end',
              originY: 'top',
              overlayX: 'end',
              overlayY: 'bottom'
            },
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top'
            },
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom'
            }
          ]),
        scrollStrategy: this.overlay.scrollStrategies.reposition()
      });

      const popoverPortal = new ComponentPortal(UserCardComponent);
      this.overlayRef.attach(popoverPortal);
    } else {
      this.overlayRef.dispose();
    }
  }
}
