import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return value.charAt(0).toUpperCase() + value.toString().substr(1, value.length).toLowerCase();

    }
    return value;
  }
  
}
