<!-- <mat-sidenav-container #matSidenav hasBackdrop="false" *ngIf="!onlyDesktopView">
  <mat-sidenav mode="push" disableClose="true" opened="true" 
    position="start" fixedInViewport="'fixed'" fixedTopGap=0 fixedBottomGap=0 class="left-sidenav">
    <uni-left-sidenav-collapsed [user]="user" (toggle)="toggleLeftSidenav()"></uni-left-sidenav-collapsed>
  </mat-sidenav>
</mat-sidenav-container> -->

<ng-container *ngIf="onlyDesktopView; else defaultView">
  <ng-container *ngTemplateOutlet="desktopView"> </ng-container>
</ng-container>
<ng-template #defaultView>
  <ng-container *ngIf="smallView; else desktopView">
    <div class="global-navbar-mobile" fxFlex>
      <div fxLayout="row" style="width: 100%; align-items: center">
        <button class="menu-button" mat-button (click)="toggleLeftSidenav()">
          <i class="icon-hamburger-bold"></i>
        </button>

        <a [href]="getHomePageUrl()" class="no-decor cursor-pointer uni-logo-wrapper">
          <uni-image
            src="https://uni-common-assets.s3.amazonaws.com/logo/uni/uni-logo-81x48.png"
            alt="UNI"
            [width]="45"
            [height]="45"
            classes="fit-cover align-middle">
          </uni-image>
        </a>

        <div fxLayoutAlign="end end" style="margin-left: auto">
          <ng-container *ngIf="user">
            <!-- <a href="https://unifidchat.com" class="no-decor" target="_blank">
              <button mat-icon-button class="search-icon-wrapper-mobile">
                <img
                  src="https://uni-common-assets.s3.us-east-1.amazonaws.com/home/ai-btn.svg"
                  alt="ai-bot-icon"
                  [height]="24" />
              </button>
            </a> -->
            <!-- <mat-menu cdkDrag #dropDownMenu="matMenu" class="chat-container">
              <uni-ai-chat-bot [user]="user"></uni-ai-chat-bot>
            </mat-menu> -->
          </ng-container>
          <button
            mat-icon-button
            class="search-icon-wrapper-mobile"
            [disabled]="isSearchRoute"
            (click)="goToSearchPage()"
            *ngIf="search">
            <i class="sparro-grey-800 icon-search-bold" style="font-size: x-large; margin: 0"></i>
          </button>
          <a href="https://uni.xyz/notifications" class="no-decor-link header-notification-badge">
            <button
              mat-icon-button
              class="search-icon-wrapper-mobile"
              matBadge="."
              [matBadgeHidden]="unreadNotificationCount?.value === 0">
              <i class="icon-bell-fill sparro-grey-800" style="font-size: x-large; margin: 0"></i>
            </button>
          </a>

          <ng-container *ngIf="user; else noUser">
            <button mat-icon-button (click)="toggleRightSidenav()" class="user-avatar-wrapper">
              <uni-user-avatar
                [user]="user"
                avatarClass="avatar-image"
                [viewProfile]="false"
                [showPopover]="false"></uni-user-avatar>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="title-wrapper" fxLayout="row" fxLayoutAlign="center center" *ngIf="headerTitle">
      <div class="width-100 margin-0 padding-x-1 font-bolder text-center">
        {{ headerTitle | truncate : 40 }}
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #desktopView>
  <mat-toolbar
    [ngClass]="{
      'global-navbar': true,
      transparent: transparentHeader,
      'title-wrapper': headerWidth != 'none',
      'no-left-menu': headerWidth == 'medium'
    }"
    fxFlex>
    <div class="width-100 margin-0 padding-x-1 font-bolder" *ngIf="headerWidth != 'none'">
      {{ headerTitle | truncate : 40 }}
    </div>
    <div fxLayoutGap="8px" fxLayout="row" style="height: 40px">
      <form
        class="navbar-item-search"
        [ngStyle]="{ width: !user ? '224px' : '158px' }"
        [formGroup]="searchfield"
        (ngSubmit)="onSubmit()"
        *ngIf="!isSearchRoute && search">
        <div class="search-bar">
          <button mat-icon-button class="search-icon-wrapper">
            <i class="sparro-grey-500 icon-search"></i>
          </button>
          <input
            type="font-sm"
            formControlName="search"
            placeholder="Search on UNI"
            name="search"
            oninput="this.value = this.value.toLowerCase()"
            class="search-bar-input" />
        </div>
      </form>

      <ng-container *ngIf="user; else noUser">
        <div class="menu-section" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <a [href]="goToProfile()" class="no-decor-link" style="height: 40px">
            <button
              mat-button
              class="user-avatar-wrapper"
              style="padding-left: 2px; padding-right: 0px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <uni-user-avatar
                  [user]="user"
                  avatarClass="avatar-image"
                  [showPopover]="false"></uni-user-avatar>
                <span
                  class="font-sm"
                  style="font-weight: 400; padding-left: 8px; padding-right: 5px"
                  >{{ user.first_name | truncate : (xsmallView ? 5 : 10) }}</span
                >
              </div>
            </button>
          </a>
          <ng-container *ngIf="user">
            <!-- <a href="https://unifidchat.com" class="no-decor" target="_blank">
              <button mat-icon-button style="width: 30px; height: 30px; line-height: 12px">
                <img
                  src="https://uni-common-assets.s3.us-east-1.amazonaws.com/home/ai-btn.svg"
                  alt="ai-bot-icon"
                  [height]="24" />
              </button>
            </a> -->
            <!-- <mat-menu cdkDrag #menu1="matMenu" class="chat-container">
              <uni-ai-chat-bot [user]="user"></uni-ai-chat-bot>
            </mat-menu> -->
          </ng-container>
          <ng-container *ngIf="user">
            <a href="https://uni.xyz/notifications" class="no-decor-link header-notification-badge">
              <button
                mat-icon-button
                style="width: 30px; height: 30px; line-height: 12px"
                matBadge="."
                [matBadgeHidden]="unreadNotificationCount?.value === 0">
                <i class="icon-bell-fill font-lg sparro-grey-800"></i>
              </button>
            </a>
          </ng-container>

          <!-- drop-down-menu -->
          <div class="header-notification-badge">
            <button
              mat-icon-button
              [matMenuTriggerFor]="dropDownMenu"
              #triggered="matMenuTrigger"
              #menuTrigger="matMenuTrigger"
              style="width: 30px; height: 30px; line-height: 12px">
              <i *ngIf="triggered.menuOpen" class="icon-arrow-up-fill font-lg sparro-grey-800"></i>
              <i
                *ngIf="!triggered.menuOpen"
                class="icon-arrow-down-fill font-lg sparro-grey-800"></i>
            </button>
            <mat-menu #dropDownMenu="matMenu" class="header-menu">
              <div mat-list-item fxLayout="column" class="content">
                <a [href]="goToProfile()" class="right-sidenav-inner-content no-decor-link">
                  <uni-user-avatar
                    [user]="user"
                    avatarClass="user-inner-avatar"
                    [showPopover]="false"></uni-user-avatar>
                  <p class="font-sm">{{ user.username }}</p>
                </a>
                <a
                  [href]="goToUserProfileTab('competitions')"
                  class="right-sidenav-inner-content no-decor-link">
                  <i class="icon-competition font-lg"></i>
                  <p class="font-sm">My competitions</p>
                </a>
                <a
                  [href]="goToUserProfileTab('projects')"
                  class="right-sidenav-inner-content no-decor-link">
                  <i class="icon-project font-lg"></i>
                  <p class="font-sm">My projects</p>
                </a>
                <a
                  [href]="goToUserProfileTab('journals')"
                  class="right-sidenav-inner-content no-decor-link">
                  <i class="icon-journal font-lg"></i>
                  <p class="font-sm">My journals</p>
                </a>
                <a
                  [href]="goToUserProfileTab('publications')"
                  class="right-sidenav-inner-content no-decor-link">
                  <i class="icon-publication font-lg"></i>
                  <p class="font-sm">My publications</p>
                </a>
                <a [href]="goToBookmarks()" class="right-sidenav-inner-content no-decor-link">
                  <uni-image
                    [src]="bookmarkSvg"
                    alt="icon-bookmark"
                    classes="fit-cover"
                    [width]="24"
                    [height]="24"></uni-image>
                  <p class="font-sm">My bookmarks</p>
                </a>
                <a [href]="goToSettings()" class="right-sidenav-inner-content no-decor-link">
                  <i class="icon-settings font-lg"></i>
                  <p class="font-sm">Settings</p>
                </a>
                <a [href]="goToCart()" class="right-sidenav-inner-content no-decor-link">
                  <i class="icon-cart font-lg"></i>
                  <p class="font-sm">Cart</p>
                </a>
                <ng-container *ngIf="isAdmin">
                  <div (click)="goToImageUploader()" class="right-sidenav-inner-content">
                    <i class="icon-upload font-md"></i>
                    <p class="font-sm">Upload Image</p>
                  </div>
                </ng-container>
              </div>

              <div class="margin-x-4">
                <a [href]="goToOrganizations()" class="right-sidenav-inner-content no-decor-link">
                  <i class="icon-organization font-md"></i>
                  <p class="font-sm">Manage my organizations</p>
                </a>
              </div>

              <mat-divider style="margin: 12px 0 5px !important"></mat-divider>

              <div class="margin-x-4">
                <div (click)="logout()" class="right-sidenav-inner-content">
                  <i class="icon-logout font-md logout"></i>
                  <p class="font-sm logout">Logout</p>
                </div>
              </div>

              <mat-divider style="margin: 5px 0 12px !important"></mat-divider>

              <div
                class="footer-wrapper width-100"
                fxLayout="column"
                fxLayoutAlign="start center"
                fxLayoutGap="16px">
                <div
                  fxLayout="row"
                  fxFlex="100"
                  fxLayoutAlign="space-between center"
                  class="width-100">
                  <a href="https://uni.xyz/" target="_blank" class="no-decor-link">About UNI</a>
                  <span class="font-md font-bold">.</span>
                  <a
                    href="https://uni.xyz/policy/general-terms/terms-of-service"
                    target="_blank"
                    class="no-decor-link"
                    >Terms of Service</a
                  >
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="width-100">
                  <p class="font-bold sparro-grey-800 margin-0" style="margin: 0">
                    <i class="icon-copyright-bold"></i> Uniegis Network Pvt. Ltd.
                  </p>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-toolbar>
</ng-template>

<ng-template #noUser>
  <button mat-button (click)="getLoginUrl()" class="login-wrapper">
    <i class="sparro-grey-800 icon-login-bold"></i>
    Login/ Signup
  </button>
</ng-template>
