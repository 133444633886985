import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private baseUrl = `api/v2/`;
  static instance: ReportService;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
    ReportService.instance = this;
  }

  getReportArchTypes() {
    return this.http.get(this.baseUrl + 'arch_types/');
  }

  createReport(source: string, slug: string, report) {
    return this.http.post(this.baseUrl + source + '/' + slug + '/reports/', report);
  }

  redactReport(source: string, slug: string, reportId: number) {
    return this.http.put(this.baseUrl + source + '/' + slug + '/reports/' + reportId + '/redact/', {});
  }
}
