import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '@sparro/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/core/config/constants.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@about/env/environment';
import { HeaderService } from '@sparro/app/core/api/header/header.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '@sparro/core/api/auth/auth.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  page: number = 1;
  user: User;
  next: boolean = false;
  isLoading: boolean = true;
  notifications: any = [];
  showSpinner: boolean = false;
  showTitleHeader: boolean = false;
  mobileView: boolean = false;
  leftSideNav: boolean = false;
  serverUrl: string = environment.serverUrl;
  @ViewChild('notificationSection') notificationSection: ElementRef;
  @ViewChild('leftnav') leftnav: MatDrawer;
  @ViewChild('rightnav') rightnav: MatDrawer;
  @ViewChild('matdrawer') matdrawer: MatDrawerContainer;

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    private headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.headerService.setHeaderWidth('full');
    this.userService.fetchUser(user => (this.user = user));
    this.headerService.getLeftNav().subscribe(res => {
      this.leftSideNav = res;
    });
    this.instantiateBreakpoints();
    this.getNotifications();
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:resize', ['$event'])
  onWindowScroll(): void {
    const currentPos = document.documentElement.scrollTop || document.body.scrollTop;
    const maxPos = document.documentElement.scrollHeight;
    if (currentPos >= maxPos / 2 && this.next && !this.showSpinner) {
      this.showSpinner = true;
      if (this.next) {
        this.loadMore();
      }
    }

    this.showTitleHeader = Boolean(this.notificationSection.nativeElement.offsetTop <= currentPos);
    if (this.showTitleHeader && this.notifications.length) {
      this.headerService.setHeaderTitle('Notifications');
    } else {
      this.headerService.setHeaderTitle('');
    }
  }

  getNotifications() {
    this.isLoading = true;
    this.headerService.getUserNotifications(this.page).subscribe(
      (response: any) => {
        if (response) {
          this.notifications = [...this.notifications, ...response.results];
          this.isLoading = false;
          this.showSpinner = false;
          if (response.next) {
            this.next = true;
            const num = response.next.match(/page=(\d+)/)[1];
            this.page = parseInt(num);
          } else {
            this.next = false;
          }
        }
      },
      error => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  markAllRead() {
    this.headerService.markAllNotificationsRead().subscribe((response: any) => {
      if (response?.success) {
        this.setUnreadCount();
        this.notifications.filter(obj => {
          obj.read = true;
        });
      }
    });
  }

  markRead(id: number) {
    this.headerService.markNotificationRead(id).subscribe((response: any) => {
      if (response) {
        this.setUnreadCount();
        this.notifications.forEach(element => {
          if (element.id === id) {
            element.read = response.read;
          }
        });
      }
    });
  }

  loadMore() {
    this.getNotifications();
  }

  setUnreadCount() {
    this.headerService.getUreadNotificationCount().subscribe((response: any) => {
      if (response) {
        this.headerService.setNotificationCount(response.count);
      }
    });
  }

  instantiateBreakpoints() {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.mobileView = result.breakpoints[BREAKPOINTS.below.md];
    });
  }

  closeBackdrop(): void {
    this.leftSideNav = false;
    this.leftnav.toggle();
  }

  leftToggle(event): void {
    this.leftSideNav = true;
  }

  rightToggle(event): void {
    this.matdrawer.hasBackdrop = true;
    this.rightnav.toggle();
  }

  logout(toLogout): void {
    this.authService.logout().subscribe(
      response => {
        this.cookieService.deleteAll('/', '.uni.xyz');
        this.cookieService.deleteAll('/', 'competition.uni.xyz');
        this.userService.unsetUser();
        window.location.href = `${environment.competitionUrl}`;
      },
      error => {
        console.log(error);
      }
    );
  }
}
