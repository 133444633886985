import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '@sparro/env/environment';
import { UserService } from '@sparro/core/api/user/user.service';
import { User } from '@sparro/app/core/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private loginUrl: string;
  private user: User;
  authenticated: boolean = false;
  constructor(
    private userService: UserService,
  ) {
    this.loginUrl = `${environment.loginUrl}`;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.userService.fetchUser((user) => {
      this.user = user;
    });
    if (this.user || this.userService.user?.getValue()) {
      return true;
    }
    window.location.href = `${this.loginUrl}/authentication?service=sign-in&continue=${window.location.href}`;
    return false;
  }

}
