import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[float-button-item]'
})
export class FloatButtonDirective implements OnInit {

  constructor(private el:ElementRef) { }

  ngOnInit() {
    this.el.nativeElement.style.display = 'flex';
    this.setJustifyContent('flex-start');
    this.setDirection('column');
  }

  private setJustifyContent(position: string){
    this.el.nativeElement.style.justifyContent = position;
  }

  private setDirection(direction: string) {
    this.el.nativeElement.style.flexDirection = direction;
  }
}
