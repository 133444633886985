import { CommentService } from '@sparro/app/core/api/comment/comment.service';
import { User } from '@sparro/app/core/models/user.model';

export class Comment {
  id: number;
  user: number;
  mentioned_users: Array<User>;
  comment: string;
  score: number;
  is_highlighted: boolean;
  edited: boolean;
  is_removed: boolean;
  flair: string;
  created_at: string;
  updated_at: string;
}

export class NeoComment {
  id: number;
  user: User;
  mentioned_users: Array<User>;
  comment: string;
  score: number;
  is_highlighted: boolean;
  edited: boolean;
  is_removed: boolean;
  flair: string;
  created_at: string;
  updated_at: string;
  parent: number;
  child_count: number;
  static api_viewset: string;
  // slug: string;
  // If we are able to set slug while fetching or we start sending it in serializer, we can optimize further

  'construct': Pick<typeof NeoComment, keyof typeof NeoComment>;
  // Used to fetch static variables from non-static functions

  static getComments(api_viewset: string, slug: string, page: number = 1, children = false, parent = 0) {
    if (children) {
      return CommentService.instance.getChildrenComments(api_viewset, slug, parent, page);
    } else {
      return CommentService.instance.getNeoComments(api_viewset, slug, page);
    }
  }

  static getUserComments(api_viewset: string, slug: string, page: number = 1) {
    return CommentService.instance.getUserNeoComments(api_viewset, slug, page);
  }

  static createComment(api_viewset: string, slug: string, comment) {
    return CommentService.instance.createNeoComment(api_viewset, slug, comment);
  }

  static updateComment(api_viewset: string, slug: string, comment_pk: number, comment) {
    return CommentService.instance.updateNeoComment(api_viewset, slug, comment_pk, comment);
  }

  static removeComment(api_viewset: string, slug: string, comment_pk: number) {
    return CommentService.instance.removeNeoComment(api_viewset, slug, comment_pk);
  }

  static highlightComment(api_viewset: string, slug: string, comment_pk: number) {
    return CommentService.instance.highlightNeoComment(api_viewset, slug, comment_pk);
  }

  static checkCommentVote(api_viewset: string, slug: string, comment_pk: number) {
    return CommentService.instance.checkNeoCommentVote(api_viewset, slug, comment_pk);
  }

  static upvoteComment(api_viewset: string, slug: string, comment_pk: number, user_pk) {
    return CommentService.instance.upvoteNeoComment(api_viewset, slug, comment_pk, user_pk);
  }

  static downvoteComment(api_viewset: string, slug: string, comment_pk: number, user_pk) {
    return CommentService.instance.downvoteNeoComment(api_viewset, slug, comment_pk, user_pk);
  }

  static removeCommentVote(api_viewset: string, slug: string, comment_pk: number, user_pk) {
    return CommentService.instance.removeNeoCommentVote(api_viewset, slug, comment_pk, user_pk);
  }
}

export class ProjectComment extends NeoComment {
  static api_viewset = 'neo_projects';
}

export class BlogComment extends NeoComment {
  static api_viewset = 'blogs';
}

export class PublicationComment extends NeoComment {
  static api_viewset = 'publications';
}

export class CompetitionComment extends NeoComment {
  static api_viewset = 'competitions';
}
