import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@sparro/api/auth/auth.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { User } from '@sparro/app/core/models/user.model';
import { BlogService } from '@sparro/core/api/blog/blog.service';
import { PublicationService } from '@sparro/core/api/publication/publication.service';
import { UserService } from '@sparro/core/api/user/user.service';
import { environment } from '@sparro/env/environment';
import { CookieService } from 'ngx-cookie-service';
import { ImageUploaderDialogComponent } from '../../dialogs/image-uploader-dialog/image-uploader-dialog.component';
import { UnifidContributionComponent } from '../../dialogs/unifid-contribution/unifid-contribution.component';
import Gleap from 'gleap';
import { SearchService } from '@sparro/app/core/api/search/search.service';
import { HeaderService } from '@sparro/app/core/api/header/header.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'uni-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentChecked {
  @Input() user: User;
  @Input() transparentHeader: boolean = false;
  @Input() search: boolean = false;
  @Input() onlyDesktopView: boolean = false;
  @Input() menuSpacing: boolean = false;
  isSearchRoute: boolean = false;

  @Output() searchText = new EventEmitter<any>();
  @Output() sidenav = new EventEmitter<void>();
  @Output() rightSidenav = new EventEmitter<any>();
  @Output() leftSidenav = new EventEmitter<any>();
  @Output() searchPage = new EventEmitter<void>();

  @ViewChild('ai', { static: false }) ai: ElementRef;

  searchfield: FormGroup;

  isNotification: boolean = false;
  username: string;
  activeMembership: boolean = false;
  currentUrl: string;
  isAdmin: boolean = false;
  bookmarkSvg: string =
    'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/addtocollection/default-48x8.svg';
  smallView: boolean = true;
  xsmallView: boolean = true;
  unreadNotificationCount: any;
  headerWidth: any;
  headerTitle: any;

  @ViewChild('menuTrigger1') menu1: MatMenuTrigger;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthService,
    private cookieService: CookieService,
    public blogService: BlogService,
    public publicationService: PublicationService,
    public breakpointObserver: BreakpointObserver,
    public userService: UserService,
    public dialog: MatDialog,
    private searchService: SearchService,
    private headerService: HeaderService
  ) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    this.headerService.menuOpen$.subscribe(menuName => {
      this.menu1.openMenu();
    });
  }

  ngOnInit(): void {
    this.getAuthUser();
    this.headerService.getHeaderWidth().subscribe(res => {
      this.headerWidth = res;
    });
    this.headerService.getHeaderTitle().subscribe(res => {
      this.headerTitle = res;
    });
    this.unreadNotificationCount = this.headerService.getNotificationCount();
    Gleap.initialize('HVPDPKIxZn6GHf2YzVDCu9gasA98TO9C');
    this.initializeBreakpoints();
    this.searchfield = new FormGroup({
      search: new FormControl('')
    });
    this.checkIfUserIsAdmin();
    this.getNotificationCount();
    //if (!window.location.href.includes(`${environment.serverUrl}about/contribute-to-unifid`))
    //  this.openUnifidCampaignDialog();
  }

  openUnifidCampaignDialog(): void {
    let width = window.innerWidth <= 500 ? `90vw` : `700px`;
    const dialogRef = this.dialog.open(UnifidContributionComponent, {
      width,
      data: {}
    });
  }

  getNotificationCount() {
    this.headerService.getUreadNotificationCount().subscribe(
      (response: any) => {
        if (response.count) {
          this.headerService.setNotificationCount(response.count);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  checkIfUserIsAdmin(): void {
    this.userService.isAdmin().subscribe(
      (response: any) => {
        this.isAdmin = response.Admin;
      },
      error => {
        console.log(error);
      }
    );
  }

  createGleapSession() {
    Gleap.identify(
      this.user.id.toString(),
      {
        name: `${this.user.first_name} ${this.user.last_name}`,
        email: `${this.user.email}`
      },
      this.user.gleap_hash
    );
  }

  getAuthUser(): void {
    this.userService.fetchUser(user => {
      if (user) {
        this.user = user;
        this.checkActiveMembership();
        this.createGleapSession();
        this.username = this.setUserDisplayName();
      }
    });
  }

  ngAfterContentChecked(): void {
    this.searchService.getActiveSearchHost().subscribe(res => {
      this.isSearchRoute = Boolean(this.currentUrl.includes('search')) || res != '';
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.md, BREAKPOINTS.below.sm])
      .subscribe(result => {
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
        this.xsmallView = Boolean(result.breakpoints[BREAKPOINTS.below.sm]);
      });
  }

  toggleRightSidenav(): void {
    this.rightSidenav.emit();
  }

  goToSearchPage(): void {
    this.searchPage.emit();
  }

  goToHelp(): string {
    return 'https://help.uni.xyz/';
  }

  getLoginUrl(): void {
    window.location.href = `${environment.loginUrl}authentication?service=sign-in&continue=${window.location.href}`;
  }

  onSubmit(): void {
    this.searchText.emit(this.searchfield.value.search);
  }

  toggleLeftSidenav(): void {
    this.headerService.setLeftNav(true);
  }

  setUserDisplayName(): string {
    const name = `${this.user.first_name} ${this.user.last_name}`;
    let username = '';
    if (name.length > 20) {
      if (name[20] !== ' ') {
        const nameList = name.slice(0, 20).split(' ');
        for (const i of nameList.slice(0, -1)) {
          username += `${i} `;
        }
        return username.trim() + '...';
      } else {
        return username.slice(0, 20) + '...';
      }
    }
    return username;
  }

  goToMembership(): string {
    return 'https://uni.xyz/about/designers/membership';
  }

  goToProfile(): string {
    return `${environment.profileUrl}edit/${this.user.username}`;
  }

  goToBookmarks(): string {
    return `${environment.profileUrl}bookmarks`;
  }

  goToOrganizations(): string {
    return `${environment.organizationUrl}`;
  }

  goToUserProfileTab(tabName: string): string {
    return `${environment.profileUrl}edit/${this.user.username}/${tabName}`;
  }

  goToCart(): string {
    return environment.paymentUrl;
  }

  goToSettings(): string {
    return `${environment.loginUrl}settings`;
  }

  goToImageUploader(): void {
    let width;
    window.innerWidth <= 500 ? (width = `90vw`) : (width = `700px`);
    const dialogRef = this.dialog.open(ImageUploaderDialogComponent, {
      width,
      height: '500px',
      data: {
        user: this.user,
        slug: this.user.username,
        source: 'users'
      }
    });
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
      }
    });
  }

  logout(): void {
    this.authService.logout().subscribe(
      response => {
        this.cookieService.deleteAll('/', '.uni.xyz');
        this.cookieService.deleteAll('/', 'competition.uni.xyz');
        this.userService.unsetUser();
        Gleap.clearIdentity();
        window.location.href = `${environment.aboutUrl}`;
      },
      error => {
        this.cookieService.deleteAll('/', '.uni.xyz');
        this.cookieService.deleteAll('/', 'competition.uni.xyz');
        this.userService.unsetUser();
        Gleap.clearIdentity();
        console.log(error);
      }
    );
  }

  goToUploadNewProject(): string {
    return `${environment.projectUploaderUrl}new-project/`;
  }

  getUrl(type: string): string {
    return type === 'journal'
      ? `${environment.postUrl}edit`
      : `${environment.publicationUrl}edit/new_publication`;
  }

  checkActiveMembership(): void {
    if (this.user?.hasActiveMembership) {
      this.activeMembership = this.user.hasActiveMembership;
    } else {
      this.userService.hasActiveMembership().subscribe(
        response => {
          if ('success' in response) {
            this.activeMembership = response.success;
            this.user['hasActiveMembership'] = this.activeMembership;
            this.userService.setUser(this.user);
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  getHomePageUrl(): string {
    return `${environment.serverUrl}`;
  }
}
