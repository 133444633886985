import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReportService } from '@sparro/app/core/api/report/report.service';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { Report, ReportArchType } from '@sparro/app/core/models/report.model';

import _ from 'lodash';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {
  source: string;
  slug: string;
  public validateUrlRegex = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
  reportArchTypes: ReportArchType[] = [];

  reportInfoControl: FormControl = new FormControl();
  urlControl: FormControl = new FormControl('', {
    validators: [Validators.required, Validators.pattern(this.validateUrlRegex)]
  });
  embedControl: FormControl = new FormControl();
  typeControl: FormControl = new FormControl();
  reporter: number;

  selectedType: number;
  selectedArchType: ReportArchType;
  isLoading: boolean = false;
  isUploading: boolean = false;
  readOnlyUrl: boolean = false;
  imageUrl: any = '';
  image: any;
  reportTypeIds: number[];
  sources: string[] = ['neo_projects', 'blogs', 'publications', 'users', 'competitions', 'discourses', 'organizations'];

  constructor(
    private reportService: ReportService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.slug = this.data.slug ? this.data.slug : null;
    this.reportTypeIds = this.data?.reportTypeIds ? this.data.reportTypeIds : [];
    this.source = this.data.source;

    if (!this.source || !this.sources.includes(this.source)) {
      throw new Error('A valid source is required');
    }
    this.reporter = this.data.user?.id;
    this.getReportArchTypes();

    this.urlControl.setValue(this.data.url ? this.data.url : window.location.href);
    this.readOnlyUrl = Boolean(this.urlControl.value);
  }

  getReportArchTypes(): void {
    this.reportService.getReportArchTypes().subscribe(
      (response: ReportArchType[]) => {
        this.reportArchTypes = _.orderBy(response, 'title', 'asc');
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
    });
  }

  getArchType(event) {
    this.selectedArchType = event.value;
    this.selectedType = null;
    this.toggleSelect();
  }

  setReportType(type): void {
    this.selectedType = type.id;
  }

  toggleSelect() {
    this.reportArchTypes = this.reportArchTypes.filter((archType, index) => {
      archType.active = Boolean(this.selectedArchType.title === archType.title);
      return archType;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  createReport(): void {
    const data = {
      reporter: this.reporter,
      report_type: this.selectedType,
      url: this.urlControl.value,
      additional_info: this.reportInfoControl.value ? this.reportInfoControl.value : '',
      image: this.imageUrl ? this.image.id : null
    };

    Report.createReport(this.source, this.slug, data).subscribe(
      (response: any) => {
        this.dialogRef.close(response);
      },
      (err) => {
        console.log(err);
      });
  }


  checkControlState() {
    if (this.selectedArchType) {
      const typeLength = this.selectedArchType.types.length;
      if ((typeLength && this.selectedType) || (!typeLength && this.reportInfoControl.value)) {
        return false;
      }
    }
    return true;
  }

  uploadFile(event) {
    const files = event.target.files;
    this.isUploading = true;
    for (const item of files) {
      if (item.size > 15000000) {

      } else {
        this.startFileUpload(item);
        const reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        };
      }
    }
  }

  startFileUpload(file: any) {
    const formData = new FormData();
    formData.append('title', '');
    formData.append('description', '');
    formData.append('file', file, file.name);
    formData.append('type', file.type);
    this.uploadQuery(formData);
  }

  uploadQuery(formData) {
    this.userService.uploadUserMedia(formData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {

        } else if (event.type === HttpEventType.Response) {
          this.imageUrl = event.body.url;
          this.image = event.body;
          this.isUploading = false;
        }
      }, (err) => {
        console.log(err);
        this.isUploading = false;
      });
  }

}
