import { Injectable } from '@angular/core';

@Injectable()
export class UniApiConfig {
  readonly blogUrl = 'blogs';
  readonly competitionUrl = 'competitions';
  readonly projectUrl = 'neo_projects';
  readonly publicationUrl = 'publications';
  readonly discussionUrl = 'discourses';
  readonly profileUrl = 'users';
  readonly copyLinkUrls = {
    blogUrl: 'journal',
    competitionUrl: 'competitions',
    projectUrl: 'projects',
    publicationUrl: 'publications',
    discussionUrl: 'discourses',
  };
}
