import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThumbnailUploaderComponent } from './thumbnail-uploader/thumbnail-uploader.component';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { ImagesModule } from '../images/images.module';



@NgModule({
  declarations: [ThumbnailUploaderComponent],
  imports: [
    CommonModule,
    PipesModule,
    ImagesModule,
  ],
  exports: [
    ThumbnailUploaderComponent
  ]
})
export class UploaderModule { }
