import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'uni-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() count: number = 1;
  @Input() alignCenter: boolean = false; // an input for adding custom class
  @Input() animation: 'progress' | 'pulse' | 'none' = 'progress';
  @Input() appearance: 'project' | 'project-feed' | 'evaluation' | 'competition' | 'blog-featured'
    | 'competition-with-grids' | 'project-with-user' | 'discussion' | 'blog'
    | 'eval-blog' | 'eval-publication' | 'publication' | 'organization' = 'project';

  items: Array<any> = [];

  constructor() { }

  ngOnInit() {
    this.items.length = this.count;

    const allowedAnimations = ['progress', 'pulse', 'none'];
    if (!allowedAnimations.includes(this.animation)) {
      throw new Error('Invalid animation');
    }

    const allowedAppearances = ['project', 'project-feed', 'evaluation', 'competition', 'publication',
      'competition-with-grids', 'project-with-user', 'blog', 'discussion', 'eval-blog',
      'eval-publication', 'organization'];
    if (!allowedAppearances.includes(this.appearance)) {
      throw new Error('Invalid appearance');
    }
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }
}
