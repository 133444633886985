<div [ngClass]="{'card-grid': alignCenter }" *ngIf="appearance === 'project' || appearance === 'evaluation'">
  <mat-card class="project-skeleton-card" *ngFor="let item of items">
    <div class="rounded-border">
      <div class="project-cover-image" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
      <mat-card-content class="project-card-content">
        <span class="project-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
        <span class="content" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse', score: appearance === 'evaluation'}"></span>
      </mat-card-content>
    </div>
  </mat-card>
</div>

<div [ngClass]="{'card-grid': alignCenter }" *ngIf="appearance === 'project-with-user'">
  <mat-card class="project-skeleton-card" *ngFor="let item of items">
    <div class="project-card-header margin-bottom-4">
      <div class="project-avatar" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
      <div class="project-avatar-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
    </div>
    <div class="rounded-border">
      <div class="project-cover-image" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
      <mat-card-content class="project-card-content">
        <span class="project-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
        <span class="content" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      </mat-card-content>
    </div>
  </mat-card>
</div>

<ng-container *ngIf="appearance === 'project-feed'">
  <mat-card class="project-skeleton-card" style="border:0;" *ngFor="let item of items">
    <div class="project-cover-image rounded" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
    <mat-card-content class="project-card-content" style="margin-top:4px;">
      <span class="project-title" style="height:16px;" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <div class="col-user">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div fxLayout="column" class="project-owner" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}">
            </div>
            <div fxLayout="column" class="project-owner" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}">
            </div>
            <div fxLayout="column" class="project-owner" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}">
            </div>
          </div>
        </div>
        <div class="col-disciplines">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div fxLayout="column" class="project-discipline" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}">
            </div>
            <div fxLayout="column" class="project-discipline" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}">
            </div>
            <div fxLayout="column" class="project-discipline" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}">
            </div>
          </div>
        </div>
      </div>
      <!-- <span class="content" style="height:32px;" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span> -->
      <!-- <span class="content" style="width:200px;height:14px;" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span> -->
    </mat-card-content>
  </mat-card>
</ng-container>

<div [ngClass]="{'card-grid': alignCenter }" *ngIf="appearance === 'competition' || appearance === 'competition-with-grids'">
  <mat-card class="competition-skeleton-card rounded-border" *ngFor="let item of items">
    <div class="competition-cover-image" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
    <mat-card-content class="competition-card-content">
      <span class="competition-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <span class="competition-subtitle-one" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <span class="competition-subtitle-two" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <div class="competition-footer" *ngIf="appearance === 'competition'" >
        <div class="footer-section" fxLayout="row" fxLayoutGap="12px">
          <span class="footer-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
          <span class="footer-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
        </div>
        <!-- <mat-divider [vertical]="true"></mat-divider> -->
        <div class="footer-section" fxLayout="row" fxLayoutGap="12px">
          <span class="footer-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
          <span class="footer-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
        </div>
      </div>
      <div class="competition-footer-three-grid" *ngIf="appearance === 'competition-with-grids'" fxLayout="row" fxLayoutAlign="space-around start">
        <div class="footer-section" fxLayout="row" fxLayoutGap="12px">
          <span class="footer-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
          <span class="footer-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
        </div>
        <!-- <mat-divider [vertical]="true"></mat-divider> -->
        <div class="footer-section" fxLayout="row" fxLayoutGap="12px" 
          style="border: 1px solid #eee; padding-left: 12px; padding-right: 12px; border-top: 0; border-bottom: 0;">
          <span class="footer-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
          <span class="footer-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
        </div>
        <!-- <mat-divider [vertical]="true"></mat-divider> -->
        <div class="footer-section" fxLayout="row" fxLayoutGap="12px">
          <span class="footer-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
          <span class="footer-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="appearance === 'discussion'">
  <mat-card class="discussion-skeleton-card" *ngFor="let item of items">
    <mat-card-content class="discussion-card-content">
      <span class="discussion-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <span [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse', content: appearance === 'discussion'}"></span>
    </mat-card-content>
    <div class="discussion-cover-image rounded-border" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
  </mat-card>
</div>


<ng-container *ngIf="appearance === 'blog' || appearance === 'eval-blog'">
    <div fxLayout="column wrap" class="margin-x-auto" style="max-width: 680px;">
      <mat-card class="blog-skeleton-card relative" *ngFor="let item of items">
        <div class="blog-card-header margin-bottom-4">
          <div class="blog-avatar" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
          <div class="blog-avatar-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div class="blog-image rounded-border hover-shadow-4" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>

          <div class="blog-content relative">
            <span class="blog-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
            <span class="blog-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
            <span class="blog-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>

            <div class="disciplines-section absolute bottom-0" *ngIf="appearance !== 'eval-blog'">
              <span [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}" class="blog-discipline"></span>
              <span [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}" class="blog-discipline"></span>
            </div>

            <span class="score" *ngIf="appearance === 'eval-blog'" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
          </div>
        </div>
      </mat-card>
    </div>
</ng-container>
<ng-container *ngIf="appearance === 'blog-featured'">
    <div class="showSection">
      <mat-card class="blog-skeleton-feturedCard relative" *ngFor="let n of numSequence(4)">
        <div class="blog-featuredCard-header margin-bottom-4">
          <div class="blog-featuredCard-avatar" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
          <div class="blog-featuredCard-avatar-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="blog-featuredCard-image rounded-border hover-shadow-4" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
  
          <div class="blog-featuredCard-content relative">
            <span class="blog-featuredCard-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
            <span class="blog-featuredCard-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
            <span class="blog-featuredCard-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
  
            <div class="disciplines-featuredCard-section">
              <span class="blog-featuredCard-discipline" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}" ></span>
              <span class="blog-featuredCard-discipline" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}" ></span>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
</ng-container>

<div class="card-grid" *ngIf="appearance === 'organization'">
  <div class="organization-skeleton-card rounded-border" *ngFor="let item of items">
    <div class="organization-cover-image" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
    <div class="organization-icon" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>

    <div class="organization-content" fxLayout="column" fxLayoutAlign="center center">
      <span class="organization-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <span class="organization-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <span class="organization-specs" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
      <span class="organization-specs" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
    </div>
  </div>
</div>

<ng-container *ngIf="appearance === 'publication' || appearance === 'eval-publication'">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <mat-card class="publication-skeleton-card relative" *ngFor="let item of items">
        <div class="publication-card-header margin-bottom-4">
          <div class="publication-avatar" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
          <div class="publication-avatar-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div class="publication-image rounded-border hover-shadow-4" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></div>

          <div class="publication-content relative">
            <span class="publication-title" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
            <span class="publication-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
            <span class="publication-subtitle" [ngClass]="{ progress: animation === 'progress', pulse: animation === 'pulse'}"></span>
          </div>
        </div>
      </mat-card>
    </div>
</ng-container>
