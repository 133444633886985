import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';

import { ContextMenuComponent } from '../menu/context-menu/context-menu.component';

@NgModule({
    declarations: [
    ContextMenuComponent
    ],
    entryComponents: [
      
    ],
    imports: [
      CommonModule,
      FlexLayoutModule,
      MaterialModule,
      DirectiveModule
    ],
    exports: [
    ContextMenuComponent
    ]
  })
  export class MenuModule { }