// import { TypeofExpr } from '@angular/compiler';

export const UNI_CONSTANTS = {
  NO_IMAGE: {
    DEFAULT: 'https://uni-common-assets.s3.amazonaws.com/images/noimage/noimage_s.jpg',
    DEFAULT_LARGE: 'https://uni-common-assets.s3.amazonaws.com/images/noimage/noimage_l.jpg',
    JOURNAL: 'https://uni-common-assets.s3.amazonaws.com/images/noimage/noimage_journal.png',
    PUBLICATION: 'https://uni-common-assets.s3.amazonaws.com/images/noimage/noimage_publication.png',
    COMP_OR_PROJECT: 'https://uni-common-assets.s3.amazonaws.com/images/noimage/noimage_competitionproject.png'
  },
  HOSTS: {
    PROJECT: 'project',
    EVALUATION: 'evaluation',
    PROFILE: 'profile',
    ARENA: 'competition',
    ORGANIZATION: 'organization',
    BLOG: 'blog',
    BUILDER: 'builder',
    ENTRY: 'entry',
    EVALUATION_DASHBOARD: 'evaluation_dashboard',
    PUBLICATION: 'publication'
  },
  STRINGS: {
    UNTITLED_PROJECT: 'Untitled Project',
    UNTITLED_PUBLICATION: 'Untitled Publication',
    UNTITLED_BLOG: 'Untitled Draft',
  },
  PERMISSIONS: {
    PROJECT: {
      KEY: 'project_permission',
      OWNER: 'owner',
    }
  }
};

export const BREAKPOINTS = {
  below: {
    xs: '(max-width: 360px)',
    sm: '(max-width: 540px)',
    md: '(max-width: 960px)',
    lg: '(max-width: 1280px)',
    xl: '(max-width: 1920px)',
    xxl: '(max-width: 2560px)',
    xxxl: '(max-width: 3840px)',
  },
  above: {
    xs: '(min-width: 360px)',
    sm: '(min-width: 540px)',
    md: '(min-width: 960px)',
    lg: '(min-width: 1280px)',
    xl: '(min-width: 1920px)',
    xxl: '(min-width: 2560px)',
    xxxl: '(min-width: 3840px)',
  },
  between: {
    xs_sm: '(max-width: 540px) and (min-width: 361px)',
    sm_md: '(max-width: 960px) and (min-width: 541px)',
    md_lg: '(max-width: 1280px) and (min-width: 961px',
    lg_xl: '(max-width: 1920px) and (min-width: 1281px)',
    xl_xxl: '(max-width: 2560px) and (min-width: 1921px)',
    xxl_xxxl: '(max-width: 3840px) and (min-width: 2561px)',
  },
  xs: '360px',
  sm: '540px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
  xxl: '2560px',
  xxxl: '3840px',
};

const HOST_LIST = Object.values(UNI_CONSTANTS.HOSTS);

export type ALLOWED_HOSTS = typeof HOST_LIST[number];

export const GUIDE_FOOTER_IMAGES = {
  BLOG: [
    'https://uni-common-assets.s3.amazonaws.com/journal/guide/1.webp',
    'https://uni-common-assets.s3.amazonaws.com/journal/guide/2.webp',
    'https://uni-common-assets.s3.amazonaws.com/journal/guide/3.webp',
    'https://uni-common-assets.s3.amazonaws.com/journal/guide/4.webp',
    'https://uni-common-assets.s3.amazonaws.com/journal/guide/5.webp',
    'https://uni-common-assets.s3.amazonaws.com/journal/guide/6.webp',
    'https://uni-common-assets.s3.amazonaws.com/journal/guide/7.webp'
  ],
  PUBLICATION: [
    'https://uni-common-assets.s3.amazonaws.com/publication/guide/1.webp',
    'https://uni-common-assets.s3.amazonaws.com/publication/guide/2.webp',
    'https://uni-common-assets.s3.amazonaws.com/publication/guide/3.webp',
    'https://uni-common-assets.s3.amazonaws.com/publication/guide/4.webp',
    'https://uni-common-assets.s3.amazonaws.com/publication/guide/5.webp',
  ]
};

export const SOCIAL_SHARE_ICONS = [
  {
    link: 'https://uni-common-assets.s3.amazonaws.com/icons/social/facebook/color_48x48.svg',
    name: 'facebook'
  },
  {
    link: 'https://uni-common-assets.s3.amazonaws.com/icons/social/linkedin/color_48x48.svg',
    name: 'linkedin'
  },
  {
    link: 'https://uni-common-assets.s3.amazonaws.com/icons/social/twitter/color_48x48.svg',
    name: 'twitter'
  }
];
