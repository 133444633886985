import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';

@Injectable({
  providedIn: 'root'
})
export class FollowerService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  follow(source: string, slug: string, data) {
    return this.http.post(this.baseUrl + source + '/' + slug + '/followers/', data);
  }

  unfollow(source: string, slug: string, follower_id: number) {
    return this.http.delete(this.baseUrl + source + '/' + slug + '/followers/' + follower_id + '/');
  }
}
