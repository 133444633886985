import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { AbstractButtonDirective } from './abstract_button_directive';

@Directive({
  selector: '[uni-button-class]'
})
export class ButtonClassDirective extends AbstractButtonDirective {
  @Input('uni-button-class') class: string = 'primary';
  @Input('uni-button-type') type: string = 'state';
  @Input('uni-icon-button') icon_only: boolean = false;
  private _class_prefix: string = 'uni-' + this.type + '-button';
  // class_name: string = "uni-button-primary-inactive";

  constructor(
    _renderer: Renderer2,
    element: ElementRef
  ) {
    super(_renderer, element);
  }

  ngOnInit() {
    // console.log(this.icon_only);
    this._class_prefix = 'uni-' + this.type + '-button';
    this.addClass();
  }

  stateHandler() {
    if (this.activeState) {
      this.updateCSS('active');
    } else {
      this.updateCSS('inactive');
    }
  }

  loadingHandler() {
    if (this.loadingState) {
      this.updateCSS('loading');
    } else {
      this.stateHandler();
    }
  }

  updateCSS(status: string = '') {
    let new_class_name = this._class_prefix + '-' + this.class;
    new_class_name += (status == '') ? '' : ('-' + status);
    this.removeClass();
    this.class_name = new_class_name;
    this.addClass();
  }

}
