<div class="container" #container>
  <ng-container *ngIf="uniFooter">
    <div [ngClass]="{'footer-xlarge': xlarge, 'footer-large': large, 'footer-medium': medium, 'footer-small': small, 'top-margin': topMargin}"
      fxLayout="column">
      <div class="footer-body" [fxLayout]="xlarge ? 'row' : 'column'" fxLayoutAlign="space-between start"
        [fxLayoutGap]="xlarge? '120px' : '48px'">
        <div fxLayout="column" class="footer-title-container" fxLayoutAlign="space-between start" fxLayoutGap="124px">
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="40px">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
              <uni-image 
                [src]="uniFooter.footer_tagline_icon" 
                alt="UNI"
                [width]="medium || small ? 48 : 63.5"
                [height]="medium || small ? 48 : 63.5"
                classes="rounded-border fit-cover">
              </uni-image>
              <ng-container *ngIf="uniFooter.footer_tagline">
                <div [fxFlex]="large? '70' : '100' " class="footer-tagline sparro-grey-800 font-bold">
                  {{uniFooter.footer_tagline | truncate: 60}}
                </div>
              </ng-container>
            </div>
            <div class="social-list" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px grid">
              <ng-container *ngFor="let platform of uniFooter.uni_social_link">
                <a [href]="platform.link">
                  <uni-image [src]="platform.icon" alt="social media link" [width]="32" [height]="32" classes="fit-cover"></uni-image>
                </a>
              </ng-container>
            </div>
          </div>
          <div class="width-100" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
            <div class="email-title sparro-grey-800 font-bold">Subscribe To our newsletter</div>
            <div class="subscribe-section" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
              <mat-form-field class="width-100 email" appearance="outline">
                <mat-label>Enter your email address</mat-label>
                <input matInput placeholder="Enter your email address" [formControl]="emailControl">
                <mat-hint [ngStyle]="{'color': 'green'}">{{subscriptionStatus}}</mat-hint>
                <mat-error class="font-xs" *ngIf="emailControl?.errors">
                  {{getErrorMessage()}}
                </mat-error>
              </mat-form-field>
              <button class="subscribe-button" (click)="subscribeNewsLetter()">Subscribe</button>
            </div>
          </div>
        </div>
        <div class="footer-content-list width-100" fxLayout="row wrap">
          <ng-container *ngFor="let footerSection of uniFooter.footer_section">
            <div class="footer-list-item" [fxFlex]="footerCurrentWidth<largeFooter ? '50' : '25'" fxLayout="column"
              fxLayoutAlign="start start" fxLayoutGap="20px">
              <div class="section-title sparro-grey-800">{{footerSection.title| truncate: 22}}</div>
              <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                <ng-container *ngFor="let footerLink of footerSection.footer_link">
                  <a class="footer-link sparro-grey-800" [href]="footerLink.link"
                  target="_blank">{{footerLink.title | truncate: 22}}</a>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <mat-divider class="footer-divider"></mat-divider>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div class="width-100" fxLayout.lt-md="column-reverse" fxLayout="row" fxLayoutAlign="start center"
          fxLayoutGap="20px">
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <uni-image
              src="https://uni-common-assets.s3.amazonaws.com/logo/uni/uni_rectangle_160x72.svg"
              [width]="medium || small ? 100 : 120"
              [height]="medium || small ? 60 : 72"
              alt="uni logo">
            </uni-image>
            <div>
              <a class="footer-link-copyright sparro-grey-500"><i class="icon-copyright-bold"></i> UNIXYZ Inc.
                {{currentYear}}</a>
            </div>
          </div>
          <ng-container *ngIf="footNote">
            <div class="footNote" fxFlex="50" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
              fxLayoutGap="20px">
              <ng-container *ngIf="footNote?.image_url">
                <uni-image [src]="footNote.image_url" alt="foot note icon" [height]="48" [width]="48" classes="fit-cover"></uni-image>
              </ng-container>
              <div class="sparro-grey-800 font-bold footNote-title">
                {{footNote.title}}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="seoContent?.content">
    <div class="accordion-section">
      <mat-accordion>
        <mat-expansion-panel [expanded]="small ? false : true">
          <mat-expansion-panel-header>
            <div [innerHtml]="seoContent?.title | safeContent"></div>
          </mat-expansion-panel-header>
          <div class="content" [innerHtml]="seoContent?.content | safeContent"></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
</div>