<ng-container *ngIf="comment && !comment.is_removed">
  <div fxLayout="row" style="width: 100%; margin: 16px 0" *ngIf="comment && !edit_mode">
    <div fxLayout="column" fxFlex="56px" class="col-user">
      <uni-comment-user [user]="comment.user" [avatar_mode]="true"> </uni-comment-user>
    </div>
    <div fxLayout="column" fxFlex="90">
      <div fxLayout="row" class="margin-0" style="margin-bottom: 8px">
        <div fxLayout="column" fxFlex="50">
          <uni-comment-user [user]="comment.user" [header_mode]="true" [flair]="comment.flair">
          </uni-comment-user>
        </div>
        <div fxLayout="column" fxLayoutAlign="center end" fxFlex="50">
          <div fxLayout="row" fxFlex class="width-100">
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="center end">
              <p class="sparro-grey-700 font-xxs margin-0" style="margin: 5px 0">
                <i>
                  <span *ngIf="comment.edited"> (edited) </span>
                  {{ comment.updated_at | timesince }} ago</i
                >
              </p>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              fxFlex="20"
              *ngIf="can_edit || owner_flag">
              <button mat-icon-button [matMenuTriggerFor]="menu" style="height: 30px; width: 30px">
                <mat-icon style="vertical-align: baseline">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="can_edit" (click)="editComment()">
                  <span>Edit</span>
                </button>
                <button mat-menu-item *ngIf="can_edit" (click)="removeComment(comment)">
                  <span>Delete</span>
                </button>
                <button mat-menu-item *ngIf="owner_flag" (click)="highlightComment(comment)">
                  <span *ngIf="!comment.is_highlighted">Highlight</span>
                  <span *ngIf="comment.is_highlighted">Remove highlight</span>
                </button>
                <!-- <button mat-menu-item (click)="report(comment.user)">
                    <span>Report</span>
                  </button> -->
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <p
          class="font-sm text-left margin-0 padding-bottom-3 comment-text"
          [ngClass]="{
            highlight: comment.is_highlighted == true,
            'margin-top-0': can_edit || owner_flag
          }">
          <span
            *ngFor="let user of comment.mentioned_users"
            style="padding-right: 4px; color: #f15a24"
            >{{ user.first_name }} {{ user.last_name }}</span
          >
          <span *ngIf="comment.comment.length <= 100">{{ comment.comment }}</span>
          <span *ngIf="comment.comment.length > 100 && expanded"
            >{{ comment.comment }}
            <span style="cursor: pointer; font-weight: 700" (click)="toggle()"
              >Show less</span
            ></span
          >
          <span *ngIf="comment.comment.length > 100 && !expanded"
            >{{ comment.comment | truncate : 100 }}
            <span style="cursor: pointer; font-weight: 700" (click)="toggle()"
              >Read more</span
            ></span
          >
        </p>
      </div>
      <div fxLayout="row" class="margin-0" *ngIf="showFooter">
        <uni-comment-footer
          [comment]="comment"
          [source]="source"
          [user]="user"
          [slug]="slug"
          (mention)="userMentioned($event)"
          [nested]="nested"
          (nestedReply)="nestedReplyEvent($event)">
        </uni-comment-footer>
      </div>
    </div>
  </div>

  <div fxLayout="row" style="width: 100%; margin: 16px 0; padding: 16px 0" *ngIf="edit_mode">
    <uni-comment-edit
      style="width: 100%"
      [user]="user"
      [slug]="slug"
      [new]="false"
      [comment_obj]="comment"
      [source]="source"
      (comment_response)="commentEditted($event)"
      [nested]="nested">
    </uni-comment-edit>
  </div>
</ng-container>

<div *ngIf="comment.is_removed" style="width: 100%; margin: 16px 0">
  <div fxLayout="column" fxFlex="8.65" class="col-user">
    <uni-comment-user [is_removed]="comment.is_removed"> </uni-comment-user>
  </div>
  <div fxLayout="column" fxFlex="90">
    <div
      class="font-xs"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 40px; background: #f4f4f4; border-radius: 12px">
      <i>{{ comment.comment }}</i>
    </div>
  </div>
</div>

<div
  style="border-left: 1px dotted blue; margin-left: 20px; padding-left: 35px"
  *ngIf="nested && nested_reply_event">
  <uni-comment-group
    [slug]="slug"
    [source]="source"
    [user]="user"
    [nested]="nested"
    [max_nest_depth]="max_nest_depth"
    [current_nest_depth]="current_nest_depth + 1"
    [parent]="comment.id"
    [child_group]="true">
  </uni-comment-group>
</div>
