import { Injectable } from '@angular/core';

@Injectable()
export class UniConnectionConfig {
  readonly froalaV3Key = 'QFF4nB15B9A5C6D5B3eouuB5ffzA-9H3E2J2C5C6C3E2B5F2H2==';
  readonly froalaV28Key = 'QG4F4H3B17C10D9jC5D4C3F3J2A7C7C4A4E3A-9wmmC8C-16C-16r==';
  readonly froalaLegacyKey = 'QeC-7F-7A2lll==';
  readonly razorPayKey = '';
  readonly razorPayTestKey = '';
  readonly googleMapKey = '';
}
