import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {
    name: 'dflip',
    src: `${environment.serverUrl}static/dflip/js/dflip.min.js`,
  },
  {
    name: 'jquery',
    src: `${environment.serverUrl}static/dflip/js/libs/jquery.min.js`,
  },
  {
    name: 'three',
    src: `${environment.serverUrl}static/dflip/js/libs/three.min.js`,
  },
  {
    name: 'dflipCss',
    src: `${environment.serverUrl}static/dflip/css/dflip.css`,
  },
  {
    name: 'themify',
    src: `${environment.serverUrl}static/dflip/css/themify-icons.css`,
  },
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]): Promise<any> {
    const promises: any[] = [];

    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        let script = null;
        if (this.scripts[name].src.endsWith('.js')) {
          script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = this.scripts[name].src;
        } else if (this.scripts[name].src.endsWith('.css')) {
          script = document.createElement('link');
          script.type = 'text/css';
          script.rel = 'stylesheet';
          script.href = this.scripts[name].src;
        }
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = (data) => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
  
  // CAN BE USED LATER
  // removeScripts(...scripts: string[]) {
  //   const promises: any[] = [];
  //   scripts.forEach((script) => promises.push(this.removeScript(script)));
  //   return Promise.all(promises);
  // }
  
  // removeScript(name: string) {
  //   return new Promise((resolve, reject) => {
  //     const scripts = document.getElementsByTagName('script');
  //     const links = document.getElementsByTagName('link');
  //     for (let i = 0; i < scripts.length; i++) {
  //       const src = scripts[i].getAttribute('src');
  //       if (src !== null && src.includes(name)) {
  //         scripts[i].parentNode.removeChild(scripts[i]);
  //       }
  //     }
  //     for (const iterator of links) {
  //       const rel = iterator.getAttribute('href');
  //       if (rel !== null && rel.includes(name)) {
  //         iterator.parentNode.removeChild(iterator);
  //       }
  //     }
  //     resolve({ loaded: true, status: 'removed' });
  //   });
  // }
}
