import { OnInit, Directive, ElementRef, HostListener, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[uni-button-title]'
})
export class ButtonTitleDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() default: string;
  @Input() active: string;
  @Input() activeHover: string;
  @Input() inactiveHover: string;
  @Input() loading: boolean;
  @Input() loadingText: string = '';
  @Output() title_changed = new EventEmitter<string>();
  activeState: boolean;
  loadingState: boolean = false;
  loader;

  constructor(
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // console.log(this.default)
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.loading.currentValue == true) {
      this.setTitle(this.loadingText !== '' ? this.loadingText : 'Loading');
      // this.showLoader();
    }
    else if((changes.loading.previousValue == true) && (changes.loading.currentValue == false)) {
      // this.hideLoader();
      this.setState(this.activeState);
    }
  }

  setState(state: boolean) {
    if(!this.loading) {
      this.activeState = state;
      if (this.activeState) {
        this.setTitle(this.active);
      } else {
        this.setTitle(this.default);
      }
    }
  }

  toggleLoading(isLoading: boolean) {
    this.loadingState = isLoading;
  }
  // QUESTION: Do we need breakpoints here?

  @HostListener('mouseenter') onMouseEnter() {
    if(!this.loading) {
      if (this.activeState && this.activeHover) {
        this.setTitle(this.activeHover);
      } else if (!this.activeState && this.inactiveHover) {
        this.setTitle(this.inactiveHover);
      }
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if(!this.loading) {
      if (this.activeState && this.active) {
        this.setTitle(this.active);
      } else {
        this.setTitle(this.default);
      }
    }
  }

  setTitle(title: string) {
    this.element.nativeElement.innerHTML = title;
    this.title_changed.emit(title);
  }

}
