import { Component, OnInit, Input } from '@angular/core';
import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@sparro/env/environment';

@Component({
  selector: 'uni-comment-user',
  templateUrl: './comment-user.component.html',
  styleUrls: ['./comment-user.component.scss']
})
export class CommentUserComponent implements OnInit {
  @Input() user: any;
  @Input() avatar_mode: boolean = false;
  @Input() header_mode: boolean = false;
  @Input() flair: String;
  @Input() is_removed = false;

  constructor() { }

  ngOnInit() {
  }

  getProfileUrl(user: User): string {
    return `${environment.profileUrl}${user.username}/`;
  }
}
