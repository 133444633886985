import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { BlogService } from '@sparro/api/blog/blog.service';
import { PublicationService } from '@sparro/api/publication/publication.service';
import { User } from '@sparro/app/core/models/user.model';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { ImageUploaderDialogComponent } from '../../dialogs/image-uploader-dialog/image-uploader-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Gleap from 'gleap';
import { AuthService } from '@sparro/app/core/api/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'uni-right-sidenav',
  templateUrl: './right-sidenav.component.html',
  styleUrls: ['./right-sidenav.component.scss']
})
export class RightSidenavComponent implements OnInit {
  @Input() user: User;
  @Output() toLogout = new EventEmitter<any>();
  isAdmin: boolean = false;
  bookmarkSvg: string = 'https://uni-common-assets.s3.amazonaws.com/icons/system/svg/1/addtocollection/default-48x8.svg';

  constructor(
    public blogService: BlogService,
    public publicationService: PublicationService,
    public userService: UserService,
    public dialog: MatDialog,
    private authService: AuthService,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    this.checkIfUserIsAdmin();
  }

  checkIfUserIsAdmin() {
    if(this.user)
      this.createGleapSession();
    this.userService.isAdmin().subscribe(
      (response: any) => {
        this.isAdmin = response.Admin;
      },
      (error) => {
        console.log(error);
      });
  }

  createGleapSession() {
    Gleap.identify(this.user.id.toString(), {
      name: `${this.user.first_name} ${this.user.last_name}`,
      email: `${this.user.email}`,
    }, this.user.gleap_hash);
  }

  goToUserProfileTab(tabName: string) {
    return `${environment.profileUrl}edit/${this.user.username}/${tabName}`;
  }

  goToUserBookmarks() {
    return `${environment.profileUrl}bookmarks`;
  }

  goToProfile() {
    return `${environment.profileUrl}`;
  }

  goToPayment() {
    return `${environment.paymentUrl}`;
  }

  goToSettings() {
    return `${environment.loginUrl}settings`;
  }

  // logout() {
  //   this.toLogout.emit();
  //   Gleap.clearIdentity();
  // }
  logout(): void {
    this.authService.logout().subscribe(
      (response) => {
        this.cookieService.deleteAll('/', '.uni.xyz');
        this.cookieService.deleteAll('/', 'competition.uni.xyz');
        this.userService.unsetUser();
        Gleap.clearIdentity();
        window.location.href = `${environment.aboutUrl}`;
      },
      (error) => {
        this.cookieService.deleteAll('/', '.uni.xyz');
        this.cookieService.deleteAll('/', 'competition.uni.xyz');
        this.userService.unsetUser();
        Gleap.clearIdentity();
        console.log(error);
      });
  }

  goToUploadNewProject(): string {
    return `${environment.projectUploaderUrl}new-project/`;
  }

  getUrl(type: string): string {
    return type === 'journal' ? `${environment.postUrl}edit` : `${environment.publicationUrl}edit/new_publication`;
  }

  goToImageUploader() {
    let width;
    window.innerWidth <= 500 ?  width = `90vw` : width = `700px`;
    const dialogRef = this.dialog.open(ImageUploaderDialogComponent, {
      width: width,
      height: '500px',
      data: {
        user: this.user,
        slug: this.user.username,
        source: 'users'
      }
    });
    dialogRef.afterClosed().subscribe(
      (response: any) => {
        if(response) {
        }
      });
  }
}
