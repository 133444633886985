import { Component, OnInit } from '@angular/core';

import { User } from '@sparro/app/core/models/user.model';

import { UserService } from '@sparro/app/core/api/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'about';
  user: User;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.fetchUser(user => (this.user = user));
  }
}
