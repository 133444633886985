import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  secondsInYear: number = 31536000;
  secondsInMonth: number = 2592000;
  secondsInDay: number = 86400;
  secondsInHour: number = 3600;
  secondsInMin: number = 60;
  millisecondsInSecond: number = 1000;

  public transform(value: any, args?: any[]): any {
    const method = args[0];
    let format: 'short' | 'long';

    let seconds: any;

    if (method == 'calc time left') {
      seconds = Math.abs(
        Math.floor((new Date(value).getTime() - new Date().getTime()) / this.millisecondsInSecond)
      );
      format = args[1];
    } else {
      const startDate: Date = new Date(value);
      const endDate: Date = new Date(args[1]);
      format = args[2];
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      seconds = Math.floor(timeDiff / this.millisecondsInSecond);
    }

    let interval = Math.floor(seconds / this.secondsInYear);

    if (interval > 1) {
      return `${interval} ${format == 'short' ? 'yr' : 'years'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }
    if (interval === 1) {
      return `${interval} ${format == 'short' ? 'yr' : 'year'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }

    interval = Math.floor(seconds / this.secondsInMonth);

    if (interval > 1) {
      return `${interval} ${format == 'short' ? 'mo' : 'months'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }
    if (interval === 1) {
      return `${interval} ${format == 'short' ? 'mo' : 'month'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }

    interval = Math.floor(seconds / this.secondsInDay);

    if (interval > 1) {
      return `${interval} ${format == 'short' ? 'dy' : 'days'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }
    if (interval === 1) {
      return `${interval} ${format == 'short' ? 'dy' : 'day'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }

    interval = Math.floor(seconds / this.secondsInHour);

    if (interval > 1) {
      return `${interval} ${format == 'short' ? 'hr' : 'hours'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }
    if (interval === 1) {
      return `${interval} ${format == 'short' ? 'hr' : 'hour'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }

    interval = Math.floor(seconds / this.secondsInMin);

    if (interval > 1) {
      return `${interval} ${format == 'short' ? 'min' : 'minutes'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }
    if (interval === 1) {
      return `${interval} ${format == 'short' ? 'min' : 'minute'}${
        method == 'calc time left' ? ' left' : ''
      }`;
    }

    return `${Math.floor(seconds)} ${
      format == 'short' ? 'sec' : seconds === 1 ? 'second' : 'seconds'
    }${method == 'calc time left' ? ' left' : ''}`;
  }
}
