import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
import { ButtonsModule } from '@sparro/app/shared/components/buttons/buttons.module';
import { ImagesModule } from '@sparro/app/shared/components/images/images.module';
import { LayoutModule } from '@sparro/app/shared/components/layout/layout.module';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';
import { LandingPageHeaderComponent } from './components/landing-page-header/landing-page-header.component';

@NgModule({
  declarations: [LandingPageHeaderComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ButtonsModule,
    LayoutModule,
    ImagesModule,
    PipesModule,
    FlexLayoutModule,
    ParallaxScrollModule
  ],
  exports: [LandingPageHeaderComponent]
})
export class SharedModule {}
