import { Renderer2, ElementRef } from '@angular/core';
export abstract class AbstractButtonDirective {
  activeState: boolean = false;
  loadingState: boolean = false;
  class_name: string;

  constructor(
    protected _renderer: Renderer2,
    protected element: ElementRef
  ) { }

  setState(state: boolean) {
    this.activeState = state;
    this.stateHandler();
  }
  
  toggleLoading(isLoading: boolean) {
    this.loadingState = isLoading;
    this.loadingHandler();
  }

  addClass() {
    this._renderer.addClass(this.element.nativeElement, this.class_name);
  }

  removeClass() {
    this._renderer.removeClass(this.element.nativeElement, this.class_name);
  }
  
  abstract stateHandler(); 
  abstract loadingHandler();
}
