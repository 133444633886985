import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from '@sparro/app/core/helpers/toastr/toastr.service';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss']
})
export class CustomToastComponent implements OnInit {
  customMessage: string;

  constructor(
    private toastService: ToastrService,
    public snackBarRef: MatSnackBarRef<CustomToastComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.toastService.customMessage.subscribe(message => {
      this.customMessage = message
    })
  }

  closeSnackbar() {
    this.snackBarRef.dismiss();
  }
}
