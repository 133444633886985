import { Renderer2, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[auxiliary-class]'
  })

  // USAGE => <element .... auxiliary-class auxiliaryClassName="class name here" >

export class AuxiliaryClassDirective {
  @Input() public auxiliaryClassName: string;

  constructor(
    protected _renderer: Renderer2,
    protected element: ElementRef
  ) { }

  ngOnInit() {
  }

  ngAfterContentChecked(): void {
    this.addClass();
  }

  addClass() {
    if(this.element.nativeElement && this.auxiliaryClassName) {
      this._renderer.addClass(this.element.nativeElement, this.auxiliaryClassName);
    }
  }
}
