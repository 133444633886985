<section class="uni-comment-section">
  <ng-container *ngIf="user_checked || is_authenticated">
    <section id="createsection" class="comment-create margin-bottom-4">
      <uni-comment-create
        [user]="user"
        [slug]="slug"
        [owner_flag]="owner_flag"
        [juror_flag]="juror_flag"
        [source]="source"
        [mentioned_users]="mentioned_users"
        (comment_response)="created($event)"
        (remove_mention)="removeMention($event)"
        (clear_comment)="clearComment($event)"
        style="height: 170px"
        [nested]="nested && current_nest_depth < max_nest_depth"
        [parent]="parent"
        [child_group]="child_group">
        <div *ngIf="!is_authenticated" commentPlaceholder>
          <p class="comment-header font-xs">
            Please &nbsp; <a class="link" (click)="goToLogin()">Login </a>&nbsp; or &nbsp;<a
              class="link"
              (click)="goToLogin()"
              >Sign up </a
            >&nbsp; to add comments
          </p>
        </div>
      </uni-comment-create>
    </section>
    <section class="comment-list height-100" *ngIf="comments.length">
      <uni-comment-list
        [showFooter]="showFooter"
        [comments]="comments"
        [user]="user"
        [slug]="slug"
        [owner_flag]="owner_flag"
        [source]="source"
        (mentioned)="userMentioned($event)"
        [max_nest_depth]="max_nest_depth"
        [current_nest_depth]="current_nest_depth">
      </uni-comment-list>
    </section>
  </ng-container>
  <ng-container *ngIf="!create_only">
    <ng-container *ngIf="showLoader; else nextButton">
      <div fxLayoutAlign="center center" style="height: 200px">
        <uni-loader></uni-loader>
      </div>
    </ng-container>
    <ng-template #nextButton>
      <div
        *ngIf="next"
        fxLayoutAlign="center center"
        style="margin-top: 30px; padding-bottom: 30px">
        <uni-state-button
          default="Load more comments"
          button_class="secondary"
          (click)="loadMoreComments()">
        </uni-state-button>
      </div>
      <div
        class=""
        *ngIf="!next && comments_count > 0 && !child_group"
        fxLayoutAlign="center center">
        <!-- <p class="sparro-grey-900 font-bold font-sm" style="margin:30px">That's all folks!</p> -->
        <!-- <a class="font-sm black-text bold" style="margin:30px; text-decoration:none" (click)="toggleComments(true)">Hide comments</a> -->
      </div>
    </ng-template>
  </ng-container>
  <!-- <ng-template #toggleCommentsButton>
    <a *ngIf="comments_count > 0" class="font-sm black-text bold" style="margin:30px; text-decoration:none" (click)="toggleComments(false)">Show comments</a>
  </ng-template> -->
</section>
