import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[float-button-direction]'
})
export class FloatButtonDirectionDirective implements OnInit {
  @Input() btnDirection: string;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.el.nativeElement.style.display = 'inline-flex';

    switch(this.btnDirection) {
      case 'right':
        this.setJustifyContent('flex-start');
        this.setDirection('row');
        break;

      case 'left':
        this.setJustifyContent('flex-start');
        this.setDirection('row-reverse');
        break;

      case 'down':
        this.setJustifyContent('flex-start');
        this.setDirection('column');
        break;

      case 'up':
        this.setJustifyContent('flex-end');
        this.setDirection('column-reverse');
        break;

      default:
          throw 'invalid direction.'
    }
  }

  private setJustifyContent(position: string) {
    this.el.nativeElement.style.justifyContent = position;
  }

  private setDirection(direction: string) {
    this.el.nativeElement.style.flexDirection = direction;
  }

}
