import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'uni-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() radius: number = 50;
  @Input() color: string = "#f15a24";

  constructor() { }

  ngOnInit() {
  }

}
