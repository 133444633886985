import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { PopoverService } from '@sparro/app/core/api/popover/popover.service';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { UserDefaultImageService } from '@sparro/app/core/helpers/image/user-default-image.service';
import { ImageMetaData } from '@sparro/app/core/models/media.model';
import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@sparro/env/environment';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'uni-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  @Input() user: User;
  @Input() self: boolean;
  @Input() size: 'nano' | 'micro' | 'small' | 'medium' | 'jumbo' = 'small';
  @Input() avatarClass: string = '';
  @Input() viewProfile: boolean = true;
  @Input() shape: string = 'circle';
  @Input() showPopover: boolean = true;
  userMetaData: ImageMetaData;
  isUploading: boolean = false;
  default: string = '';
  isDesktop: boolean = false;
  overlayRef: OverlayRef;
  isPopoverOpen = false;

  @ViewChild('avatar', { static: false }) avatar: ElementRef;

  constructor(
    private userDefaultImageService: UserDefaultImageService,
    private userService: UserService,
    public changeDetection: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    public popoverService: PopoverService
  ) {}

  togglePopover() {
    if (this.showPopover && window.innerWidth > 540)
      this.popoverService.toggleUserPopover(this.avatar.nativeElement, this.user);
  }

  ngOnInit(): void {
    this.setDefaultAvatar();
    this.initializeBreakpoints();
  }

  initializeBreakpoints(): void {
    const tabletBreakpoint = BREAKPOINTS.above.md;
    this.breakpointObserver.observe([tabletBreakpoint]).subscribe(result => {
      this.isDesktop = result.breakpoints[tabletBreakpoint];
    });
  }

  setDefaultAvatar(): void {
    this.default = this.userDefaultImageService.setDefaultAvatar(
      this.user.first_name,
      this.user.last_name,
      this.user.email,
      this.user.username,
      this.shape
    );
  }
  setUserImageMetaData(): void {}

  getProfileUrl(user: User): string {
    return this.self
      ? `${environment.profileUrl}edit/${user.username}/`
      : `${environment.profileUrl}${user.username}/`;
  }

  uploadFile(event, type): void {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      // this.files.push(element.name)
      const fileData = new FormData();
      fileData.append('user', `${this.user.id}`);
      fileData.append('title', '');
      fileData.append('description', '');
      fileData.append('file', element, element.name);
      fileData.append('type', 'logo');
      if (type === 'img') {
        this.uploadAvatarImage(fileData);
      }
    }
  }

  uploadAvatarImage(fileData: FormData): void {
    this.userService.uploadUserAvatar(fileData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.isUploading = true;
        } else if (event.type === HttpEventType.Response) {
          this.isUploading = false;
          this.user.avatar_url = event.body.avatar_url;
          this.changeDetection.detectChanges();
        }
      },
      error => {
        console.log(error);
        this.isUploading = false;
      }
    );
  }

  deleteAvatar(): void {
    this.userService.deleteUserAvatar(this.user.id).subscribe((response: any) => {
      if (response.success) {
        this.user.avatar_url = '';
      }
    });
  }
}
