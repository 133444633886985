<div
  class="error-section border-radius-3"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap.gt-sm="30px">
  <div fxFlex="50" fxFlex.lt-sm="30" class="error-page-image-container">
    <uni-image
      [src]="errorObj?.image ? errorObj?.image : defaultErrorImage"
      [alt]="errorObj.code + ' error'"
      classes="width-100 height-100">
    </uni-image>
  </div>
  <div style="margin: 0 auto; padding: 0 12px" fxLayout="column">
    <h1 class="error-message font-bold">
      {{ errorObj.message ? errorObj.message : defaultErrorMessage }}
    </h1>
    <a
      *ngIf="errorObj.redirect.url && errorObj.redirect.message"
      class="redirect-link"
      [href]="errorObj.redirect.url">
      {{ errorObj.redirect.message }}
    </a>
    <p *ngIf="errorObj.redirect.url === '' && errorObj.redirect.message" class="redirect-link">
      {{ errorObj.redirect.message }}
    </p>
  </div>
</div>
