import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBar } from '@sparro/app/core/models/snackbar.model';
import { SnackbarManagerService } from '@sparro/core/helpers/toastr/snackbar-manager.service';

@Component({
  selector: 'app-snackbar-display',
  templateUrl: './snackbar-display.component.html',
  styleUrls: ['./snackbar-display.component.scss']
})
export class SnackbarDisplayComponent implements OnInit {
  snackbars: SnackBar[] = [];

  constructor(
    private snackbarManager: SnackbarManagerService,
    public snackBarRef: MatSnackBarRef<SnackbarDisplayComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.snackbarManager.snackBarQueueObservable.subscribe((snackbarQueue) => {
      this.snackbars = snackbarQueue;
    })
  }

  ngAfterViewInit() {
    this.closeSnackbarAfterTimeout();
  }

  closeSnackbarByUser(snackbar: SnackBar) {
    if(this.snackbarManager.snackBarQueue.indexOf(snackbar) > -1) {
      this.snackbarManager.snackBarQueue.splice(this.snackbarManager.snackBarQueue.indexOf(snackbar), 1);
      this.snackbarManager.snackBarQueueObservable.next(this.snackbarManager.snackBarQueue);
    }
    if(this.snackbarManager.snackBarQueue.length == 0) {
      this.dismissSnackbar();
    }
  }

  closeSnackbarAfterTimeout() {
    this.snackbars.forEach((snackbar) => {
      if(this.snackbarManager.snackBarQueue.indexOf(snackbar) > -1) {
        setTimeout(() => {
          this.snackbarManager.snackBarQueue.splice(this.snackbarManager.snackBarQueue.indexOf(snackbar), 1);
          this.snackbarManager.snackBarQueueObservable.next(this.snackbarManager.snackBarQueue);
        }, snackbar.snackBarItem.duration)
        // this.closeSnackbarAfterTimeout();
      }
    })
    if(this.snackbarManager.snackBarQueue.length == 0) {
      this.dismissSnackbar();
    }
  }

  dismissSnackbar() {
    this.snackBarRef.dismiss();
  }

}
