import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'uni-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() errorObj: any;

  defaultErrorImage: string = 'https://uni-common-assets.s3.amazonaws.com/illustrations/svg/1/404.svg';
  defaultErrorMessage: string = 'Oops! Something went wrong';

  constructor() { }

  ngOnInit() {
  }

}
