import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getBlogComments(slug: string, page: number) {
    return this.http.get(this.baseUrl + 'blogs/' + slug + '/comments/?page=' + page);
  }

  createBlogComment(slug: string, comment) {
    return this.http.post(this.baseUrl + 'blogs/' + slug + '/comments/', comment);
  }

  updateBlogComment(slug: string, comment_pk: number, comment) {
    return this.http.put(this.baseUrl + 'blogs/' + slug + '/comments/' + comment_pk + '/', comment);
  }

  removeBlogComment(slug: string, comment_pk: number, user_pk: number) {
    return this.http.put(this.baseUrl + 'blogs/' + slug + '/comments/' + comment_pk + '/remove_vote/', {});
  }

  checkBlogCommentVote(slug: string, comment_pk: number) {
    return this.http.get(this.baseUrl + 'blogs/' + slug + '/comments/' + comment_pk + '/check_vote/', {});
  }

  upvoteBlogComment(slug: string, comment_pk: number, user_pk: number) {
    return this.http.put(this.baseUrl + 'blogs/' + slug + '/comments/' + comment_pk + '/upvote/', {});
  }

  downvoteBlogComment(slug: string, comment_pk, user_pk: number) {
    return this.http.put(this.baseUrl + 'blogs/' + slug + '/comments/' + comment_pk + '/downvote/', {});
  }

  getBlog(slug: string) {
    return this.http.get(this.baseUrl + 'blogs/' + slug + '/')
  }

  checkUserBlogLike(slug: string) {
    return this.http.get(this.baseUrl + 'blogs/' + slug + '/check_liked/');
  }

  likeBlog(slug: string) {
    return this.http.put(this.baseUrl + 'blogs/' + slug + '/like/', {});
  }

  deleteBlog(uuid: string) {
    return this.http.delete(this.baseUrl + 'blog/self/' + uuid + '/');
  }

  toggleUnlist(uuid: string) {
    return this.http.put(this.baseUrl + 'blog/self/' + uuid + '/toggle_unlist/', {});
  }

  uploadBlogCover(formData) {
    return this.http.post(this.baseUrl + 'blog_cover_upload/', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  removeBlogCover(blog_uuid: string) {
    return this.http.put(this.baseUrl + 'blog/self/' + blog_uuid + '/remove_discipline_thumbnail/', {});
  }
}
