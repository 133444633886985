import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NeoComment } from '@sparro/api/comment/comment.model';
import { User } from '@sparro/app/core/models/user.model';

@Component({
  selector: 'uni-comment-footer',
  templateUrl: './comment-footer.component.html',
  styleUrls: ['./comment-footer.component.scss']
})
export class CommentFooterComponent implements OnInit {
  @Input() comment: NeoComment;
  @Input() user: User;
  @Input() slug: string;
  @Input() source: string;
  @Input() nested = false;
  @Output() mention = new EventEmitter<any>();
  @Output() nestedReply = new EventEmitter<any>();
  can_reply: boolean = true;
  vote: number = 0;
  score: number = 0;

  constructor(
  ) { }

  ngOnInit() {
    // console.log(this.comment)
    this.checkUserVote();
    if (this.user.id == this.comment.user.id) {
      this.can_reply = false;
      //User cannot reply to his comments
    }
    if (this.user.id !== this.comment.user.id) {
      this.can_reply = true; // user cannot reply to his comments
    }
    this.can_reply = this.can_reply && !this.nested;
    // console.log(this.can_reply);
  }

  checkUserVote() {
    if (this.comment.is_removed) {
      return;
    }
    NeoComment.checkCommentVote(this.source, this.slug, this.comment.id).subscribe(
      (response) => {
        // console.log(response);
        this.setVoteData(response);
      }
    );
  }

  setVoteData(response) {
    this.vote = response.vote;
    this.score = response.score;
  }

  upvoteClicked() {
    if (this.comment.is_removed) {
      return;
    }
    if (this.vote == 1) {
      this.removeVote();
    } else {
      this.upvoteComment();
    }
  }

  downvoteClicked() {
    if (this.comment.is_removed) {
      return;
    }
    if (this.vote == -1) {
      this.removeVote();
    } else {
      this.downvoteComment();
    }
  }

  upvoteComment() {
    NeoComment.upvoteComment(this.source, this.slug, this.comment.id, this.user).subscribe(
      (response) => {
        // console.log(response);
        this.setVoteData(response);
      }, (err) => {
        console.log(err);
      }
    );
  }

  removeVote() {
    NeoComment.removeCommentVote(this.source, this.slug, this.comment.id, this.user).subscribe(
      (response) => {
        this.setVoteData(response);
      }, (err) => {
        console.log(err);
      }
    );
  }

  downvoteComment() {
    NeoComment.downvoteComment(this.source, this.slug, this.comment.id, this.user).subscribe(
      (response) => {
        this.setVoteData(response);
      }, (err) => {
        console.log(err);
      }
    );
  }

  mentionUser() {
    this.mention.emit(this.comment.user);
    // console.log('Footer is emitting user', this.comment.user);
  }

  nestedReplyEvent() {
    this.nestedReply.emit(true);
    // console.log('Reply karo re')
  }
}
