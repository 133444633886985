import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
// import { ShareModule } from 'ngx-sharebuttons';

import { ImagesModule } from '../images/images.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { LoaderModule } from '../loader/loader.module';
import { PipesModule } from '../../pipes/pipes.module';

import { CommentComponent } from './comment/comment.component';
import { CommentCreateComponent } from './comment-create/comment-create.component';
import { CommentEditComponent } from './comment-edit/comment-edit.component';
import { CommentGroupComponent } from './comment-group/comment-group.component';
import { CommentListComponent } from './comment-list/comment-list.component';
import { CommentUserComponent } from './comment-user/comment-user.component';
import { CommentFooterComponent } from './comment-footer/comment-footer.component';
import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';


@NgModule({
  declarations: [
    CommentComponent,
    CommentCreateComponent,
    CommentEditComponent,
    CommentGroupComponent,
    CommentListComponent,
    CommentUserComponent,
    CommentFooterComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    // ShareModule,
    ImagesModule,
    PipesModule,
    ButtonsModule,
    LoaderModule,
    DirectiveModule
  ],
  exports: [
    CommentGroupComponent,
    CommentCreateComponent,
    CommentUserComponent,
    CommentListComponent
  ]
})
export class CommentsModule { }
