<ul float-button-direction [btnDirection]="direction">
  <li>
    <div class="plus-icon"
      [@animateMain]="{value: togglerState}"
      (click)="triggerBtnMenu()"
      [style.background]="color"
      [class.plus-icon-sm]="isSmall">
    </div>
  </li>

  <li *ngFor="let btn of buttons" float-button-item>
    <button *ngIf="!isSmall && showBtns" class="section-button" [@buttonAnimation]="animateState"
      (click)="fireAction($event, btn.type)">
      <img src="{{ btn.icon }}" alt="{{ btn.type }}" height="40px" width="40px">
    </button>
    <button *ngIf="isSmall && showBtns" class="section-button" [@buttonAnimation]="animateState"
      (click)="fireAction($event, btn.type)">
      <img src="{{ btn.icon }}" alt="{{ btn.type }}" height="30px" width="30px">
    </button>

    <label *ngIf="shouldShowLabel(btn.label) && showBtns" [@labelAnimation]="showBtns"
        float-button-label [isSmall]="isSmall">
      {{ btn.label }}
    </label>
  </li>

</ul>
