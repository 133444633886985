export const environment = {
  production: false,
  staging: false,
  imageOptimizer: true,
  baseUrl: '/api/v2/',
  projectUrl: 'http://localhost:4004/',
  loginUrl: 'http://localhost:4003',
  serverUrl: 'http://localhost:8000',
  competitionUrl: 'https://localhost:4000',
  evaluationUrl: 'http;//localhost:4002/',
  profileUrl: 'http://localhost:4006',
};
