import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectService } from '@sparro/app/core/api/project/project.service';

import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'uni-project-embed',
  templateUrl: './project-embed.component.html',
  styleUrls: ['./project-embed.component.scss']
})
export class ProjectEmbedComponent implements OnInit {
  @Input() project_media: any; // TODO: ProjectMedia and Media Models
  isLoading = false;
  src: any = null;

  constructor(
    private projectService: ProjectService,
    private embedService: EmbedVideoService,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    // this.getMediaInfo();
    this.src = this.renderEmbed(this.project_media.media?.embed_code);
  }

  getMediaInfo() {
    this.isLoading = true;
    this.projectService.getEmbedProperties(this.project_media.media.uuid).subscribe(
      (media: any) => {
        this.isLoading = false;
        this.project_media.media = media;
      },
      (error) => {
        this.isLoading = false;
        console.error(error);
      }
    );
  }

  renderEmbed(embed_code: string) {
    if (embed_code.includes('iframe')) {
      const parser = new DOMParser();
      const parsedIframe: any = parser.parseFromString(embed_code, 'text/html');
      const iframe: any = parsedIframe.getElementsByTagName('iframe');
      const src = iframe[0].src;
      return this._sanitizer.bypassSecurityTrustResourceUrl(src);
      // const frame = `<iframe src=${src} frameborder="0" allowfullscreen></iframe>`;
      // return this._sanitizer.bypassSecurityTrustHtml(frame);
    } else {
      const parser = new DOMParser();
      const check = this.embedService.embed(embed_code);
      if (check !== undefined) {
        const src: any = parser.parseFromString(check, 'text/html').getElementsByTagName('iframe')[0].src;
        return this._sanitizer.bypassSecurityTrustResourceUrl(src);
      } else {
        return undefined;
      }
    }
  }
}
