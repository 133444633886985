import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@sparro/app/material.module';

import { ImagesModule } from '../images/images.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { DirectiveModule } from '../../directives/directive.module';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { CarouselComponent } from './carousel/carousel.component';
import { LeftSidenavCollapsedComponent } from './left-sidenav/left-sidenav-collapsed/left-sidenav-collapsed.component';
import { LeftSidenavExpandedComponent } from './left-sidenav/left-sidenav-expanded/left-sidenav-expanded.component';
import { RightSidenavComponent } from './right-sidenav/right-sidenav.component';
import { ErrorComponent } from './error/error.component';
import { PublicFooterComponent } from './public-footer/public-footer.component';
import { PipesModule } from '../../pipes/pipes.module';
import { TabsComponent } from './tabs/tabs.component';
import { GuideFooterComponent } from './guide-footer/guide-footer.component';
import { CompetitionsCardCarouselComponent } from './competitions-card-carousel/competitions-card-carousel.component';
import { CardsModule } from '../cards/cards.module';

import { FrequentQuestionsComponent } from './frequent-questions/frequent-questions.component';
import { LoaderModule } from '../loader/loader.module';
import { SearchComponent } from './search/search.component';
import { AiChatBotComponent } from './ai-chat-bot/ai-chat-bot.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    FooterComponent,
    PublicFooterComponent,
    HeaderComponent,
    CarouselComponent,
    LeftSidenavExpandedComponent,
    LeftSidenavCollapsedComponent,
    RightSidenavComponent,
    ErrorComponent,
    TabsComponent,
    GuideFooterComponent,
    CompetitionsCardCarouselComponent,
    FrequentQuestionsComponent,
    SearchComponent,
    AiChatBotComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ImagesModule,
    ButtonsModule,
    DirectiveModule,
    PipesModule,
    CardsModule,
    LoaderModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  entryComponents: [HeaderComponent],
  exports: [
    HeaderComponent,
    FooterComponent,
    PublicFooterComponent,
    CarouselComponent,
    LeftSidenavExpandedComponent,
    LeftSidenavCollapsedComponent,
    RightSidenavComponent,
    GuideFooterComponent,
    ErrorComponent,
    TabsComponent,
    CompetitionsCardCarouselComponent,
    FrequentQuestionsComponent,
    SearchComponent,
    AiChatBotComponent
  ]
})
export class LayoutModule {}
