import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
import { ShareModule } from 'ngx-sharebuttons';

import { PipesModule } from '../../pipes/pipes.module';
import { ImagesModule } from '../images/images.module';
import { LoaderModule } from '../loader/loader.module';

import { ButtonsModule } from '../buttons/buttons.module';
import { BlogPanelComponent } from './blog-panel/blog-panel.component';
import { ProjectPanelComponent } from './project-panel/project-panel.component';
import { PublicationDocumentViewComponent } from './publication-panel/components/publication-document-view/publication-document-view.component';
import { PublicationPanelComponent } from './publication-panel/publication-panel/publication-panel.component';

@NgModule({
  declarations: [
    BlogPanelComponent,
    ProjectPanelComponent,
    PublicationPanelComponent,
    PublicationDocumentViewComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    LoaderModule,
    ImagesModule,
    PipesModule,
    ShareModule,
    ButtonsModule,
  ],
  exports: [
    BlogPanelComponent,
    ProjectPanelComponent,
    PublicationPanelComponent,
    PublicationDocumentViewComponent,
  ]
})
export class PanelModule { }
