import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NeoComment } from '@sparro/api/comment/comment.model';

// import _ from 'lodash';

@Component({
  selector: 'uni-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent implements OnInit {
  @Input() comments: NeoComment[] = [];
  @Input() showFooter: boolean = true;
  @Input() user: any;
  @Input() slug: string;
  @Input() owner_flag: boolean;
  @Input() source: string;
  @Output() mentioned = new EventEmitter<any>();
  @Input() max_nest_depth: number = 0;
  @Input() current_nest_depth: number = 0;

  constructor() {}

  ngOnInit() {
    // console.log(this.comments, "list");
  }

  removed(removed_comment: any) {
    // console.log("removed event");
    this.comments.map((comment, index) => {
      if (comment.id == removed_comment.id) {
        // console.log(comment, "matched");
        this.comments.splice(index, 1);
      }
    });
  }

  hightlight(data: any) {
    // console.log(data, "Highlight event");
  }

  userMentioned(data: any) {
    console.log(data);
    this.mentioned.emit(data);
  }
}
