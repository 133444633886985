<!-- TODO: Add popover -->
  <div fxLayout="row" *ngIf="avatar_mode && user">
    <uni-user-avatar [user]="user"></uni-user-avatar>
  </div>
  <div fxLayout="column" fxLayoutGap="2px" *ngIf="header_mode" >
    <span class="comment-text font-bold sparro-grey-800"><a [href]="getProfileUrl(user)" target="_blank" class="no-decor margin-right-1">{{ user.first_name | titlecase }} {{ user.last_name | titlecase }}</a>
      <span *ngIf="flair" class="font-bold font-xxs sparro-green-600 flair-badge">{{ flair }}</span>&nbsp;
    </span>
    <span class="comment-subtext sparro-grey-800"> {{ user.bio }} </span>
  </div>

  <div fxLayout="row" *ngIf="is_removed">
    <uni-user-avatar [user]="user"></uni-user-avatar>
  </div>
