import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { StorageHandlerService } from '@sparro/core/helpers/storage/storage-handler.service';
import { User } from '@sparro/core/models/user.model';
import { MetaService } from '../../helpers/meta/meta.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `api/v2/`;
  user: BehaviorSubject<User>;
  userInitialized = new BehaviorSubject(false);
  unreadNotificationCount = new BehaviorSubject(0);

  constructor(
    private http: HttpClient,
    private storage: StorageHandlerService,
    private metaService: MetaService
  ) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  setUser(user: User, store: boolean = false, callback?: (user: User) => any): void {
    this.user = new BehaviorSubject(user);
    if (user && store) {
      this.storage.storeItem('user', user);
    }
    this.userInitialized.next(true);
    if (callback) {
      callback(user);
    }
  }

  unsetUser(): void {
    this.user.next(null);
    this.storage.removeItem('user');
  }

  fetchUser(callback: (user: User) => any): void {
    if (this.user?.value) {
      callback(this.user.value);
    }
    const storageUser = this.storage.getItem('user');
    if (storageUser) {
      this.setUser(storageUser, false, callback);
    } else {
      this.getAuthUser(user => this.setUser(user, true, callback));
    }
  }

  getAuthUser(callback: (user: User) => any): void {
    this.http
      .get(this.baseUrl + 'profiles/auth/')
      .pipe(
        map((response: Object) => {
          response = this.metaService.decodeApiResponse(response);
          const modifiedResponse = response as User;
          return modifiedResponse;
        })
      )
      .subscribe(
        (modifiedResponse: User) => {
          callback(modifiedResponse);
        },
        (error: any) => {
          callback(null);
        }
      );
  }

  searchUserById(userId: number): Observable<any> {
    return this.http.get(this.baseUrl + 'profiles/' + userId + '/');
  }

  searchByUserName(name: string): Observable<any> {
    return this.http
      .get(this.baseUrl + 'profiles/search_by_username/?username=' + name + '&partial=true')
      .pipe(
        map(response => {
          return this.metaService.decodeApiResponse(response);
        })
      );
  }

  searchByUserEmail(email: string) {
    return this.http.get(this.baseUrl + 'profiles/search_by_email/?email=' + email).pipe(
      map(response => {
        return this.metaService.decodeApiResponse(response);
      })
    );
  }

  checkProjectOwner(projectSlug: string) {
    return this.http.get(this.baseUrl + 'neo_projects/' + projectSlug + '/check_owner/');
  }

  checkCompetitionJuror(competitionSlug: string) {
    return this.http.get(
      this.baseUrl + 'competitions/' + competitionSlug + '/is_competition_jury/'
    );
  }

  getUserProfileTabs(): { path: string; name: string }[] {
    return [
      {
        path: '',
        name: 'Profile'
      },
      {
        path: 'competitions',
        name: 'Competitions'
      },
      {
        path: 'projects',
        name: 'Projects'
      },
      {
        path: 'publications',
        name: 'Publications'
      },
      {
        path: 'posts',
        name: 'Posts'
      }
    ];
  }

  getUserCover(userId: string | number) {
    return this.http.get(this.baseUrl + 'profiles/' + userId + '/personal/cover/');
  }

  uploadUserCoverImage(fileData) {
    return this.http.post(this.baseUrl + 'user_cover_upload/', fileData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadUserAvatar(formData) {
    return this.http.post(this.baseUrl + 'user_media_upload/', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteUserProject(uuid: string) {
    return this.http.delete(this.baseUrl + 'neo_project/self/' + uuid + '/', {});
  }

  hasActiveMembership(): Observable<any> {
    return this.http.get(this.baseUrl + 'user_memberships/has_active_memberships/');
  }

  deleteUserCover(userId: number) {
    return this.http.delete(this.baseUrl + 'profiles/' + userId + '/personal/delete_cover/');
  }

  deleteUserAvatar(userId: number) {
    return this.http.delete(this.baseUrl + 'profiles/' + userId + '/personal/delete_avatar/');
  }

  uploadUserMedia(formData) {
    return this.http.post(this.baseUrl + 'user_files/upload/', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateImageProperties(uuid: string, postData) {
    return this.http.put(this.baseUrl + 'neo_medias/' + uuid + '/', postData);
  }

  isAdmin() {
    return this.http.get(this.baseUrl + 'profiles/is_admin/');
  }

  logout() {
    const postData = {};
    return this.http.post(this.baseUrl + 'rest-auth/logout/', postData);
  }

  getUniFooter() {
    return this.http.get(this.baseUrl + 'footer/');
  }

  subscribeNewsletter(subscribersInfo: any) {
    return this.http.post(this.baseUrl + 'subscribe_newsletter/subscribe/', subscribersInfo);
  }

  getSearchTabs() {
    return [
      {
        url: `${environment.competitionUrl}search`,
        name: 'Competition'
      },
      {
        url: `${environment.projectUrl}search`,
        name: 'Project'
      },
      {
        url: `${environment.imageUrl}search`,
        name: 'Image'
      },
      {
        url: `${environment.postUrl}search`,
        name: 'Journal'
      },
      {
        url: `${environment.profileUrl}search`,
        name: 'Profile'
      },
      {
        url: `${environment.publicationUrl}search`,
        name: 'Publication'
      },
      {
        url: `${environment.organizationUrl}search`,
        name: 'Organization'
      }
    ];
  }

  getNotificationSubscription(userId: number) {
    return this.http.get(this.baseUrl + 'notification/' + userId + '/');
  }

  updateNotificationSubscription(postData) {
    return this.http.put(`${this.baseUrl}notification/${postData.id}/`, postData);
  }

  getUserMetaInfo(username: string) {
    return this.http.get(this.baseUrl + 'profiles/user_meta/?username=' + username);
  }
}
