<uni-state-button
  [activeState]="isActive"
  [default]="default"
  [button_class]="button_class"
  button_type="toggle"
  [icon_only]="icon_only"
  [icon_class]="icon_class"
  [disabled]="disabled"
  [showLoader]="showLoader"
  [loadingText]="loadingText">
</uni-state-button>
<template #dividerTemplate></template>
