import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, ViewChild } from '@angular/core';
import { ButtonTitleDirective } from '@sparro/app/shared/directives/button-title.directive';
import { ButtonClassDirective } from '@sparro/app/shared/directives/buttons/button-class.directive';
import { ButtonIconDirective } from '@sparro/app/shared/directives/buttons/button-icon.directive';

@Component({
  selector: 'uni-state-button',
  templateUrl: './state-button.component.html',
  styleUrls: ['./state-button.component.scss']
})
export class StateButtonComponent implements AfterViewInit {
  private _state: boolean;
  title: string;

  // Commenting and adding a possible hack to remove undefined error
  // @Input() set activeState(value: boolean) {
  //   this._state = value;
  //   this.setState();
  // }

  @Input() activeState: boolean;
  @Input() button_type: 'state' | 'navigation' | 'toggle' = 'state';
  @Input() default: string;
  @Input() active: string;
  @Input() activeHover: string;
  @Input() inactiveHover: string;
  @Input() loading: string;
  @Input() button_class: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() icon_class: string;
  @Input() icon_class_active: string;
  @Input() icon_only: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showLoader: boolean = false;
  @Input() loadingText: string = '';
  @Input() customClass: string = '';

  @ViewChild(ButtonTitleDirective) buttonTitleDirective: ButtonTitleDirective;
  @ViewChild(ButtonClassDirective) buttonClassDirective: ButtonClassDirective;
  @ViewChild(ButtonIconDirective) buttonIconDirective: ButtonIconDirective;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // console.log(this.default)
    // this.title = this.default;
    // this._state = this.activeState
    // this.setState()
  }

  ngAfterViewInit() {
    this.title = this.default;
    this._state = this.activeState;
    this.setState();
    this.changeDetectorRef.detectChanges();
  }

  setState() {
    this.buttonClassDirective?.toggleLoading(this.showLoader);
    this.buttonTitleDirective?.setState(this._state);
    this.buttonClassDirective?.setState(this._state);
    this.buttonIconDirective?.setState(this._state);
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.buttonTitleDirective.onMouseEnter();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.buttonTitleDirective.onMouseLeave();
  }

  @HostListener('click', ['$event']) onMouseClick(event) {
    if (this.disabled) {
      event.stopPropagation();
    }
  }

  titleChanged(title) {
    this.title = title;
  }

}
