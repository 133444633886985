import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@sparro/env/environment';
import { UserService } from '@sparro/app/core/api/user/user.service';


@Component({
  selector: 'uni-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit {
  sections = [
    {
      title: 'Project',
      icon: 'icon-project',
      url: `${environment.projectUploaderUrl}new-project/`,
    },
    {
      title: 'Journal',
      icon: 'icon-journal',
      url: `${environment.postUrl}edit`,
    },
    {
      title: 'Publication',
      icon: 'icon-publication',
      url: `${environment.publicationUrl}edit/new_publication`,
    },
  ]
  activeMembership: any=null;
  constructor(
    public dialogRef: MatDialogRef<CreateDialogComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.checkActiveMembership();
  }

  checkActiveMembership(): void {

    this.userService.hasActiveMembership().subscribe(
      (response) => {
        if ('success' in response) {
          this.activeMembership = response.success;
        }
      },
      (err) => {
        console.log(err);
      });
  }

  close() {
    this.dialogRef.close();
  }
}
