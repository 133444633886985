import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouteService } from '@sparro/app/core/helpers/route/route.service';
import { environment } from '@sparro/env/environment';
import { SearchService } from '@sparro/app/core/api/search/search.service';
import { CreateDialogComponent } from '../../../dialogs/create-dialog/create-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from '@sparro/app/core/api/header/header.service';
import { UserService } from '@sparro/app/core/api/user/user.service';

@Component({
  selector: 'uni-left-sidenav-collapsed',
  templateUrl: './left-sidenav-collapsed.component.html',
  styleUrls: ['./left-sidenav-collapsed.component.scss']
})
export class LeftSidenavCollapsedComponent implements OnInit {
  leftNavLinks: any;
  isOpened: any = false;
  discoverSections = [
    {
      title: 'Competitions',
      url: `${environment.competitionUrl}`,
      icon: 'icon-competition-fill'
    },
    {
      title: 'Projects',
      url: `${environment.projectUrl}`,
      icon: 'icon-project-fill'
    },
    {
      title: 'Journals',
      url: `${environment.postUrl}`,
      icon: 'icon-journal-fill'
    },
    {
      title: 'Publications',
      url: `${environment.publicationUrl}`,
      icon: 'icon-publication-fill'
    },
    {
      title: 'Images',
      url: `${environment.projectUrl}image/search`,
      icon: 'icon-image-fill'
    }
    // {
    //   title: 'Organizations',
    //   url: `${environment.organizationUrl}/search`,
    //   icon: 'icon-organization-fill'
    // }
  ];

  @Input() user: any = null;
  @Output() toggle = new EventEmitter();
  @Input() showSidenav: boolean = false;
  @Input() activeSection: string = '';
  @Input() showDiscoverSection: boolean = false;
  activeFeed: string = '';
  activeApp: string = '';

  constructor(
    private routeService: RouteService,
    private searchService: SearchService,
    public dialog: MatDialog,
    private headerService: HeaderService,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (!this.user) {
      this.userService.fetchUser(user => {
        if (user) {
          this.user = user;
        }
      });
    }
    this.headerService.getLeftNav().subscribe(res => (this.isOpened = res));
    this.searchService.getActiveSearchHost().subscribe(res => {
      this.activeFeed = res;
    });
    this.searchService.getActiveAppFeedUrl().subscribe(res => {
      this.activeApp = res;
    });
    this.leftNavLinks = this.routeService.populateDataInLeftnav(this.user, window.location.href);
  }

  toggleLeftSidenav() {
    this.headerService.setLeftNav(!this.isOpened);
    // this.toggle.emit();
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateDialogComponent, {
      width: '400px'
    });
  }

  openMenu() {
    this.headerService.openMenu();
  }
}
