import { Component, OnInit, Input, Injector } from '@angular/core';
import { FollowerService } from '@sparro/app/core/api/follower/follower.service';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@sparro/env/environment';
// import { UniApiConfig } from '@sparro/app/core/config/api.config';
import { PopoverService } from '@sparro/app/core/api/popover/popover.service';


@Component({
  selector: 'uni-user-card-popover',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user: User;
  currentUser: User;
  metaInfo: any = null;
  userCover: any = null;
  source: string;
  defaultCoverImage: string = "https://uni-common-assets.s3.amazonaws.com/illustrations/png/1/default-profile-cover.png";
  profileUrl: string = `${environment.profileUrl}`;

  constructor(
    private userService: UserService,
    private imageOptimizer: ImageOptimizerService,
    private followerService: FollowerService,
    private injector: Injector
  ) { 
    this.user = this.injector.get(PopoverService).data;
  }

  ngOnInit(): void {
    this.source = 'users';
    this.getAuthUser();
    this.getUserMetaInfo();
    this.getCover();
    this.defaultCoverImage = this.renderImage(this.defaultCoverImage, 312, 98);
  }

  getAuthUser(): void {
    this.userService.fetchUser(user => {
      if (user) {
        this.currentUser = user
      }
    });
  }

  getUserMetaInfo() {
    this.userService.getUserMetaInfo(this.user.username).subscribe(res => {
      this.metaInfo = res;
    }, error => {
      console.log(error);
    })
  }

  getCover() {
    this.userService.getUserCover(this.user.id).subscribe(res => {
      this.userCover = res;
      if (this.userCover?.cover_image?.url) {
        this.userCover.cover_image.url = this.renderImage(this.userCover.cover_image.url, 312, 98);
      }
    }, error => {
      console.log(error);
    })
  }

  renderImage(src, width, height) {
    return this.imageOptimizer.renderImage(src, width, height);
  }

  toggleProfileFollow(): void {
    if(this.currentUser) {
      if (this.metaInfo.is_following) {
        this.followerService
          .unfollow(
            this.source,
            this.user.username,
            this.metaInfo?.is_following?.id
          )
          .subscribe((response) => {
            if (response) {
              this.metaInfo.is_following = null;
            }
          });
      } else {
        const data = {
          follower: this.currentUser.id,
        };
        this.followerService
          .follow(this.source, this.user.username, data)
          .subscribe(
            (response: any) => {
              this.metaInfo.is_following = response;
            },
            (err) => {
              console.log(err);
            }
          );
      }
    } else {
      window.location.href = `${environment.loginUrl}authentication?service=sign-in&continue=${window.location.href}`;
    }
  }
}
