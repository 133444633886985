<ng-container>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" (click)="toggleLike()"
    [ngClass]="{'like-rounded-border': border, 'cursor-pointer': true }"
    [ngStyle]="{'padding': border ? '8px 16px' : '0'}">

    <div fxLayout="row" fxLayoutAlign="center center"
      [ngClass]="{
        'normal-like-button': isNormal && !showCount,
        'jumbo-like-button': isJumbo,
        'like-button-with-count': isNormal && showCount }">
        <i [ngClass]="{'resize-icon': isNormal, 'jumbo-icon': isJumbo, 'icon-like-fill': item?.user_like, 'icon-like': !item?.user_like}"></i>
    </div>

    <p class="font-sm sparro-grey-900 like-count font-regular" *ngIf="showCount">
      {{ item?.likes === 0 ? '' : item?.likes }} {{ item?.likes > 1 ? 'Likes' : 'Like' }}
    </p>
  </div>
</ng-container>
