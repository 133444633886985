import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';

import { LoaderComponent } from './loader/loader.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';

@NgModule({
  declarations: [
    LoaderComponent,
    SkeletonLoaderComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    DirectiveModule
  ],
  exports: [
    LoaderComponent,
    SkeletonLoaderComponent
  ]
})
export class LoaderModule { }
