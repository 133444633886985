<ng-container *ngIf="self; else readOnly">
    <div class="cover-container">
        <uni-image [src]="coverImage" [default]="defaultImage" alt="Cover Image" [height]="476"
            classes="fit-cover width-100 height-100 border-bottom" #cover>
        </uni-image>
        <div class="middle">
            <div class="icon-container-box">
                <i (click)="fileInput.click()" class="icon-upload">
                    <input hidden type="file" #fileInput (change)="uploadFile($event, 'img')"
                        accept="img/*">
                    <ng-container *ngIf="isUploading; else notUploading">
                        <div class="loader">
                            <uni-loader></uni-loader>
                        </div>
                    </ng-container>
                    <ng-template #notUploading>
                        <!-- <div class="edit">
              <button mat-stroked-button (click)="fileInput.click()">Cover image<mat-icon>edit</mat-icon></button>
            </div> -->
                    </ng-template>
                </i>
                <!-- <i *ngIf="coverImage" (click)="deleteCoverImage()" class="icon-delete-bold"></i> -->
            </div>
        </div>
    </div>
</ng-container>
<ng-template #readOnly>
    <uni-image [src]="coverImage" [default]="defaultImage" alt="Cover Image" [height]="476"
        classes="fit-cover width-100 height-100 border-bottom" #cover>
    </uni-image>
</ng-template>
