<div class="wrapper" fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="30px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="space-around center"
    fxLayoutAlign.lt-md="space-between center">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="file-upload">
        <input type="file" (change)="onFileChanged($event)" multiple accept="image/*"
          title="upload image in .jpg, .gif, .png format only max size 15mb" #fileUpload>
        <i class="icon-upload-1 sparro-grey-800"></i>
      </div>
      <p class="font-xs sparro-grey-900" fxHide.lt-sm>Upload images</p>
    </div>
  </div>
  <div class="image-info" *ngFor="let file of files" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="30px">
    <div>
      <uni-image [src]="file.url" alt="{{ file.title }}" [height]="115" [width]="230" classes="fit-cover height-auto">
      </uni-image>
    </div>
    <div>uuid : {{file.uuid}}</div>
    <div>url : {{file.url}}</div>
    <mat-form-field class="width-100">
      <mat-label>Add image title</mat-label>
      <input matInput placeholder="Add image title" [(ngModel)]="file.title" required>
    </mat-form-field>
    <mat-form-field class="width-100">
      <mat-label>Add image description</mat-label>
      <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="50" matInput maxlength="360"
        placeholder="Add image description. 360 characters max." [(ngModel)]="file.description">
        </textarea>
    </mat-form-field>
    <div class="btn-container width-100" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="12px">
      <ng-container>
        <uni-navigation-button 
          default="Save"
          button_class="primary"
          (click)="updateMediaInfo(file)">
        </uni-navigation-button>
      </ng-container>
    </div>
  </div>
</div>