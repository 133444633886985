import { ReportService } from '@sparro/app/core/api/report/report.service';

export class ReportType {
  id: number;
  title: string;
  info: string;
}

export class ReportArchType {
  title: string;
  id: number;
  info: string;
  types: ReportType[];
  active?: boolean;
}

export class Report {
  id: number;
  reporter: {
    id: number;
  };
  report_type: ReportType;
  url: string;
  additional_info: string;
  image: Object;
  embed_code: Object;
  status: string;
  created_at: string;
  static api_viewset: string;

  'construct': Pick<typeof Report, keyof typeof Report>;

  static createReport(api_viewset: string, slug: string, report) {
    return ReportService.instance.createReport(api_viewset, slug, report);
  }

  static redactReport(api_viewset: string, slug: string, report_id: number) {
    return ReportService.instance.redactReport(api_viewset, slug, report_id);
  }
}

export class ProjectReport extends Report {
  static api_viewset = 'neo_projects';
}

export class BlogReport extends Report {
  static api_viewset = 'blogs';
}

export class PublicationReport extends Report {
  static api_viewset = 'publications';
}

export class UserReport extends Report {
  static api_viewset = 'users';
}
