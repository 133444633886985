<mat-drawer-container #matdrawer (backdropClick)="closeBackdrop()">
  <mat-drawer #leftnav role="navigation" class="left-sidenav-drawer" [opened]="leftSideNav">
    <uni-left-sidenav-collapsed
      class="fixed top-0"
      (toggle)="closeBackdrop()"
      [showSidenav]="true"
      [showDiscoverSection]="true">
    </uni-left-sidenav-collapsed>
  </mat-drawer>

  <mat-drawer role="navigation" position="end" #rightnav>
    <uni-right-sidenav [user]="user" (toLogout)="logout($event)"> </uni-right-sidenav>
  </mat-drawer>
  <mat-drawer-content>
    <div class="fixed top-0" fxLayout="row" *ngIf="!mobileView">
      <uni-left-sidenav-collapsed [showSidenav]="true" (toggle)="leftToggle($event)">
      </uni-left-sidenav-collapsed>
    </div>
    <div>
      <uni-header
        [user]="user"
        [search]="false"
        [transparentHeader]="true"
        (leftSidenav)="leftToggle($event)"
        (rightSidenav)="rightToggle($event)">
      </uni-header>
    </div>
    <main class="app-main">
      <div class="margin-auto notification-section">
        <ng-container *ngIf="!isLoading && !notifications.length">
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="margin-y-20"
            fxLayoutGap="24px">
            <img
              src="https://uni-user.s3.amazonaws.com/te/team212filesteam2122022-12-06T10-59-40-510389.png"
              alt="no-notification image"
              class="bell-img" />
            <p class="margin-0 font-md font-bold">No notifications yet!</p>
          </div>
        </ng-container>
        <ng-container *ngIf="notifications.length">
          <div class="notification-container" #notificationSection>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="width-100 margin-bottom-4">
              <p class="margin-0 font-bolder font-md">
                {{ notifications.length > 1 ? 'Notifications' : 'Notification' }}
              </p>
              <p
                class="font-bold cursor-pointer padding-right-1 margin-0 mark-all-read"
                (click)="markAllRead()">
                <i class="icon-tick-bold-1"></i>
                Mark all as read
              </p>
            </div>
            <div fxLayout="column" class="margin-auto width-100">
              <ng-container *ngFor="let notification of notifications">
                <div
                  class="notification-box relative"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="16px"
                  [class.unread]="!notification.read">
                  <ng-container *ngIf="notification.subject; else mainObjectView">
                    <uni-user-avatar
                      style="z-index: 1"
                      [user]="notification.subject"
                      avatarClass="subject-img"></uni-user-avatar>
                  </ng-container>
                  <ng-template #mainObjectView>
                    <a
                      [href]="serverUrl + notification.main_object.url"
                      class="no-decor"
                      style="z-index: 1">
                      <uni-image
                        [src]="notification.main_object.image"
                        [alt]="notification.main_object.title"
                        [height]="64"
                        [width]="64"
                        classes="border-radius-2 fit-cover">
                      </uni-image>
                    </a>
                  </ng-template>
                  <a
                    [href]="serverUrl + notification.link"
                    (click)="markRead(notification.id)"
                    target="_blank"
                    class="no-decor margin-0 width-100 sparro-grey-900 text">
                    <span *ngIf="notification.subject" class="font-bold">
                      {{
                        notification.subject.first_name + ' ' + notification.subject.last_name
                          | titlecase
                      }}
                    </span>
                    {{ notification.main_action_statement }}
                  </a>

                  <div fxLayout="row" fxLayoutAlign="center center">
                    <p class="margin-0 time sparro-grey-800">
                      {{ notification.created_at | timesince : 'short' }}
                    </p>
                    <div
                      mat-icon-button
                      class="cursor-pointer margin-left-1"
                      style="z-index: 1"
                      [matMenuTriggerFor]="menu"
                      *ngIf="!notification?.read">
                      <mat-icon class="icon-3-vertical-dots-fill sparro-grey-800"> </mat-icon>
                    </div>

                    <mat-menu #menu="matMenu">
                      <!-- <button mat-menu-item *ngIf="!notification?.archived">
                                                <span>{{ notification?.archived ? 'Archived' : 'Archive' }}</span>
                                            </button> -->
                      <button
                        mat-menu-item
                        *ngIf="!notification?.read"
                        (click)="markRead(notification.id)">
                        <span>Mard as read</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isLoading">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            [class.height-75vh]="!notifications.length">
            <uni-loader></uni-loader>
          </div>
        </ng-container>
      </div>

      <uni-public-footer></uni-public-footer>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
