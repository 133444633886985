import { Component, OnInit, Input, Output, EventEmitter, ElementRef, Renderer2} from '@angular/core';

@Component({
  selector: 'uni-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  
  @Input() isActive: boolean;
  @Input() default: string; //inactive state
  @Input() active: string;  //active state, in case the button has different text for active state
  @Input() button_class: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() icon_class: string;        //inactive
  @Input() icon_class_active: string; //active
  @Input() icon_only: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showLoader: boolean = false;  
  @Input() loadingText: string = '';
  @Output() toggleButtonActiveState: EventEmitter<boolean> = new EventEmitter;

  constructor 
  (public el: ElementRef,
   public renderer: Renderer2) { }

  ngOnInit() {
  }

  toggle(){
    this.isActive = !this.isActive;
    this.toggleButtonActiveState.emit(this.isActive);
  }

}
