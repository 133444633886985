import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDefaultImageService {
  letters = 'abcdefghijklmnopqrstuvwxyz';
  default: string = '';
  defaultChar: string = '';

  constructor() {}

  setDefaultAvatar(
    firstName: string = 'default',
    lastName: string = 'default',
    email: string = 'default@example.com',
    username: string = 'user',
    shape: string = 'circle'
  ): string {
    try {
      // Modified: Kept the original checkAlphabet method to ensure Latin characters are used
      // This ensures that the character can be used safely in a file name.
      if (this.checkAlphabet(firstName)) {
        this.defaultChar = firstName.toLowerCase().charAt(0);
      } else if (this.checkAlphabet(lastName)) {
        this.defaultChar = lastName.toLowerCase().charAt(0);
      } else if (this.checkAlphabet(email)) {
        this.defaultChar = email.toLowerCase().charAt(0);
      } else if (this.checkAlphabet(username)) {
        this.defaultChar = username.toLowerCase().charAt(0);
      } else {
        // Added: Set defaultChar to empty if no valid character is found.
        // This ensures that the default profile picture is used if no Latin character is available.
        this.defaultChar = '';
      }
    } catch (e: any) {
      // Kept the error handling to catch any unexpected issues and reset defaultChar to empty.
      console.log(e, 'in user avatar');
      this.defaultChar = '';
    }
    return this.setDefault('user', shape);
  }

  setDefaultOrganizationAvatar(title: string, slug: string): string {
    // Modified: Reset defaultChar at the start to ensure no old values interfere.
    this.defaultChar = '';
    title = title.split(' ').join(''); // Removing spaces from title

    // Modified: Use checkAlphabet to ensure only Latin characters are used.
    if (this.checkAlphabet(title)) {
      this.defaultChar = title.toLowerCase().charAt(0);
    } else if (this.checkAlphabet(slug)) {
      this.defaultChar = slug.toLowerCase().charAt(0);
    } else {
      // Added: Set defaultChar to empty if no valid character is found.
      this.defaultChar = '';
    }
    return this.setDefault('organization');
  }

  checkAlphabet(name: string): boolean {
    // Kept this method to validate that only Latin alphabet characters are used.
    // This is crucial for ensuring file names are safe for URLs.
    const lowerCased = name.toLowerCase();
    for (let char of lowerCased) {
      if (!this.letters.includes(char)) return false;
    }
    return true;
  }

  setDefault(type: string, shape: string = 'circle'): string {
    // Modified: Added condition to set fallback to default image if no valid character is found.
    if (this.defaultChar === '') {
      // Use a generic profile picture if no valid character is available.
      this.default =
        'https://uni-common-assets.s3.amazonaws.com/icons/avatar/svg/circle/a-red-120px.svg';
    } else if (type === 'organization') {
      // Set the organization default image URL, with the validated defaultChar.
      this.default = `https://uni-common-assets.s3.amazonaws.com/icons/organization/${this.defaultChar}-blue-120px.svg`;
    } else {
      // Set the user default image URL, with the validated defaultChar and shape.
      this.default = `https://uni-common-assets.s3.amazonaws.com/icons/avatar/svg/${shape}/${this.defaultChar}-red-120px.svg`;
    }
    return this.default;
  }
}
