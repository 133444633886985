export const environment = {
  production: true,
  baseUrl: 'api/v2/',
  //competitionUrl: 'http://localhost:4000/',
  //postUrl: 'http://localhost:4001/',
  //loginUrl: 'http://localhost:4003/',
  //evalUrl: 'http://localhost:4002',
  //projectUrl: 'http://localhost:4004/',
  //imageUrl: 'http://localhost:4004/image/',
  //paymentUrl: 'http://localhost:4005/transaction/',
  //profileUrl: 'http://localhost:4006/',
  //projectUploaderUrl: 'http://localhost:4008/',
  //organizationUrl: 'http://localhost:4009/',
  //studioUrl: 'http://localhost:4010/',
  //publicationUrl: 'http://localhost:4011/',
  //topicUrl: 'http://localhost:4012/',
  //discussionUrl: 'http://localhost:4013/',
  //aboutUrl: 'http://localhost:4014/',
  //serverUrl: 'http://localhost:8000/',

  /* Production environment variables */
  loginUrl: 'https://uni.xyz/accounts/',
  evalUrl: 'https://uni.xyz/evaluations/',
  projectUrl: 'https://uni.xyz/projects/',
  imageUrl: 'https://uni.xyz/projects/image/',
  competitionUrl: 'https://uni.xyz/competitions/',
  postUrl: 'https://uni.xyz/journal/',
  paymentUrl: 'https://uni.xyz/payment/transaction/',
  profileUrl: 'https://uni.xyz/profile/',
  serverUrl: 'https://uni.xyz/',
  aboutUrl: 'https://uni.xyz/',
  publicationUrl: 'https://uni.xyz/publications/',
  organizationUrl: 'https://uni.xyz/organization/',
  studioUrl: 'https://uni.xyz/studio/',
  projectUploaderUrl: 'https://uni.xyz/builder/',
  topicUrl: 'https://uni.xyz/topic/',
  discussionUrl: 'https://uni.xyz/discussion/'

  /* Staging environment variables */
  // loginUrl: 'https://unixyz.lol/accounts/',
  // evalUrl: 'https://unixyz.lol/evaluations/'
  // projectUrl: 'https://unixyz.lol/projects/',
  // imageUrl: 'https://unixyz.lol/projects/image/',
  // competitionUrl: 'https://unixyz.lol/competitions/',
  // postUrl: 'https://unixyz.lol/journal/',
  // paymentUrl: 'https://unixyz.lol/payment/transaction/',
  // profileUrl: 'https://unixyz.lol/profile/',
  // serverUrl: 'https://unixyz.lol/',
  // aboutUrl: 'https://unixyz.lol/',
  // publicationUrl: 'https://unixyz.lol/publications/',
  // organizationUrl: 'https://unixyz.lol/organization/',
  // studioUrl: 'https://unixyz.lol/studio/',
  // projectUploaderUrl: 'https://unixyz.lol/builder/',
  // topicUrl: 'https://unixyz.lol/topic/',
  // discussionUrl: 'https://unixyz.lol/discussion/'
};
