import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Blog } from '@sparro/app/core/models/blog.model';
import { Organization } from '@sparro/app/core/models/organization.model';
import { Project } from '@sparro/app/core/models/project.model';
import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@sparro/env/environment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  baseUrl = null;
  type = new Map([
    ['Review', 'Article'],
    ['Interview', 'Article'],
    ['News', 'NewsArticle'],
    ['results', 'NewsArticle'],
    ['Letter', 'Article'],
    ['Blog', 'BlogPosting'],
    ['Lists', 'BlogPosting'],
    ['Help', 'Article'],
    ['Story', 'Article'],
    ['Design Process', 'BlogPosting']
  ]);

  constructor(
    private http: HttpClient,
    public titleService: Title,
    public meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.baseUrl = `${environment.serverUrl}`;
  }

  shareItem(source: string, slug: string) {
    return this.http.get(this.baseUrl + 'api/v2/share/' + source + '/' + slug + '/');
  }

  copyItem(source: string, slug: string) {
    return `${environment.serverUrl}${source}/${slug}/`;
  }

  updateMetaInfo(metaInfo: MetaDefinition) {
    try {
      this.meta.updateTag(metaInfo, metaInfo.name ? metaInfo.name : metaInfo.property);
    } catch (error) {
      // console.log('meta twitter fix');
    }
  }

  updateTitle(title?: string) {
    this.titleService.setTitle(title);
  }

  updateMeta(metaInfo: MetaDefinition[]) {
    this.meta.addTags(metaInfo);
  }

  createCanonicalURL() {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', this.document.URL);
  }

  createArticleSchema(blog: Blog): { [key: string]: any } {
    const user = {
      '@type': 'Person',
      name: `${blog.author.first_name} ${blog.author.last_name}`,
      url: `${environment.profileUrl}${blog.author.username}`
    };
    const publisher = {
      '@type': 'Organization',
      name: 'Uniegis Network Pvt. Ltd',
      logo: {
        '@type': 'ImageObject',
        url: 'https://uni-common-assets.s3.amazonaws.com/logo/uni/unilogo.svg'
      }
    };
    const Schema = {
      '@context': 'https://schema.org',
      '@type': this.type.get(blog.type.name),
      headline: blog.title,
      image: blog.image,
      author: user,
      datePublished: blog.created_at,
      dateModified: blog.updated_at,
      publisher
    };
    return Schema;
  }

  createPersonSchema(userInfo: User): { [key: string]: any } {
    const Schema = {
      '@context': 'http://www.schema.org',
      '@type': 'Person',
      '@id': `${environment.profileUrl}${userInfo.username}`,
      name: userInfo.first_name + ' ' + userInfo.last_name,
      alternateName: userInfo.username,
      Description: userInfo.bio,
      url: `${environment.profileUrl}${userInfo.username}`,
      image: userInfo.avatar_url,
      birthDate: userInfo.dob,
      email: userInfo.email
    };
    return Schema;
  }

  createOrganizationSchema(orgInfo: Organization): { [key: string]: any } {
    const location = orgInfo.locations[0];
    const Schema = {
      '@context': 'http://www.schema.org',
      '@type': 'Organization',
      name: orgInfo.title,
      url: orgInfo.website,
      logo: orgInfo.logo,
      address: location
        ? {
            '@type': 'PostalAddress',
            streetAddress: location?.address,
            addressRegion: location?.city?.name,
            postalCode: location?.postal_code,
            addressCountry: location?.country?.iso_code
          }
        : ''
    };
    return Schema;
  }

  createProjectSchema(projectInfo: Project): { [key: string]: any } {
    const contributor = projectInfo?.owners?.map((owner: any) => {
      if (owner.user) {
        return this.createPersonSchema(owner.user);
      } else if (owner.members) {
        for (let i = 0; i < owner.members.length; i++) {
          return this.createPersonSchema(owner.members[i].user);
        }
      } else {
        return '';
      }
    });
    const Schema = {
      '@context': 'http://schema.org',
      '@type': 'CreativeWork',
      description: projectInfo.content.desc,
      isFamilyFriendly: 'True',
      likesCount: projectInfo.likes,
      viewCount: projectInfo.views,
      contributor,
      creator: contributor,
      headline: projectInfo.content.title,
      url: `${environment.projectUrl}${projectInfo.slug}`
    };
    return Schema;
  }

  decodeApiResponse(encodedData, decode: boolean = false) {
    if (decode) {
      let rev = encodedData.split('').reverse().join('');
      rev = rev.split('=');
      let data = `${rev[1]}.${rev[0]}.${rev[2]}`;

      try {
        return jwt_decode(data);
      } catch (Error) {
        return null;
      }
    }
    return encodedData;
  }
}
