import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';
import { PopoverComponent } from './popover/popover.component';
import { UserCardComponent } from '../cards/user/user-card/user-card.component';
import { TourPopoverComponent } from './tour-popover/tour-popover.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';

@NgModule({
  declarations: [PopoverComponent, TourPopoverComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    ButtonsModule,
    DirectiveModule
  ],
  entryComponents: [
    UserCardComponent //Tested for UserCard. Whichever card component is being used as a popover will need to be added to entryComponents array
  ],
  exports: [
    PopoverComponent,
    TourPopoverComponent
  ]
})
export class PopoverModule { }
