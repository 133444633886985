import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@sparro/app/material.module';

import { BlogCardComponent } from './blogs/blog-card/blog-card.component';
import { BlogCardsGroupComponent } from './blogs/blog-cards-group/blog-cards-group.component';
import { BlogCardFooterComponent } from './blogs/components/blog-card-footer/blog-card-footer.component';
import { BlogCardHeaderComponent } from './blogs/components/blog-card-header/blog-card-header.component';
import { BlogCardImageComponent } from './blogs/components/blog-card-image/blog-card-image.component';
import { BlogCardSubtitleComponent } from './blogs/components/blog-card-subtitle/blog-card-subtitle.component';
import { BlogCardTitleComponent } from './blogs/components/blog-card-title/blog-card-title.component';
import { BlogFeaturedSidenavComponent } from './blogs/blog-featured-sidenav/blog-featured-sidenav.component';

import { PublicationCardFooterComponent } from './publications/components/publication-card-footer/publication-card-footer.component';
import { PublicationCardImageComponent } from './publications/components/publication-card-image/publication-card-image.component';
import { PublicationCardSubtitleComponent } from './publications/components/publication-card-subtitle/publication-card-subtitle.component';
import { PublicationCardTitleComponent } from './publications/components/publication-card-title/publication-card-title.component';
import { PublicationCardComponent } from './publications/publication-card/publication-card.component';
import { PublicationCardsGroupComponent } from './publications/publication-cards-group/publication-cards-group.component';

import { CompetitionCardComponent } from './competitions/competition-card/competition-card.component';
import { CompetitionCardsGroupComponent } from './competitions/competition-cards-group/competition-cards-group.component';
import { CompetitionCardFooterComponent } from './competitions/components/competition-card-footer/competition-card-footer.component';

import { ProjectCardFooterComponent } from './projects/components/project-card-footer/project-card-footer.component';
import { ProjectCardHeaderComponent } from './projects/components/project-card-header/project-card-header.component';
import { ProjectCardImageComponent } from './projects/components/project-card-image/project-card-image.component';
import { ProjectCardSubtitleComponent } from './projects/components/project-card-subtitle/project-card-subtitle.component';
import { ProjectCardTitleComponent } from './projects/components/project-card-title/project-card-title.component';
import { ProjectCardComponent } from './projects/project-card/project-card.component';
import { ProjectCardsGroupComponent } from './projects/project-cards-group/project-cards-group.component';
import { ProjectEntryCardsGroupComponent } from './projects/project-entry-cards-group/project-entry-cards-group.component';

import { PipesModule } from '../../pipes/pipes.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { CommentsModule } from '../comments/comments.module';
import { DialogModule } from '../dialogs/dialog.module';
import { ImagesModule } from '../images/images.module';
import { UserAvatarComponent } from '../images/user-avatar/user-avatar.component';
import { LoaderModule } from '../loader/loader.module';
import { PanelModule } from '../panel/panel.module';
import { MenuModule } from '../menu/menu.module';
import { PublicationCardHeaderComponent } from './publications/components/publication-card-header/publication-card-header.component';
import { UserCardContentComponent } from './user/components/user-card-content/user-card-content.component';
import { UserCardFooterComponent } from './user/components/user-card-footer/user-card-footer.component';
import { UserCardHeaderComponent } from './user/components/user-card-header/user-card-header.component';
import { UserNameComponent } from './user/components/user-name/user-name.component';
import { UserCardComponent } from './user/user-card/user-card.component';
import { UserCardsGroupComponent } from './user/user-cards-group/user-cards-group.component';
import { UserComponent } from './user/user/user.component';
import { ContextMenuComponent } from '../menu/context-menu/context-menu.component';

import { DirectiveModule } from '@sparro/app/shared/directives/directive.module';
import { BlogEntryCardsGroupComponent } from './blogs/blog-entry-cards-group/blog-entry-cards-group.component';
import { CompetitionCardContentComponent } from './competitions/components/competition-card-content/competition-card-content.component';
import { CompetitionCardHeaderComponent } from './competitions/components/competition-card-header/competition-card-header.component';
import { DiscussionCardsGroupComponent } from './discussions/discussion-cards-group/discussion-cards-group.component';
import { DiscussionCardHeaderComponent } from './discussions/discussion-feed-card/components/discussion-card-header/discussion-card-header.component';
import { DiscussionCardMediaComponent } from './discussions/discussion-feed-card/components/discussion-card-media/discussion-card-media.component';
import { DiscussionFeedCardComponent } from './discussions/discussion-feed-card/discussion-feed-card.component';
import { OrganizationCardContentComponent } from './organizations/components/organization-card-content/organization-card-content.component';
import { OrganizationCardFooterComponent } from './organizations/components/organization-card-footer/organization-card-footer.component';
import { OrganizationCardHeaderComponent } from './organizations/components/organization-card-header/organization-card-header.component';
import { PublicationEntryCardsGroupComponent } from './publications/publication-entry-cards-group/publication-entry-cards-group.component';
import { SelectStudioHostDialogComponent } from './competitions/components/select-studio-host-dialog/select-studio-host-dialog.component';
import { JsonLdSchemaComponent } from './components/json-ld-schema/json-ld-schema.component';
import { OrganizationCardComponent } from './organizations/organization-card/organization-card.component';
import { OrganizationCardsGroupComponent } from './organizations/organization-cards-group/organization-cards-group.component';
import { ProductCardComponent } from './products/product-card/product-card.component';
import { ProductCardsGroupComponent } from './products/product-cards-group/product-cards-group.component';
import { ImageCardsGroupComponent } from './image/image-cards-group/image-cards-group.component';
import { JurorCardsGroupComponent } from './juror/juror-cards-group/juror-cards-group.component';
import { JurorCardComponent } from './juror/juror-card/juror-card.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { AdvertisementCardComponent } from './advertisement-card/advertisement-card.component';


@NgModule({
  declarations: [
    BlogCardComponent,
    BlogCardsGroupComponent,
    BlogCardHeaderComponent,
    BlogCardImageComponent,
    BlogCardTitleComponent,
    BlogCardSubtitleComponent,
    BlogCardFooterComponent,
    BlogFeaturedSidenavComponent,
    CompetitionCardComponent,
    CompetitionCardFooterComponent,
    CompetitionCardsGroupComponent,
    ProjectCardsGroupComponent,
    ProjectCardComponent,
    ProjectCardHeaderComponent,
    ProjectCardImageComponent,
    ProjectCardTitleComponent,
    ProjectCardSubtitleComponent,
    ProjectCardFooterComponent,
    ProjectEntryCardsGroupComponent,
    // ProjectIframeComponent,
    // ProjectPanelComponent,
    PublicationCardsGroupComponent,
    PublicationCardComponent,
    PublicationCardImageComponent,
    PublicationCardFooterComponent,
    PublicationCardTitleComponent,
    PublicationCardSubtitleComponent,
    PublicationCardHeaderComponent,
    UserCardsGroupComponent,
    UserCardComponent,
    UserCardHeaderComponent,
    UserCardContentComponent,
    UserCardFooterComponent,
    UserNameComponent,
    UserComponent,
    OrganizationCardsGroupComponent,
    OrganizationCardComponent,
    OrganizationCardContentComponent,
    OrganizationCardFooterComponent,
    OrganizationCardHeaderComponent,
    CompetitionCardHeaderComponent,
    CompetitionCardContentComponent,
    PublicationEntryCardsGroupComponent,
    BlogEntryCardsGroupComponent,
    DiscussionCardsGroupComponent,
    DiscussionFeedCardComponent,
    DiscussionCardMediaComponent,
    DiscussionCardHeaderComponent,
    ProductCardsGroupComponent,
    ProductCardComponent,
    ImageCardsGroupComponent,
    JurorCardsGroupComponent,
    JurorCardComponent,
    JsonLdSchemaComponent,
    SelectStudioHostDialogComponent,
    AdvertisementCardComponent
  ],
  entryComponents: [
    // ProjectIframeComponent,
    // ProjectPanelComponent,
    UserAvatarComponent,
    SelectStudioHostDialogComponent,
    ContextMenuComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    PipesModule,
    ButtonsModule,
    LoaderModule,
    ImagesModule,
    CommentsModule,
    DirectiveModule,
    PanelModule,
    DialogModule,
    MenuModule,
    NgxMasonryModule
  ],
  exports: [
    ProjectCardsGroupComponent,
    ProjectCardComponent,
    ProjectEntryCardsGroupComponent,
    BlogCardsGroupComponent,
    BlogCardComponent,
    PublicationCardComponent,
    CompetitionCardsGroupComponent,
    // ProjectPanelComponent,
    // ProjectIframeComponent,
    ProjectCardHeaderComponent,
    ProjectCardImageComponent,
    ProjectCardTitleComponent,
    ProjectCardSubtitleComponent,
    ProjectCardFooterComponent,
    PublicationCardsGroupComponent,
    PublicationCardImageComponent,
    PublicationCardFooterComponent,
    PublicationCardTitleComponent,
    PublicationCardSubtitleComponent,
    BlogCardHeaderComponent,
    BlogCardImageComponent,
    BlogCardTitleComponent,
    BlogCardSubtitleComponent,
    BlogCardFooterComponent,
    BlogFeaturedSidenavComponent,
    UserCardsGroupComponent,
    UserCardComponent,
    UserCardHeaderComponent,
    UserCardContentComponent,
    UserCardFooterComponent,
    UserComponent,
    UserNameComponent,
    OrganizationCardsGroupComponent,
    OrganizationCardComponent,
    // OrganizationCardHeaderComponent,
    // OrganizationCardContentComponent,
    // OrganizationCardFooterComponent,
    PublicationEntryCardsGroupComponent,
    BlogEntryCardsGroupComponent,
    DiscussionCardsGroupComponent,
    DiscussionFeedCardComponent,
    DiscussionCardHeaderComponent,
    ProductCardsGroupComponent,
    CompetitionCardComponent,
    ImageCardsGroupComponent,
    JurorCardsGroupComponent,
    JurorCardComponent,
    JsonLdSchemaComponent,
    AdvertisementCardComponent
  ],
})
export class CardsModule { }
