<div class="wrapper">
  <ng-container *ngIf="data; else noData">
    <div fxLayout="row" class="margin-bottom-4" *ngIf="data.title" fxLayoutAlign="space-between center">
      <p class="font-sm font-bold margin-0" [innerHtml]="data.title | safeContent"></p>
      <mat-icon (click)="closeDialog(false)" style="cursor: pointer;">close</mat-icon>
    </div>

    <section class="content" fxLayout="column" fxLayoutGap="20px">
      <div mat-dialog-content style="line-height:24px;" [innerHtml]="data.message | safeContent"></div>

      <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" [ngClass]="{'end-container': !showCancel }" fxLayoutGap="12px">
        <uni-navigation-button *ngIf="showCancel"
          default="Cancel"
          button_class="secondary"
          (click)="closeDialog(false)">
        </uni-navigation-button>

        <uni-navigation-button
          [default]="data.okayButtonText"
          button_class="primary"
          (click)="closeDialog(true)">
        </uni-navigation-button>
      </div>
    </section>
  </ng-container>

  <ng-template #noData>
  </ng-template>
</div>
