<ng-container *ngIf="isLoading; else elseTemplate">
  <div fxLayout="row" fxLayoutAlign="center center" style="height:300px">
    <uni-loader></uni-loader>
  </div>
</ng-container>

<ng-template #elseTemplate>
  <div class="project-image">
    <ng-container *ngIf="project_media">
      <uni-image
        [src]="project_media.media.url"
        [alt]="altText"
        [default]="project_media.media.url"
        classes="width-100 height-auto sparro-bg-white">
      </uni-image>
    </ng-container>

    <div *ngIf="project_media.media.description" class="image-desc-container">
      {{ project_media.media.description }}
    </div>

    <div class="image-badges-container-right">
      <div class="image-badge" [ngStyle]="{'display': !project_media.media.title ? 'none' : 'block'}">
        <ng-container *ngIf="isMobile; else infoIconVisibleOnHover">
          <i style="opacity:1;" class="badge-icon icon-info-bold" (click)="imageInfo(project_media.media.uuid)"></i>
            <span class="tooltip-text" style="max-width: 188px; font-size: 12px; padding: 12px;">
              <ng-container *ngIf="isLoading; else notLoading">
                <uni-loader></uni-loader>
              </ng-container>
              <ng-template #notLoading>
                <p class="margin-1" *ngIf="project_media.media.title">{{ project_media.media.title }}</p>
                <p class="margin-1" *ngIf="!project_media.media.title">No Title</p>
              </ng-template>
            </span>
        </ng-container>

        <ng-template #infoIconVisibleOnHover>
          <i class="badge-icon icon-info-bold margin-left-0" (click)="imageInfo(project_media.media.uuid)"></i>
          <span class="tooltip-text" style="font-size: 14px; max-width: 628px; padding: 16px;">
            <ng-container *ngIf="isLoading; else notLoading">
              <uni-loader></uni-loader>
            </ng-container>
            <ng-template #notLoading>
              <p class="margin-1" *ngIf="project_media.media.title">{{ project_media.media.title }}</p>
              <p class="margin-1" *ngIf="!project_media.media.title">No Title</p>
            </ng-template>
          </span>
        </ng-template>
      </div>

      <div class="image-badge">
        <ng-container *ngIf="isMobile; else newWindowIconVisibleOnHover">
        <i (click)="openImageNewWindow()" style="opacity: 1;" class="badge-icon icon-open-in-new-tab-bold"> </i>
        <span class="tooltip-text" style="font-size: 12px; padding: 12px;">
          <p class="margin-1">Open in new tab</p>
        </span>
        </ng-container>

        <ng-template #newWindowIconVisibleOnHover>
          <i (click)="openImageNewWindow()" class="badge-icon icon-open-in-new-tab-bold"></i>
          <span class="tooltip-text" style="font-size: 14px; padding: 16px;">
            <p class="margin-1">Open in new tab</p>
          </span>
        </ng-template>
      </div>

    </div>
  </div>
</ng-template>
