import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectService } from '@sparro/app/core/api/project/project.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { ImageMetaData } from '@sparro/app/core/models/media.model';

@Component({
  selector: 'uni-project-image',
  templateUrl: './project-image.component.html',
  styleUrls: ['./project-image.component.scss']
})
export class ProjectImageComponent implements OnInit {
  @Input() project_media: any; // TODO: ProjectMedia and Media Models
  isLoading = false;
  @Output() uuid = new EventEmitter<any>();
  isMobile = false;
  projectImageMetaData: ImageMetaData;
  altText: string = '';

  constructor(
    private projectService: ProjectService,
    public breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit() {
    this.instantiateBreakpoints();
    this.getMediaInfo();
  }

  instantiateBreakpoints() {
    this.breakpointObserver.observe([
      BREAKPOINTS.below.sm,
    ]).subscribe(result => {
      this.isMobile = result.breakpoints[BREAKPOINTS.below.sm];
    });
  }

  getMediaInfo(): void {
    this.isLoading = true;
    this.projectService.getImageProperties(this.project_media.media.uuid).subscribe(
      (media: any) => {
        this.isLoading = false;
        this.project_media.media = media;
        this.altText = this.project_media.media.title ?
          this.project_media.media.title :
          this.project_media.media.description ?
          this.project_media.media.description :
          this.project_media.media.tags?.map(tag => tag.name).toString();
      },
      (error) => {
        this.isLoading = false;
        console.error(error);
      }
    );

  }

  openImageDialog() {
    // const dialogRef = this.dialog.open(
    //   ImagePropertiesDialogComponent, {
    //       data: this.project_media.media
    //   }
    // );
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  imageInfo(uuid) {
    this.uuid.emit(uuid); // value passed to function is being emitted by output(emit)variable
  }

  openImageNewWindow() {
    return window.open(
      this.project_media.media.url,
      '_blank'
    );
  }
}
