import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timesince'
})
export class TimesincePipe implements PipeTransform {
  secondsInYear: number = 31536000;
  secondsInMonth: number = 2592000;
  secondsInDay: number = 86400;
  secondsInHour: number = 3600;
  secondsInMin: number = 60;
  millisecondsInSecond: number = 1000;

  public transform(value: any, args?: any): any {
    const date: Date = new Date(value);

    if (isNaN(date.getTime())) {
      return value;
    }

    const seconds = Math.abs(
      Math.floor(
        (new Date().getTime() - date.getTime()) / this.millisecondsInSecond,
      ),
    );

    let interval = Math.floor(seconds / this.secondsInYear);

    if (interval > 1) {
      return `${interval} ${args=='short' ? 'yr' : 'years'}`;
    }
    if (interval === 1) {
      return `${interval} ${args=='short' ? 'yr' : 'year' }`;
    }

    interval = Math.floor(seconds / this.secondsInMonth);

    if (interval > 1) {
      return `${interval} ${args=='short' ? 'mo' : 'months' }`;
    }
    if (interval === 1) {
      return `${interval} ${args=='short' ? 'mo' : 'month' }`;
    }

    interval = Math.floor(seconds / this.secondsInDay);

    if (interval > 1) {
      return `${interval} ${args=='short' ? 'dy' : 'days' }`;
    }
    if (interval === 1) {
      return `${interval} ${args=='short' ? 'dy' : 'day'}`;
    }

    interval = Math.floor(seconds / this.secondsInHour);

    if (interval > 1) {
      return `${interval} ${args=='short' ? 'hr' : 'hours' }`;
    }
    if (interval === 1) {
      return `${interval} ${args=='short' ? 'hr' : 'hour' }`;
    }

    interval = Math.floor(seconds / this.secondsInMin);

    if (interval > 1) {
      return `${interval} ${args=='short' ? 'min' : 'minutes' }`;
    }
    if (interval === 1) {
      return `${interval} ${args=='short' ? 'min' : 'minute' }`;
    }

    return `${Math.floor(seconds)} ${args=='short' ? 'sec' : (seconds === 1 ? 'second' : 'seconds')}`;
  }

}
