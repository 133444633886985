<ng-container *ngIf="showFloatingShare">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="12px"
    class="floating-button-container">
    <ng-container *ngIf="source === 'competitions'; else userAvatarView">
      <uni-organization-avatar
        [organization]="item.organizer"
        avatarClass="shared-cover-image"
        matTooltip="Hosted by {{ item?.organizer?.title | titlecase }}"
        matTooltipPosition="left">
      </uni-organization-avatar>
    </ng-container>

    <ng-template #userAvatarView>
      <ng-container *ngIf="source !== 'neo_projects'; else projectOwnersAvatars">
        <uni-user-avatar [user]="item.author" avatarClass="shared-cover-image"> </uni-user-avatar>
      </ng-container>
      <ng-template #projectOwnersAvatars>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
          <uni-user-avatar
            [user]="projectOwners[0]?.user"
            avatarClass="shared-cover-image"
            [showPopover]="projectOwners.length == 1"
            matTooltip="Published by {{ projectOwners[0]?.user?.first_name | titlecase }} {{
              projectOwners[0].user?.last_name | titlecase
            }} + {{ projectOwners.length - 1 + ' others' }}"
            [matTooltipDisabled]="projectOwners.length == 1"
            matTooltipPosition="left">
          </uni-user-avatar>
        </div>
      </ng-template>
    </ng-template>

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <button
        class="fab-button mat-mini-fab"
        (click)="openShareDialog()"
        matTooltip="Share this"
        matTooltipPosition="left">
        <i class="icon-share-bold"></i>
      </button>
      <button
        class="fab-button mat-mini-fab"
        (click)="toggleLike()"
        matTooltip="{{ item.likes === 0 ? '' : item.likes }} {{
          item.likes > 1 ? 'Likes' : 'Like'
        }}"
        matTooltipPosition="left">
        <i
          [ngClass]="{
            'icon-like-fill sparro-orange-600': item.user_like,
            'icon-like-bold sparro-grey-800': !item.user_like
          }">
        </i>
      </button>
      <button
        class="fab-button mat-mini-fab"
        matTooltipPosition="left"
        (click)="toggleBookmark()"
        matTooltip="{{ item.bookmark ? 'Added to collection' : 'Add to collection' }}">
        <i
          [ngClass]="{
            'icon-add-to-collection': !item.bookmark,
            'sparro-orange-600': item.bookmark,
            'icon-add-to-collection-fill': item.bookmark
          }"></i>
      </button>
      <button
        class="fab-button mat-mini-fab sparro-grey-800"
        (click)="goToComment()"
        matTooltip="{{ item.comments === 0 ? '' : item.comments }} 
          {{
          (source === 'competitions' ? 'Discussion' : 'Comment') + (item.comments > 1 ? 's' : '')
        }}"
        matTooltipPosition="left"
        *ngIf="commentSectionTop">
        <i class="icon-comments"></i>
      </button>
      <div class="tooltip" *ngIf="item?.badges?.length">
        <button class="fab-button mat-mini-fab sparro-grey-800">
          <i class="icon-award-fill sparro-orange-600"></i>
        </button>
        <div class="info">
          <ng-container *ngFor="let badge of item.badges; let i = index">
            <p class="margin-0">
              {{ badge.title }} <span *ngIf="i != item.badges.length - 1">,</span>
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="source == 'competitions'; else defaultView">
  <!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px" class="competition-floating-button-container">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <button class="fab-button mat-mini-fab" (click)="toggleLike()"
        matTooltip="{{ item.likes === 0 ? '' : item.likes }} {{ item.likes > 1 ? 'Likes' : 'Like' }}"
        matTooltipPosition="above">
        <i [ngClass]="{ 'icon-like-fill sparro-orange-600': item.user_like,
          'icon-like-bold sparro-grey-800': !item.user_like }">
        </i>
      </button>
      <button class="fab-button mat-mini-fab icon-comments sparro-grey-800 font-lg" (click)="goToComment()"
        matTooltip="{{ item.comments === 0 ? '' : item.comments }} {{ item.comments > 1 ? 'Comments' : 'Comment' }}"
        matTooltipPosition="above">
      </button>
      <button class="fab-button mat-mini-fab" matTooltipPosition="above" (click)="toggleBookmark()"
        matTooltip="{{ item.bookmark ? 'Added to collection' : 'Add to collection' }}">
        <uni-image classes="margin-top-2" [src]="item.bookmark ? icons.bookmark_fill_orange : icons.bookmark" alt="share-icon" [height]="18" [width]="18"></uni-image>
      </button>
      <button class="fab-button mat-mini-fab" (click)="openShareDialog()" matTooltip="Share this"
        matTooltipPosition="above">
        <uni-image classes="margin-top-2" [src]="icons.share" alt="share-icon" [height]="18" [width]="18"></uni-image>
      </button>
    </div>
    <uni-organization-avatar [organization]="item.organizer" avatarClass="shared-cover-image"
      matTooltip="Hosted by {{ item?.organizer?.title | titlecase }}" matTooltipPosition="above">
    </uni-organization-avatar>
  </div> -->
</ng-container>

<ng-template #defaultView>
  <div fxLayout="row" class="share-menu-section" style="align-items: center">
    <div mat-icon-button class="cursor-pointer margin-right-1" (click)="openShareDialog()">
      <!-- <uni-image [src]="icons.share_fill" alt="Share" [height]="24" [width]="24" classes="align-middle"></uni-image> -->
      <!-- <i class="icon-share-fill"></i> -->
      <mat-icon class="icon-share-fill sparro-grey-800"> </mat-icon>
    </div>
    <div
      mat-icon-button
      class="cursor-pointer margin-left-1"
      (click)="toggleBookmark()"
      matTooltipPosition="above"
      matTooltip="{{ item?.bookmark ? 'Remove from collection' : 'Add to collection' }}">
      <!-- <uni-image [src]="item?.bookmark ? icons.bookmark_fill_orange : icons.bookmark" alt="Bookmark"
      [height]="24" [width]="24" classes="align-middle"></uni-image> -->
      <!-- <i class="icon-add-to-collection"></i> -->
      <mat-icon
        [class]="
          item?.bookmark
            ? 'icon-add-to-collection-fill sparro-orange-600'
            : 'icon-add-to-collection sparro-grey-800'
        ">
      </mat-icon>
    </div>

    <div mat-icon-button class="cursor-pointer margin-left-1" [matMenuTriggerFor]="menu">
      <mat-icon class="icon-three-vertical-dots-fill sparro-grey-800"> </mat-icon>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="toggleFollow()">
        <span>{{ following ? 'Unfollow' : 'Follow' }}</span>
      </button>
      <button
        mat-menu-item
        (click)="createReport()"
        *ngIf="!report || report?.status !== 'INITIATED'"
        [disabled]="report?.status === 'VERIFIED'">
        <span>{{ report?.status === 'VERIFIED' ? 'Reported' : 'Report' }}</span>
      </button>
      <button mat-menu-item *ngIf="report?.status === 'INITIATED'" (click)="redactReport()">
        <span>Redact</span>
      </button>
      <button mat-menu-item *ngIf="self" (click)="goToEditPage()">
        <span>Edit</span>
      </button>
    </mat-menu>
  </div>
</ng-template>

<!-- Note: Default view when source is competitions and showFloatingShare is false doesn't work -->
