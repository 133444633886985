import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FloatButton } from '@sparro/app/core/models/float-button.model';
import { animateButtons } from './animation';

export type btnAnimateState = ('void' | 'right' | 'left' | 'up' | 'down');

@Component({
  selector: 'uni-float-button',
  templateUrl: './float-button.component.html',
  styleUrls: ['./float-button.component.scss'],
  animations: animateButtons,
})

export class FloatButtonComponent implements OnInit {
  @Input() buttons: Array<FloatButton>;
  @Input() direction: string = 'right';
  @Input() isSmall: boolean = false;
  @Input() color: string = 'white';

  showBtns: boolean = false;
  animateState: btnAnimateState = 'void';
  togglerState: boolean = false;

  @Output() clicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (!this.buttons || this.buttons.length == 0) {
      throw new Error('buttons required');
    }
  }

  triggerBtnMenu() {
    this.showBtns = !this.showBtns;
    this.togglerState = !this.togglerState;

    if (!this.showBtns) {
      this.animateState = 'void';
    } else {
      switch (this.direction) {
        case 'right':
          this.animateState = 'right';
          break;

        case 'left':
          this.animateState = 'left';
          break;

        case 'up':
          this.animateState = 'up';
          break;

        case 'down':
          this.animateState = 'down';
          break;

        default:
          this.animateState = 'right';
      }
    }
  }

  fireAction(event, action) {
    // console.log(event, action);
    // this.triggerBtnMenu();
    this.clicked.emit(action);
  }

  shouldShowLabel(title: string) {
    if (!title || title == '') {
      return false;
    }
    else {
      return true;
    }
  }
}
