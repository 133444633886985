<ng-container *ngIf="metaInfo && userCover; else skeletonLoader">
    <div class="border-radius-3 popover-container" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center" class="margin-bottom-4">
            <a [href]="profileUrl+user.username" fxLayout="column" fxLayoutAlign="center center"
                class="relative margin-bottom-13 no-decor">
                <img [src]="userCover.cover_image.url" *ngIf="userCover?.cover_image" alt="user's cover image"
                    class="cover-image border-radius-3 margin-1 fit-cover">
                <img [src]="defaultCoverImage" *ngIf="!userCover?.cover_image" alt="user's cover image"
                    class="cover-image border-radius-3 margin-1 fit-cover">
                <uni-user-avatar [user]="user" [viewProfile]="false" 
                    avatarClass="user-card-popover-avatar">
                </uni-user-avatar>
            </a>
            <a [href]="profileUrl+user.username" class="no-decor padding-x-1" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap="8px">
                <p class="margin-0 title">{{ (user.first_name + ' ' + user.last_name) }}</p>
                <i *ngIf="metaInfo.is_verified" class="icon-verified sparro-orange-600"></i>
            </a>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="margin-top-2 padding-x-1">
                <p class="margin-0 subtitle">@{{user.username}}</p>
                <!-- <p class="margin-0 subtitle">|</p>
                <p class="margin-0 subtitle"></p> -->
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="margin-top-2">
                <p *ngIf="metaInfo?.has_active_membership" class="pro-badge border-radius-1 font-bolder margin-0">
                    UNI Member
                </p>
                <p *ngIf="metaInfo?.profile_score?.rank" class="border-radius-1 sparro-bg-white profile-badge margin-0">
                    {{metaInfo.profile_score.rank}}
                </p>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="margin-x-2 margin-bottom-4">
            <ng-container *ngIf="metaInfo?.project_count">
                <div class="meta-info-container border-radius-2 padding-1 sparro-bg-white" fxLayout="column"
                    fxLayoutAlign="center center" fxLayoutGap="4px">
                    <p class="margin-0 font-bold count">{{metaInfo.project_count}}</p>
                    <p class="subtitle margin-0">{{metaInfo.project_count > 1 ? 'Projects' : 'Project'}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="metaInfo?.journal_count">
                <div class="meta-info-container border-radius-2 padding-1 sparro-bg-white" fxLayout="column"
                    fxLayoutAlign="center center" fxLayoutGap="4px">
                    <p class="margin-0 font-bold count">{{metaInfo.journal_count}}</p>
                    <p class="subtitle margin-0">{{metaInfo.journal_count > 1 ? 'Journals' : 'Journal'}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="metaInfo?.publication_count">
                <div class="meta-info-container border-radius-2 padding-1 sparro-bg-white" fxLayout="column"
                    fxLayoutAlign="center center" fxLayoutGap="4px">
                    <p class="margin-0 font-bold count">{{metaInfo.publication_count}}</p>
                    <p class="subtitle margin-0">{{metaInfo.publication_count > 1 ? 'Publications' : 'Publication'}}</p>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="currentUser?.id!=user?.id">
            <button mat-flat-button class="follow-btn sparro-bg-white margin-bottom-4"
                [class.sparro-orange-600]="metaInfo?.is_following" (click)="toggleProfileFollow()">
                {{metaInfo?.is_following ? 'Following' : 'Follow'}}
            </button>
        </ng-container>
    </div>
</ng-container>

<ng-template #skeletonLoader>
    <div class="border-radius-3 popover-container padding-bottom-2" fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="16px">
        <div fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center" class="relative margin-bottom-13 no-decor">
                <div class="cover-image border-radius-3 margin-1 fit-cover loader progress"></div>
                <div class="avatar-url fit-cover sparro-bg-white loader progress"></div>
            </div>
            <div class="padding-x-1" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                <p class="margin-0 loader progress" style="height: 28px; width: 200px"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="margin-top-2 padding-x-1">
                <p class="margin-0 loader progress" style="height: 14px; width: 55px"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="margin-top-2">
                <p style="height: 24px; width: 86px" class="border-radius-1 loader progress margin-0"></p>
                <p style="height: 24px; width: 86px" class="border-radius-1 loader progress margin-0"></p>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="margin-x-2">
            <div class="border-radius-2 padding-1 loader progress" style="height: 60px; width: 94px;"></div>
            <div class="border-radius-2 padding-1 loader progress" style="height: 60px; width: 94px;"></div>
            <div class="border-radius-2 padding-1 loader progress" style="height: 60px; width: 94px;"></div>
        </div>
        <div class="loader progress" style="height: 38px; width: 302px; border: none; margin-bottom: 16px;"></div>
    </div>
</ng-template>
