import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarDisplayComponent } from '@sparro/app/shared/components/dialogs/snackbar-display/snackbar-display.component';
import { SnackBar } from '../../models/snackbar.model';
import { SnackbarManagerService } from './snackbar-manager.service';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  snackbarQueue: SnackBar[] = [];

  constructor(
    public snackbarManager: SnackbarManagerService,
    public snackbar: MatSnackBar
  ) { }

  success(message: string, priority?: number, duration?: number) {
    this.setSnackbarParams(message, 'success', priority, duration);
  }

  error(message: string, priority?: number, duration?: number) {
    this.setSnackbarParams(message, 'error', priority, duration);
  }

  warn(message: string, priority?: number, duration?: number) {
    this.setSnackbarParams(message, 'warning', priority, duration);
  }

  info(message: string, priority?: number, duration?: number) {
    this.setSnackbarParams(message, 'info', priority, duration);
  }

  setSnackbarParams(message: string, type: string, priority?: number, duration?: number) {
    let snackbarElement: SnackBar = {
        priority: 0,
        snackBarItem: {
          message: '',
          duration: 0,
          type: ''
        }
      };    
    if(priority) {
      snackbarElement.priority = priority;
    }
    else {
      snackbarElement.priority = 1;
    }
    if(duration) {      
      snackbarElement.snackBarItem.duration = duration;
    }
    else {      
      snackbarElement.snackBarItem.duration = 20000;
    }
    snackbarElement.snackBarItem.message = message;
    snackbarElement.snackBarItem.type = type;
    this.addToQueue(snackbarElement);
  }

  addToQueue(snackbar: SnackBar) {
    this.snackbarManager.addToPriorityQueue(snackbar);    
    if(this.snackbarManager.snackBarQueue.length == 1) {
      this.openSnackBar();
    }
  }

  openSnackBar() {
    this.snackbar.openFromComponent(SnackbarDisplayComponent, {
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      data: this.snackbarManager.snackBarQueue
    })
  }
}
