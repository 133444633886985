import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { HttpEventType } from '@angular/common/http';
@Component({
  selector: 'uni-user-cover',
  templateUrl: './user-cover.component.html',
  styleUrls: ['./user-cover.component.scss']
})
export class UserCoverComponent implements OnInit {
  @Input() user_id: number;
  @Input() self: boolean;

  coverImage: string = "";
  defaultImage: string = "https://uni-common-assets.s3.amazonaws.com/illustrations/png/1/default-profile-cover.png";
  @ViewChild('cover') cover: ElementRef;
  isUploading: boolean = false;
  uploadCoverProgress: number = 0;

  constructor(
    private userService: UserService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.user_id) {
      this.getCoverImage()
    }
  }
  // ngAfterViewInit(): void {
  //   if (this.cover && this.self) {
  //     this.cover.nativeElement.classList.add('overlay')
  //   }
  // }

  getCoverImage() {
    this.userService.getUserCover(this.user_id).subscribe(
      (response: any) => {
        if (response.cover_image) {
          this.coverImage = response.cover_image.url;
        }
      }
    )
  }

  uploadFile(event, type) {
    var files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      // this.files.push(element.name)
      const fileData = new FormData();
      fileData.append('user', `${this.user_id}`);
      fileData.append('title', '');
      fileData.append('description', '');
      fileData.append('file', element, element.name);
      if (type === 'img') {
        this.uploadCoverImage(element, fileData)
      } else {
      }
    }
  }

  uploadCoverImage(element, fileData) {
    this.userService.uploadUserCoverImage(fileData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.isUploading = true
        } else if (event.type === HttpEventType.Response) {
          // this.toastService.success('Cover Image Updated Successfully');
          this.isUploading = false;
          this.coverImage = event.body.cover_image.url;
          this.changeDetection.detectChanges()
        }
      },
      (error) => {
        console.log(error);
        // this.toastService.error('Something went wrong, Please try again.')
        this.isUploading = false
      }
    )
  }

  deleteCoverImage() {
    this.userService.deleteUserCover(this.user_id).subscribe(
      (response: any) => {
        if (response.success) {
          this.coverImage = "";
        }
      })
  }
}
