import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  private baseUrl = `api/v2/`;
  removeBookmark = new Subject();

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  removeBookmarks(bookmarksData: object){
    this.removeBookmark.next(bookmarksData);
  }

  addToCollection(source: string, slug: string, data) {
    return this.http.post(this.baseUrl + source + '/' + slug + '/bookmarks/', data);
  }

  removeFromCollection(source: string, slug: string, bookmark_id: number) {
    return this.http.delete(this.baseUrl + source + '/' + slug + '/bookmarks/' + bookmark_id + '/');
  }
}
