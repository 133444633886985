import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { ToastrService } from '@sparro/app/core/helpers/toastr/toastr.service';

@Component({
  selector: 'app-image-uploader-dialog',
  templateUrl: './image-uploader-dialog.component.html',
  styleUrls: ['./image-uploader-dialog.component.scss']
})
export class ImageUploaderDialogComponent implements OnInit {
  isUploading: boolean = false;
  imageUrl: string;
  file: File;
  files: any = [];
  isFileSelected: boolean = false;
  constructor(
    private toastService: ToastrService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ImageUploaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  onFileChanged(event) {
    const filesLoaded = event.target.files;
    this.isUploading = true;
    for (const item of filesLoaded) {
      if (item.size > 15000000) {
        this.toastService.error('Please upload image less than 15mb ');
      } else {
        this.startFileUpload(item);
      }
    }
  }

  startFileUpload(file: any) {
    const formData = new FormData();
    formData.append('title', '');
    formData.append('description', '');
    formData.append('file', file, file.name);
    formData.append('type', file.type);
    this.uploadImage(formData);
  }

  uploadImage(formData) {
    this.userService.uploadUserMedia(formData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {

        } else if (event.type === HttpEventType.Response) {
          this.isUploading = false;
          this.toastService.success('Cover Image Updated Successfully');
          this.files.push({
            title: '',
            description: '',
            url: event.body.url,
            uuid: event.body.uuid,
          });
        }
      }, (error) => {
        this.toastService.error('Something went wrong, Please try again.');
        this.isUploading = false;
      });
  }

  updateMediaInfo(file: any) {
    const postData = {
      url: file.url,
      title: file.title,
      description: file.description,
    };
    this.userService.updateImageProperties(file.uuid, postData).subscribe(
      (response) => {
        this.toastService.success('Image Updated Successfully');
      },
      (error) => {
        this.toastService.error('Something went wrong, Please try again.');      }
    );
  }

}
